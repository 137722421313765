import React, { useEffect, useState } from "react";
import CustomerNavbar from "../components/navbar/customer-navbar/CustomerNavbar";
import Footer from "../components/customer-dashboard/footer/Footer";
import laptop from "../assets/images/laptop.png";
import cn from "classnames";
import { useNavigate } from "react-router";
import url from "../Config/url";
import Loader from "../components/Loader/Loader";

const Orders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const getOrders = async () => {
    let user = localStorage.getItem("customer");
    if (user) {
      user = JSON.parse(user);
      const response = await fetch(
        `${url}/api/order/getOrdersByFilter?customer=${user.email}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            //"Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await response.json();
      setLoading(false);
      if (data.status) {
        setOrders(data.data);
      } else {
        console.log(data.message);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("customer")) {
      getOrders();
    } else {
      navigate("/user/login");
    }
  }, []);

  return (
    <div className=" md:min-w-[1000px] min-h-screen flex flex-col justify-between">
      <div>
        <CustomerNavbar />
      </div>
      {loading && <Loader width={100} height={100} />}
      {!loading && (
        <div className=" mx-3 md:mx-20 space-y-3 my-4 md:my-10  ">
          <div className="text-left font-bold text-xl">My Orders</div>
          <div className="bg-white border rounded-lg ">
            {orders.length === 0 && (
              <div className="flex justify-center items-center h-[100px]">
                <div className="text-[#8B96A5] text-sm md:text-base">
                  No Orders Found
                </div>
              </div>
            )}
            {orders.length !== 0 && (
              <div className=" max-h-[450px] overflow-y-scroll no-scrollbar">
                {orders.map((item) => {
                  const startDate = new Date(item.date);
                  const endDate = new Date(item.date);
                  startDate.setDate(startDate.getDate() + 7); // Set the start date to 7 days ahead
                  endDate.setDate(endDate.getDate() + 10); // Set the end date to 10 days ahead

                  const formattedStartDate =
                    `${startDate.getDate()} ` +
                    `${new Date(startDate).toLocaleString("default", {
                      month: "short",
                    })}` +
                    ` ${startDate.getFullYear()}`;

                  const formattedEndDate =
                    `${endDate.getDate()} ` +
                    `${new Date(endDate).toLocaleString("default", {
                      month: "short",
                    })}` +
                    ` ${endDate.getFullYear()}`;

                  const dateRange = `${formattedStartDate} - ${formattedEndDate}`;
                  return (
                    <>
                      <div
                        key={item._id}
                        className="flex  relative items-center justify-between p-2 "
                      >
                        <div className="flex space-x-4">
                          <div className="space-y-2">
                            {/* <div className="h-16 w-16 bg-[#F7F7F7] border border-[#E0E0E0] flex items-center justify-center rounded-lg">
                        <img className="h-12 w-12" src={laptop} alt="" />
                      </div> */}
                          </div>
                          <div className="text-left space-y-1">
                            <p className="text-sm md:text-base text-[#8B96A5]">
                              Order:{" "}
                              <span className="font-normal text-[#0D6EFD]">
                                {item._id}
                              </span>
                            </p>
                            <div className="">
                              <div className=" md:space-x-1 flex flex-col md:flex-row text-xs md:text-base text-[#8B96A5]">
                                <span>Address: {item.address}</span>
                              </div>
                              <span className="text-[#8B96A5] text-xs md:text-base">
                                Payment Method: {item.paymentMethod}
                              </span>
                              <p className="text-[#8B96A5] text-xs md:text-base">
                                Contact: {item.contact}
                              </p>
                            </div>
                            {/* <div className="flex space-x-2 w-fit ">
                        {item === "Placed" && (
                          <button className="border border-[#DEE2E7] text-[#FA3434] py-1 px-2 rounded-lg w-fit m-auto  md:m-0 flex items-center justify-center text-sm">
                            Cancel
                          </button>
                        )}
                        {item !== "Delivered" && (
                          <button className="border border-[#DEE2E7] text-[#0D6EFD] py-1 px-2 rounded-lg w-fit m-auto  md:m-0 flex items-center justify-center text-sm">
                            Track
                          </button>
                        )}
                        {item === "Delivered" && (
                          <button className="border border-[#DEE2E7] text-[#039855] py-1 px-2 rounded-lg w-fit m-auto  md:m-0 flex items-center justify-center text-sm">
                            Review
                          </button>
                        )}
                      </div> */}
                          </div>
                        </div>
                        <div className="absolute text-right flex flex-col  h-full justify-between  right-6 top-2 hover:cursor-pointer">
                          <div className="font-semibold">
                            $
                            {item.products.reduce(
                              (a, b) => a + b.price * b.qty,
                              0
                            )}
                          </div>
                          <div className="    ">
                            <div
                              className={cn(
                                "text-white rounded-full flex items-center text-sm w-fit py-1 px-2 absolute right-0",
                                {
                                  "bg-[#039855]": item.status === "Delivered",
                                  "bg-[#7F56D9]": item.status === "Dispatched",
                                  "bg-[#6BAAFC]": item.status === "Processing",
                                  "bg-[#FA3434]": item.status === "Cancelled",
                                  "bg-[#F7B500]": item.status === "Approved",
                                  "bg-[#344054]": item.status === "Returned",
                                }
                              )}
                            >
                              {item.status}
                            </div>
                          </div>
                          <div className="text-xs md:text-sm text-[#8B96A5]">
                            <div className="text-[12px] text-[#8B96A5] mt-2">
                              Placed on{" "}
                              {new Date(item.date).getDate().toString() +
                                " " +
                                new Date(item.date).toLocaleString("default", {
                                  month: "short",
                                }) +
                                " " +
                                new Date(item.date).getFullYear()}
                            </div>
                            ETC Delivery:{" "}
                            {/* {(new Date(item.date).getDate() + 7).toString() +
                              " " +
                              new Date(item.date).toLocaleString("default", {
                                month: "short",
                              }) +
                              " " +
                              new Date(item.date).getFullYear()}
                            -{" "}
                            {(new Date(item.date).getDate() + 10).toString() +
                              " " +
                              new Date(item.date).toLocaleString("default", {
                                month: "short",
                              }) +
                              " " +
                              new Date(item.date).getFullYear()} */}
                            {dateRange}
                          </div>
                        </div>
                      </div>
                      <hr className="my-4 mx-2" />
                    </>
                  );
                })}
              </div>
            )}
            <div className="flex justify-between p-3">
              <button
                className="bg-[#0D6EFD] text-white p-2 px-3 rounded-lg w-fit m-auto  md:m-0 flex items-center justify-center text-sm hover:cursor-pointer"
                onClick={() => {
                  navigate("/");
                }}
              >
                Back to shop
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Orders;
