// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDInWj4OFNmhmB9D7q7bs4koabu7yZ_Lgk",
  authDomain: "ecommerce-248bc.firebaseapp.com",
  projectId: "ecommerce-248bc",
  storageBucket: "ecommerce-248bc.appspot.com",
  messagingSenderId: "844121482841",
  appId: "1:844121482841:web:2aeebb00f8a83446c9f348",
  measurementId: "G-PM2WQJT4K5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);

export { storage, auth };
