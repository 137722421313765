import React, { useEffect, useState } from "react";
import laptop from "../../../assets/images/laptop.png";
import { CloseIcon } from "../../icons/icons";
import { useNavigate } from "react-router-dom";
import url from "../../../Config/url";
import CartItem from "./CartItem";

const MyCart = ({ billButton }) => {
  const pro = ["1", "2", "3", "4", "5"];
  const [cart, setCart] = useState(
    localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []
  );
  const navigate = useNavigate();
  return (
    <div className="  ">
      {cart.length > 0 && (
        <>
          <div className="text-left font-semibold text-lg py-3">My Cart</div>
          <div className="flex flex-col md:flex-row space-y-5 md:space-y-0">
            <div className="bg-white border rounded-lg md:w-2/3">
              <div className=" max-h-[450px] overflow-y-scroll no-scrollbar">
                {cart.map((item, index) => (
                  <CartItem
                    item={item}
                    key={index}
                    setCart={setCart}
                    cart={cart}
                  />
                ))}
              </div>
              <div className="flex justify-between p-3">
                <button
                  className="bg-[#0D6EFD] text-white p-2 px-3 rounded-lg w-fit m-auto  md:m-0 flex items-center justify-center text-sm hover:cursor-pointer"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Back to shop
                </button>
                <button
                  className="border border-[#DEE2E7] text-[#0D6EFD] p-2 px-3 rounded-lg w-fit m-auto  md:m-0 flex items-center justify-center text-sm hover:cursor-pointer"
                  onClick={() => {
                    setCart([]);
                    localStorage.removeItem("cart");
                  }}
                >
                  Remove all
                </button>
              </div>
            </div>
            <div className=" md:w-1/3 md:mx-5 space-y-6">
              {/* <div className='space-y-3 text-left p-5 bg-white border border-[#DEE2E7] rounded-lg'>
                    <p>Have a coupon?</p>
                    <div className="   rounded-lg">
                    <input 
                        className="w-2/3 h-8  rounded-l-lg px-3 border border-[#E0E0E0]"
                        type="text" 
                        placeholder="Add coupon" />
                    <button className="w-1/3 h-8 border border-[#E0E0E0]  text-[#0D6EFD] rounded-r-lg">
                        Apply
                    </button>
                    </div>
                </div> */}

              <div className="space-y-3 text-left p-5 bg-white border border-[#DEE2E7] rounded-lg">
                <div className="flex justify-between">
                  <span>Subtotal:</span>{" "}
                  <span>
                    $
                    {cart.reduce((acc, item) => acc + item.price * item.qty, 0)}
                  </span>
                </div>
                {/* <div className="flex justify-between">
              <span>Voucher:</span>{" "}
              <span className="text-[#00B517]">Rs. -5000</span>
            </div> */}
                <hr />
                <div className="flex justify-between font-bold">
                  <span>Total:</span>{" "}
                  <span className="text-lg">
                    $
                    {cart.reduce((acc, item) => acc + item.price * item.qty, 0)}
                  </span>
                </div>
                <button
                  className="bg-[#00B5DA] text-white p-2 px-3 m-auto w-full rounded-lg flex items-center justify-center text-sm"
                  onClick={() => {
                    navigate("/checkout", {
                      state: {
                        cart: cart,
                      },
                    });
                  }}
                >
                  {billButton}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {cart.length === 0 && (
        <div className="flex flex-col items-center justify-center space-y-5">
          No Items in the Cart
        </div>
      )}
    </div>
  );
};

export default MyCart;
