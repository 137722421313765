// NoInternetConnection.js
import React from "react";

const NoInternetConnection = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-red-500 text-white p-4 rounded-lg">
        <p className="text-xl font-semibold">No Internet Connection</p>
        <p className="text-sm">
          Please check your internet connection and try again.
        </p>
      </div>
    </div>
  );
};

export default NoInternetConnection;
