import React, { useState, useEffect } from "react";
import cn from "classnames";
import MyEditor from "./reactQuillEditor/ReactQuills.js";
import url from "../../../Config/url.js";
import ErrorAndSuccessMessage from "../ErrorAndSuccessMessage.js";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore.js";

const ProductStockAndPrice = ({
  setProductInfo,
  product,
  setProduct,
  buttonText,
}) => {
  const obj = ["Basic Info", "Description", "Stocks & Price"];
  const [terms, setTerms] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [variations, setVariations] = useState(
    product.hasOwnProperty("_id") ? product.variations : []
  );
  const [error, setError] = useState("");
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  useEffect(() => {
    setProductInfo("product-stock&price");
  }, []);

  const handleProductDescription = async () => {
    try {
      if (terms === false) {
        setError("Please agree with terms and conditions.");
      } else if (privacyPolicy === false) {
        setError("Please agree with Privacy Policy.");
      } else if (variations.length === 0) {
        setError("Please Add atleast one variation");
      } else {
        setError("");
        let obj = product;
        if (product.hasOwnProperty("_id")) {
          delete obj.variations;
          obj.variations = variations;
          setProduct(obj);
          const res = await fetch(`${url}/api/product/update`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              //"Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(obj),
          });
          const data = await res.json();
          if (data.status) {
            HandleErrorAndSuccessMessage({
              type: "success",
              message: "Product Updated Successfully",
            });
            window.location.href = "/seller/dashboard";
          } else {
            HandleErrorAndSuccessMessage({
              type: "error",
              message: "Something went wrong",
            });
            console.log(data.message);
          }
        } else {
          obj.variations = variations;
          setProduct(obj);

          const res = await fetch(`${url}/api/product/add`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              //"Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(obj),
          });
          const data = await res.json();
          if (data.status) {
            HandleErrorAndSuccessMessage({
              type: "success",
              message: "Product Added Successfully",
            });
            window.location.href = "/seller/dashboard";
          } else {
            HandleErrorAndSuccessMessage({
              type: "error",
              message: "Something went wrong",
            });
            console.log(data.message);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddVariation = () => {
    const newVariation = {
      price: 0,
      qty: 0,
      variant: "",
    };
    setVariations([...variations, newVariation]);
  };

  const handleDeleteVariation = (index) => {
    const updatedVariations = [...variations];
    updatedVariations.splice(index, 1);
    setVariations(updatedVariations);
  };

  const handleVariationChange = (index, key, value) => {
    setError("");
    const updatedVariations = [...variations];
    if (key === "price" || key === "qty") {
      if (!isNaN(value) && parseInt(value) >= 0) {
        updatedVariations[index][key] = parseInt(value);
      } else {
        // Handle invalid input (negative or non-numeric)
        setError(`Please enter a valid non-negative number for ${key}.`);
        return; // Prevent updating the state with invalid input
      }
    } else {
      updatedVariations[index][key] = value;
    }
    setVariations(updatedVariations);
  };

  return (
    <div className="mx-2 md:mx-44 space-y-4 md:space-y-8 ">
      <div className="flex w-full justify-between space-x-2 md:space-x-16 flex-grow">
        {obj.map((item, index) => (
          <div className="flex  items-center justify-between  flex-grow w-fit ">
            <div className="flex space-x-1">
              <div
                className={cn(
                  ` flex items-center font-semibold md:font-bold px-[5px] md:px-2 text-[8px] md:text-xs py-[2px] md:py-1 rounded-full `,
                  {
                    "bg-[#037400] text-white":
                      obj.indexOf("Stocks & Price") > index,
                    "bg-[#00B5DA] text-white ":
                      obj.indexOf("Stocks & Price") === index,
                    "bg-[#E1E1E6] text-[#8D8D99]":
                      obj.indexOf("Stocks & Price") < index,
                  }
                )}
              >
                {index + 1}
              </div>
              <div
                className={cn("font-normal md:font-bold text-xs md:text-base", {
                  "text-[#323238] ": obj.indexOf("Stocks & Price") === index,
                  "text-[#8D8D99]": obj.indexOf("Stocks & Price") < index,
                  "text-[#037400]": obj.indexOf("Stocks & Price") > index,
                })}
              >
                {item}
              </div>
            </div>
            {index + 1 !== obj.length && (
              <div
                className="w-2 md:w-4 font-bold text-xs md:text-base text-center"
                style={{ flexShrink: 0 }}
              >
                {">"}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="bg-white border border-[#D0D5DD] rounded-lg px-6 md:px-12 py-6 space-y-6 ">
        <div className="space-y-2">
          <div className="flex flex-col text-[#344054] text-left space-y-1">
            <label
              htmlFor=""
              className="text-xs md:text-sm flex items-center justify-between"
            >
              Variations
              <button
                className="bg-[#00B5DA] text-white p-1 px-2 rounded-lg flex items-center justify-center text-xs md:text-sm"
                onClick={handleAddVariation}
              >
                Add
              </button>
            </label>
            {variations.map((variation, index) => (
              <div key={index} className="space-x-4 flex items-center">
                <input
                  type="text"
                  placeholder="Price"
                  value={variation.price}
                  onChange={(e) =>
                    handleVariationChange(index, "price", e.target.value)
                  }
                  className="border border-gray-300 p-1 rounded"
                />
                <input
                  type="text"
                  placeholder="Quantity"
                  value={variation.qty}
                  onChange={(e) =>
                    handleVariationChange(index, "qty", e.target.value)
                  }
                  className="border border-gray-300 p-1 rounded"
                />
                <input
                  type="text"
                  placeholder="Variant"
                  value={variation.variant}
                  onChange={(e) =>
                    handleVariationChange(index, "variant", e.target.value)
                  }
                  className="border border-gray-300 p-1 rounded"
                />
                <button
                  className="text-red-500 hover:text-red-700"
                  onClick={() => handleDeleteVariation(index)}
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
          <div className="flex flex-row text-[#344054] text-left space-x-3">
            <input
              type="checkbox"
              name=""
              id=""
              checked={terms}
              onChange={() => setTerms(!terms)}
            />
            <label htmlFor="" className="text-xs md:text-sm ">
              I agree with{" "}
              <span className="font-semibold text-[#00B5DA]">
                terms and conditions.
              </span>
            </label>
          </div>
          <div className="flex flex-row text-[#344054] text-left space-x-3">
            <input
              type="checkbox"
              name=""
              id=""
              checked={privacyPolicy}
              onChange={() => setPrivacyPolicy(!privacyPolicy)}
            />
            <label htmlFor="" className="text-xs md:text-sm ">
              I agree with{" "}
              <span className="font-semibold text-[#00B5DA]">
                privacy policy.
              </span>
            </label>
          </div>
          {error && (
            <div className="text-left text-xs md:text-sm  text-red-500">
              {error}
            </div>
          )}
          <div className="flex justify-end space-x-4">
            <button
              className="bg-[#00B5DA]  text-white p-1 px-4 mt-6 rounded-lg   flex items-center justify-center text-xs md:text-sm "
              onClick={() => {
                handleProductDescription();
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default ProductStockAndPrice;
