import logo from "../../../assets/images/logo.png";
import {
  AppleIcon,
  FacebookIcon,
  LinkedinIcon,
  PlayStoreIcon,
  TwitterIcon,
} from "../../icons/icons";

const Footer = () => {
  return (
    <div className="flex flex-col bg-white">
      <div className="space-x-12 flex justify-between  w-full py-4 px-3 md:px-20">
        <div className=" text-left w-1/2 md:w-1/4 space-y-3 md:space-y-5">
          <img className="h-16 " src={logo} alt="" />
          <p>
            Best information about the company gies here but now lorem ipsum is
          </p>
          <div className="text-[#BDC4CD] space-x-2">
            <FacebookIcon />
            <TwitterIcon />
            <LinkedinIcon />
          </div>
        </div>
        {/* <div className='text-left space-y-1'>
          <div>About</div>
          <div className='text-[#8B96A5] text-sm'>
              <p>About us</p>
              <p>Find Store</p>
              <p>Categories</p>
              <p>Blogs</p>
          </div>
      </div> */}
        {/* <div className='hidden md:flex flex-col text-left space-y-1'>
          <div>Partnership</div>
          <div className='text-[#8B96A5] text-sm'>
              <p>About us</p>
              <p>Find Store</p>
              <p>Categories</p>
              <p>Blogs</p>
          </div>
      </div>
      <div className='hidden md:flex flex-col text-left space-y-1'>
          <div>Information</div>
          <div className='text-[#8B96A5] text-sm'>
              <p>Help Center</p>
              <p>Money Refund</p>
              <p>Shipping</p>
              <p>Contact Us</p>
          </div>
      </div>
      <div className='text-left space-y-1'>
          <div>For Users</div>
          <div className='text-[#8B96A5] text-sm'>
              <p>Login</p>
              <p>Register</p>
              <p>Settings</p>
              <p>My Orders</p>
          </div>
      </div> */}
        <div className="text-left space-y-1 items-center justify-center flex flex-col text-left  ">
          <div className="w-full font-semibold text-lg">
            Contact Information
          </div>
          <div className="text-[#8B96A5] text-sm">
            <p>
              <strong>Email:</strong>{" "}
              <a href="mailto:click2day.info@gmail.com">
                click2day.info@gmail.com
              </a>
            </p>
            <p>
              <strong>Number:</strong> +441413874450
            </p>
            <p>
              <strong>Address:</strong> 260-Maxwell Road Glasgow G41 1PJ UK
            </p>
          </div>
        </div>
        <div className="hidden md:flex flex-col text-left space-y-1 items-center justify-center flex flex-col text-left  ">
          <div>Get App</div>
          <div className="text-[#8B96A5] text-sm space-y-1">
            <div className="flex bg-black w-32 px-2 py-1 rounded-md text-white items-center">
              <AppleIcon />
              <div className=" p-0 m-0">
                <p className="text-[8px]">Download on the</p>
                <p className="text-sm font-semibold">App Store</p>
              </div>
            </div>
            <div className="flex bg-black w-32 px-2 py-1 rounded-md text-white items-center">
              <PlayStoreIcon />
              <div className=" p-0 m-0">
                <p className="text-[8px]">Get It On</p>
                <p className="text-sm font-semibold">Google Play</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" px-3 md:px-20 text-left bg-[#EFF2F4] py-3">
        © 2023 Click2Day.
      </div>
    </div>
  );
};

export default Footer;
