import { useEffect, useState } from "react";
import { CloseIcon } from "../icons/icons";

const SellerOrderDetails = ({ setOrderDetailVisible, obj }) => {
  // const updateFunc = async (status) => {
  //   try {
  //     fetch(`${url}/api/order/update`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         //"Access-Control-Allow-Origin": "*",
  //       },
  //       body: JSON.stringify({
  //         id: obj._id,
  //         status: status,
  //       }),
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         if (data.status) {
  //           getOrders();
  //           setOrderDetailVisible(false);
  //         } else {
  //           console.log(data.message);
  //         }
  //       });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  return (
    <div className=" w-full px-8 py-4 h-full space-y-7 ">
      <div className="flex text-left relative font-medium text-lg text-[#5D6881]">
        <label htmlFor="" className="">
          Order #{obj._id}
        </label>
        <div
          className="absolute right-0 text-black hover:cursor-pointer"
          onClick={() => {
            setOrderDetailVisible(false);
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className=" space-y-1">
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Customer name:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.name}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Phone:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.contact}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Email:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.customer}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Address:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.address}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Total Price:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.price * obj.qty}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Status:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.status}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Payment Method:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.paymentMethod}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Date:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {new Date(obj.date).toLocaleDateString()}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Product id:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">{obj.id}</div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Product Name:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.product}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Product Variant:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.variant}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Product Price:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.price}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Product Quantity:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">{obj.qty}</div>
        </div>
        {/* <div className="flex mx-20 flex-row space-x-6 items-center justify-center">
          {obj.status === "Processing" && (
            <>
              <button
                className="bg-[#D92D20] text-white py-2 rounded-md w-24"
                onClick={() => {
                  updateFunc("Cancelled");
                }}
              >
                Cancel
              </button>
              <button
                className="bg-[#F2C94C] text-white py-2 rounded-md w-24"
                onClick={() => {
                  updateFunc("Approved");
                }}
              >
                Approve
              </button>
            </>
          )}
          {obj.status === "Approved" && (
            <>
              <button
                className="bg-[#7F56D9] text-white py-2 rounded-md w-24"
                onClick={() => {
                  updateFunc("Dispatched");
                }}
              >
                Dispatch
              </button>
            </>
          )}
          {obj.status === "Dispatched" && (
            <>
              <button
                className="bg-[#039855] text-white py-2 rounded-md w-24"
                onClick={() => {
                  updateFunc("Delivered");
                }}
              >
                Delivered
              </button>
            </>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default SellerOrderDetails;
