import { useEffect, useState } from "react";
import { CloseIcon, DropImageIcon } from "../../icons/icons";
import url from "../../../Config/url";
import { storage } from "../../../Config/db";
import validator from "validator";
import { SHA512 } from "crypto-js";

const AddUser = ({ setAddStoreVisible, getStores }) => {
  const [storeName, setStoreName] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [identityno, setIdentityno] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("seller");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    if (
      (storeName === "",
      ownerEmail === "",
      address === "",
      !validator.isMobilePhone(contact),
      identityno === "",
      password === "")
    ) {
      setError("Please fill all fields");
      return 0;
    } else {
      try {
        setError("");
        let hashedPassword = SHA512(password).toString();
        const res = await fetch(`${url}/api/user/addByAdmin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            //"Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            name: storeName,
            email: ownerEmail,
            password: hashedPassword,
            address: address,
            contact: contact,
            identityno: identityno.toString(),
            role: role,
          }),
        });
        const data = await res.json();
        if (data.status) {
          getStores();
          setAddress("");
          setContact("");
          setIdentityno("");
          setOwnerEmail("");
          setPassword("");
          setStoreName("");
          setRole("seller");
          setError("");
          setAddStoreVisible(false);
        } else {
          setError("Something went wrong");
          console.log(data.message);
        }
      } catch (err) {
        setError("Something went wrong");
        console.log(err);
      }
    }
  };

  return (
    <div className=" w-full px-8 py-4 h-ful space-y-3">
      <div className="flex text-xl relative text-[#5D6881] text-left font-medium">
        <label htmlFor="" className="">
          Add Store
        </label>
        <div
          className="absolute right-0 text-black hover:cursor-pointer"
          onClick={() => {
            setAddStoreVisible(false);
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="storeName" className="text-left ">
            User Name
          </label>
          <input
            type="text"
            className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            placeholder="Olivia Store"
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="ownerEmail" className="text-left ">
            Email
          </label>
          <input
            type="email"
            className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            placeholder="
                    xyz@gmail.com
                "
            value={ownerEmail}
            onChange={(e) => setOwnerEmail(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="password" className="text-left ">
            Password
          </label>
          <input
            type="text"
            className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="shopAddress" className="text-left ">
            Address
          </label>
          <input
            type="text"
            className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            placeholder="Blue Area, Islamabad"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="contact" className="text-left ">
            Contact
          </label>
          <input
            type="text"
            className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            placeholder="country code + number"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="identityno" className="text-left ">
            Identity No
          </label>
          <input
            type="number"
            className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            placeholder="Enter Country Identity No"
            value={identityno}
            onChange={(e) => setIdentityno(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="role" className="text-left ">
            Role
          </label>
          <select
            id="role"
            className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="seller">Seller</option>
            <option value="customer">Customer</option>
          </select>
        </div>
        {error && <div className="text-left text-[red]">{error}</div>}
      </div>
      <button
        className="bg-[#00B5DA] rounded-lg px-5 py-2 text-white"
        onClick={() => {
          handleSubmit();
        }}
      >
        Add User
      </button>
    </div>
  );
};

export default AddUser;
