// import Logo from "../assets/images/logo.svg"

import { useEffect, useState } from "react";
// import Dashboard from "../components/adminDashboard/Dashboard";
import Categories from "../components/adminDashboard/Categories";
import Stores from "../components/adminDashboard/Stores";
import SellerOrders from "../components/seller-dashboard/SellerOrders";
import Finance from "../components/adminDashboard/Finance";
import Queries from "../components/adminDashboard/Queries";
import SellerSideBar from "../components/seller-dashboard/dashboard/SellerSideBar";
import SellerNavbar from "../components/seller-dashboard/dashboard/SellerNavbar";
import Products from "../components/seller-dashboard/Products";
import SellerFinance from "../components/seller-dashboard/SellerFinance";
import SellerSetting from "../components/seller-dashboard/SellerSetting";
import url from "../Config/url";
import Loader from "../components/Loader/Loader";
import { useErrorAndSuccessMessageStore } from "../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../components/seller-dashboard/ErrorAndSuccessMessage";

const SellerDashboard = () => {
  const [isSelected, setIsSelected] = useState("");
  const [status, setStatus] = useState("");
  const [seller, setSeller] = useState(
    localStorage.getItem("seller")
      ? JSON.parse(localStorage.getItem("seller"))
      : null
  );
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState({});
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const handleStatus = async () => {
    const res = await fetch(
      `${url}/api/store/getStoreByFilter?store_holder=${seller.email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      }
    );
    const data = await res.json();
    if (data.data[0].isActive) {
      setLoading(false);
      setStatus(data.data[0].status);
      setStore(data.data[0]);
    } else {
      HandleErrorAndSuccessMessage({
        type: "error",
        message:
          "There is a problem with your store. Please contact admin for more details",
      });
    }
  };

  useEffect(() => {
    if (seller !== null) {
      handleStatus();
    }
    const url = window.location.href;
    const hash = window.location.hash.substring(1);

    if (url === "http://localhost:3000/seller/dashboard") {
      setIsSelected("orders");
    } else {
      setIsSelected(hash);
    }
  }, []);

  return (
    <div>
      {loading ? (
        <div className="w-[100vw] h-[100vh] flex items-center justify-center">
          <Loader width={100} height={100} />
        </div>
      ) : (
        <>
          <div
            className="container min-w-[98%]  flex flex-row  bg-[#F8F7FC] h-[96vh]"
            style={{
              display: status === "Approved" ? "flex" : "none",
            }}
          >
            <div className="w-[20%] min-w-[200px]">
              <SellerSideBar
                isSelected={isSelected}
                setIsSelected={setIsSelected}
              />
            </div>
            <div className=" min-w-[1000px]  w-4/5 ">
              <div className="w-full h-[75px] ">
                <SellerNavbar />
              </div>
              <div className="h-[90%]">
                {isSelected === "products" && <Products store={store} />}
                {isSelected === "orders" && <SellerOrders store={store} />}
                {isSelected === "finance" && (
                  <SellerFinance store={store} seller={seller} />
                )}
                {isSelected === "settings" && (
                  <SellerSetting
                    seller={seller}
                    setSeller={setSeller}
                    store={store}
                    setStore={setStore}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className="container min-w-[98%] h-[100vh]  flex flex-row items-center justify-center bg-[#F8F7FC]"
            style={{
              display: status !== "Approved" ? "flex" : "none",
            }}
          >
            <div className="w-full h-full flex flex-col items-center justify-center">
              <h1 className="text-2xl text-red-500">
                Your Store is not Approved yet
              </h1>
              <span className="text-2xl text-green-500 mt-1">
                You will be notified by email when your store is approved
              </span>
            </div>
          </div>
        </>
      )}
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default SellerDashboard;
