import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles

const MyEditor = ({itemDescription, setItemDescription}) => {
//   const [text, setText] = useState('');

  const handleChange = (value) => {
    setItemDescription(value);
    
  };

  const modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'},{'header': '3'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, 
         {'indent': '-1'}, {'indent': '+1'}],
        ['clean']   
      ],
  };

  const formats = [
    'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  ];

  const quillStyle = {
    height: '230px', // Adjust the height as needed
  };

  return (
    <div>
      <ReactQuill
        value={itemDescription}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        style={quillStyle}
      />
    </div>
  );
};

export default MyEditor;
