import { useEffect, useState } from "react";
import {
  TruckIcon,
  ShopingBagIcon,
  ShoppingTrollyIcon,
  FilterIcon,
} from "../icons/icons";
import AddStore from "./stores/AddStore";
import url from "../../Config/url";
import Loader from "../Loader/Loader";
import { useErrorAndSuccessMessageStore } from "../../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../seller-dashboard/ErrorAndSuccessMessage";

const Offers = () => {
  const [tableRowsData, setTableRowsData] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const getCategories = async () => {
    try {
      const res = await fetch(`${url}/api/category/getAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      });
      const data = await res.json();
      if (data.status) {
        setCategories(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStores = async () => {
    try {
      setLoading(true);
      const res = await fetch(`${url}/api/offer/getOffers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      });
      const data = await res.json();
      setLoading(false);
      if (data.status) {
        const allStores = data.data;
        setTableRowsData(allStores);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addOffer = async () => {
    let offerPercentage = percentage;
    if (offerPercentage === "") {
      offerPercentage = 0;
    }
    if (offerPercentage <= 0 || offerPercentage > 100) {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter valid percentage",
      });
    } else if (selectedCategories.length === 0) {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please select atleast one category",
      });
    } else {
      try {
        const res = await fetch(`${url}/api/offer/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            //"Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            percentage: parseInt(offerPercentage),
            categories: selectedCategories,
          }),
        });
        const data = await res.json();
        if (data.status) {
          getStores();
          getCategories();
          setPercentage(0);
          setSelectedCategories([]);
        } else {
          HandleErrorAndSuccessMessage({
            type: "error",
            message: "Something went wrong",
          });
          console.log(data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    window.location.hash = "#offers";
    getStores();
    getCategories();
  }, []);

  return (
    <div className="space-y-3 p-2 relative  overflow-x-auto w-full h-full ">
      {loading && (
        <div className="w-full h-full flex justify-center items-center">
          <Loader width={100} height={100} />
        </div>
      )}
      {!loading && (
        <>
          <div className="space-x-3 text-left flex items-center h-1/4">
            <div>
              <label htmlFor="offerPercentage">Offer Percentage</label>
              <input
                type="number"
                className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
                id="offerPercentage"
                name="offerPercentage"
                placeholder="Enter Offer Percentage"
                value={percentage}
                onChange={(e) => setPercentage(e.target.value)}
              />
            </div>
            <div className="bg-white px-2 py-1  rounded-md border flex flex-col  h-[120px] overflow-y-scroll no-scrollbar w-1/2">
              <div
                className="space-x-1 mx-3"
                style={{
                  display: categories.length === 0 ? "none" : "block",
                }}
              >
                <label htmlFor="elec">All Categories</label>
                <input
                  type="checkbox"
                  id="elec"
                  checked={
                    selectedCategories.length === categories.length
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedCategories(
                        categories.map((category) => category.name)
                      );
                    } else {
                      setSelectedCategories([]);
                    }
                  }}
                />
              </div>
              <div
                className="flex items-center justify-center"
                style={{
                  display: categories.length === 0 ? "block" : "none",
                }}
              >
                <h1 className="text-sm text-[#5D6881] font-semibold ">
                  No Categories Found
                </h1>
              </div>
              <div className="flex flex-wrap">
                {categories.map((category) => (
                  <div className="space-x-1 mx-3">
                    <label htmlFor={category.name}>{category.name}</label>
                    <input
                      type="checkbox"
                      id={category.name}
                      checked={
                        selectedCategories.includes(category.name)
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedCategories([
                            ...selectedCategories,
                            category.name,
                          ]);
                        } else {
                          setSelectedCategories(
                            selectedCategories.filter(
                              (cat) => cat !== category.name
                            )
                          );
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <button
              className="py-2 text-sm text-[white] font-semibold px-4 rounded-lg bg-[#00B5DA]"
              onClick={addOffer}
            >
              Live Offer
            </button>
          </div>
          <div
            className={`h-[75%] space-y-2 p-4 border bg-white border-[#D1D1D1] rounded-lg
        } `}
          >
            <div className="flex relative  items-center ">
              <h1 className="text-sm sm:text-lg text-[#5D6881] font-semibold ">
                Offer{" "}
                <span className="text-[#BDBDBD]">{`(${tableRowsData.length})`}</span>
              </h1>
            </div>

            <div className="overflow-y-scroll no-scrollbar p-2 max-h-[100vh] sm:max-h-[90vh]  md:max-h-[80vh] lg:max-h-[60vh]  xl:max-h-[52vh] ">
              <table id="table" className="inline-table w-full">
                <tbody className="">
                  <tr className="border-b  border-[#D1D1D1]">
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[15%]">
                      Offer No
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[15%]">
                      Percentage
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[40%]">
                      Categories
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[15%]">
                      Status
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[15%]">
                      Action
                    </td>
                  </tr>
                  {tableRowsData.map((row, index) => {
                    return (
                      <tr key={row._id}>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {index + 1}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {row.percentage}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {row.categories.map((category) => category + ", ")}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {row.live ? (
                            <span className="text-green-500">Live</span>
                          ) : (
                            <span className="text-red-500">Ended</span>
                          )}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          <div
                            className={
                              "p-1.5 text-white rounded-md mx-auto w-fit bg-[#00B5DA] hover:cursor-pointer"
                            }
                            onClick={async () => {
                              if (row.live) {
                                try {
                                  const res = await fetch(
                                    `${url}/api/offer/update`,
                                    {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                        //"Access-Control-Allow-Origin": "*",
                                      },
                                      body: JSON.stringify({
                                        id: row._id,
                                        live: false,
                                      }),
                                    }
                                  );
                                  const data = await res.json();
                                  if (data.status) {
                                    getStores();
                                    getCategories();
                                  } else {
                                    HandleErrorAndSuccessMessage({
                                      type: "error",
                                      message: "Something went wrong",
                                    });
                                    console.log(data.message);
                                  }
                                } catch (error) {
                                  console.error(error);
                                }
                              } else {
                                try {
                                  const res = await fetch(
                                    `${url}/api/offer/update`,
                                    {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                        //"Access-Control-Allow-Origin": "*",
                                      },
                                      body: JSON.stringify({
                                        id: row._id,
                                        live: true,
                                      }),
                                    }
                                  );
                                  const data = await res.json();
                                  if (data.status) {
                                    getStores();
                                    getCategories();
                                  } else {
                                    HandleErrorAndSuccessMessage({
                                      type: "error",
                                      message: "Something went wrong",
                                    });
                                    console.log(data.message);
                                  }
                                } catch (error) {
                                  console.error(error);
                                }
                              }
                            }}
                          >
                            {row.live ? "End" : "Live"}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default Offers;
