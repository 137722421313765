import { useEffect, useState } from "react";
import {
  TruckIcon,
  ShopingBagIcon,
  EditIcon,
  FilterIcon,
  ShoppingTrollyIcon,
  IncreaseArrowIcon,
} from "../icons/icons";
import url from "../../Config/url";
import setting from "../../Config/settings.json";
import Loader from "../Loader/Loader";

const Finance = () => {
  const [tableRowsData, setTableRowsData] = useState([]);
  const [totalEarning, setTotalEarning] = useState(0);
  const [totalCommision, setTotalCommision] = useState(0);
  const [pendingFunds, setPendingFunds] = useState(0);
  const [commissionRate, setCommissionRate] = useState(setting.commission);
  const [monthSales, setMonthSales] = useState(0);
  const [monthCommision, setMonthCommision] = useState(0);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const res = await fetch(`${url}/api/finance/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    const data = await res.json();
    setLoading(false);
    if (data.status) {
      let totalEarning = 0;
      let paidAmount = 0;
      setTableRowsData(data.data);
      data.data.forEach((element) => {
        totalEarning += element.total;
        paidAmount += element.paidAmount;
      });
      setTotalEarning(totalEarning);
      setTotalCommision(totalEarning * (commissionRate / 100));
      setPendingFunds(
        totalEarning - (totalEarning * commissionRate) / 100 - paidAmount
      );
    } else {
      console.log(data.message);
    }
  };

  const getMonthData = async () => {
    let date = new Date();
    let start = new Date(date.getFullYear(), date.getMonth(), 1);
    let end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const res = await fetch(
      `${url}/api/order/month?start=${start}&end=${end}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      }
    );
    const data = await res.json();
    if (data.status) {
      let total = 0;
      data.data.forEach((element) => {
        if (
          element.status === "Delivered" ||
          element.status === "Dispatched" ||
          element.status === "Approved"
        ) {
          element.products.forEach((product) => {
            total += product.price * product.qty;
          });
        }
      });
      setMonthSales(total);
      setMonthCommision(total * (commissionRate / 100));
    } else {
      console.log(data.message);
    }
  };

  useEffect(() => {
    window.location.hash = "finance";
  }, []);

  useEffect(() => {
    getData();
    getMonthData();
  }, []);

  return (
    <div className="space-y-3 p-2 w-full h-full">
      {loading && (
        <div className="w-full h-full flex justify-center items-center">
          <Loader width={100} height={100} />
        </div>
      )}
      {!loading && (
        <>
          <div className=" h-[40%] flex flex-col space-y-2 justify-between">
            <div className={`space-x-10 w-full h-1/2 flex  `}>
              <div className=" min-w-[490px] w-3/5 flex flex-col justify-center space-y-2 ">
                <div className="flex h-full space-x-4 ">
                  <div className="w-full rounded-2xl min-w-[150px] text-white bg-gradient-to-r from-blue-400  to-blue-800 ">
                    <div className="text-left mx-3 mt-1 font-bold text-lg">
                      Total Earning
                    </div>
                    <div className="float-right mx-6 text-5xl font-semibold">
                      {totalEarning}
                    </div>
                  </div>
                  <div className="w-full rounded-2xl min-w-[150px] text-white bg-gradient-to-r from-fuchsia-400  to-fuchsia-700 ">
                    <div className="text-left mx-3 mt-1 font-bold text-lg">
                      Total Commision
                    </div>
                    <div className="float-right mx-6 text-5xl font-semibold">
                      {totalCommision}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`space-x-10 w-full  h-1/2 flex `}>
              <div className="flex flex-col justify-center w-full text-left py-2 px-5  rounded-2xl  bg-white ">
                <div className="text-left text-sm text-[#A1A0BD] font-bold ">
                  {new Date().toLocaleString("default", { month: "long" })}{" "}
                  Sales
                </div>
                <div className="text-2xl font-bold">{monthSales}</div>
              </div>
              <div className="flex flex-col justify-center w-full text-left py-2 px-5  rounded-2xl  bg-white ">
                <div className="text-left text-sm text-[#A1A0BD] font-bold ">
                  {new Date().toLocaleString("default", { month: "long" })}{" "}
                  Commision
                </div>
                <div className="text-2xl font-bold">{monthCommision}</div>
              </div>
              <div className="flex flex-col justify-center w-full text-left py-2 px-5  rounded-2xl  bg-white ">
                <div className="text-left text-sm text-[#A1A0BD] font-bold ">
                  Pending Funds
                </div>
                <div className="text-2xl font-bold">{pendingFunds}</div>
              </div>
              <div className="flex flex-col justify-center w-full text-left py-2 px-5  rounded-2xl  bg-white ">
                <div className="text-left text-sm text-[#A1A0BD] font-bold ">
                  Commission Rate
                </div>
                <div className="text-2xl font-bold">{commissionRate}%</div>
                {/* <button
              className="bg-[#00B5DA] flex w-fit rounded px-2 py-0.5 text-xs m-auto text-white"
              onClick={() => {
                const newRate = prompt("Enter new commission rate");
                if (newRate) {
                  setCommissionRate(newRate);
                  fs.writeFileSync(
                    "./src/Config/settings.json",
                    JSON.stringify({ commission: newRate })
                  );
                }
              }}
            >
              Change
            </button> */}
              </div>
            </div>
          </div>
          <div className="h-[57%] space-y-2 p-4 border bg-white border-[#D1D1D1] rounded-lg">
            <div className="flex relative  items-center ">
              <h1 className="text-sm sm:text-lg text-[#5D6881] font-semibold ">
                Store Earnings
              </h1>
            </div>

            <div className="overflow-y-scroll no-scrollbar p-2 max-h-[100vh] sm:max-h-[65vh]  md:max-h-[55vh] lg:max-h-[40vh]  ">
              <table id="table" className="inline-table w-full">
                <tbody className="">
                  <tr className="border-b  border-[#D1D1D1]">
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">No</td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">Store</td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">Account</td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">
                      Amount Paid
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">
                      Amount Due
                    </td>
                  </tr>
                  {tableRowsData.map((row, index) => (
                    <tr
                      key={index}
                      className="border-b border-[#D1D1D1] hover:bg-[#F7F7F7] cursor-pointer"
                    >
                      <td className=" text-sm py-1.5 text-[#7C7C7C]">
                        {index + 1}
                      </td>
                      <td className=" text-sm py-1.5 text-[#7C7C7C]">
                        {row.name}
                      </td>
                      <td className=" text-sm py-1.5 text-[#7C7C7C]">
                        {row.account}
                      </td>
                      <td className=" text-sm py-1.5 text-[#7C7C7C]">
                        {row.paidAmount}
                      </td>
                      <td className=" text-sm py-1.5 text-[#7C7C7C]">
                        {row.total -
                          (row.total * commissionRate) / 100 -
                          row.paidAmount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Finance;
