import { useEffect, useState } from "react";
import { CloseIcon } from "../../icons/icons";
import cn from "classnames";
import url from "../../../Config/url";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";

const MessageDetail = ({
  setMessageDetailVisible,
  name,
  type,
  message,
  date,
  email,
  id,
  isSolved,
  fetchData,
}) => {
  const [reply, setReply] = useState("");
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };
  return (
    <div className=" w-full px-8 py-4 h-full space-y-7 ">
      <div className="flex text-left relative font-medium text-lg text-[#5D6881]">
        <label htmlFor="" className="">
          {name}
        </label>
        <div
          className="absolute right-0 text-black hover:cursor-pointer"
          onClick={() => {
            setMessageDetailVisible(false);
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className=" space-y-4">
        <div className="flex mx-10 flex-row space-x-12">
          <div className=" text-[#5D6881] w-20 text-left font-medium">Type</div>
          <div
            className={cn(
              `text-[white] text-left font-normal px-2 py-1 rounded-lg`,
              {
                "bg-[#FB4C2F]": type === "Complaint",
                "bg-[#FFC740]": type === "Order Inquiry",
                "bg-[#BB2AF7]": type === "Suggestion",
                "bg-[#396BEE]": type === "Query",
              }
            )}
          >
            {type}
          </div>
        </div>
        <div className="flex mx-10 flex-row space-x-12">
          <div className=" text-[#5D6881] w-20 text-left font-medium">
            Message:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">{message}</div>
        </div>
        <div className="flex mx-10 flex-row space-x-12">
          <div className=" text-[#5D6881] w-20 text-left font-medium">
            Date:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {date.split("T")[0]}
          </div>
        </div>
      </div>
      <div className="flex flex-col text-[#344054] text-left space-y-1">
        <label htmlFor="" className="text-xs md:text-sm ">
          Reply
        </label>
        <textarea
          value={reply}
          onChange={(e) => {
            setReply(e.target.value);
          }}
          className="py-2 px-3 border border-[#D0D5DD] rounded-lg"
        ></textarea>
      </div>
      <button
        className="bg-[#00B5DA] rounded-lg px-5 py-2 text-white"
        onClick={async () => {
          if (reply === "") {
            HandleErrorAndSuccessMessage({
              type: "error",
              message: "Please enter a reply",
            });
          } else {
            const res = await fetch(`${url}/api/feedback/update`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                //"Access-Control-Allow-Origin": "*",
              },
              body: JSON.stringify({
                id: id,
                isSolved: true,
                message: reply,
                type: type,
              }),
            });
            const response = await res.json();
            if (response.status) {
              fetchData();
              setMessageDetailVisible(false);
            } else {
              HandleErrorAndSuccessMessage({
                type: "error",
                message: "Something went wrong",
              });
              console.log(response.message);
            }
          }
        }}
      >
        Reply via email
      </button>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default MessageDetail;
