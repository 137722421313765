import React, { useEffect, useState } from "react";
import url from "../../../Config/url";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const location = useLocation();
  const [role, setRole] = useState(location?.state?.role ?? "");
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const sendCode = async () => {
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter a valid email",
      });
      return;
    } else {
      const response = await fetch(`${url}/api/user/sendCode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      const data = await response.json();
      if (data.status) {
        HandleErrorAndSuccessMessage({
          type: "success",
          message: "Code sent to " + email,
        });
      } else {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Something went wrong",
        });
        console.log(data.message);
      }
    }
  };

  const verifyCode = async () => {
    const response = await fetch(`${url}/api/user/forgotPassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        email: email,
        code: code,
      }),
    });
    const data = await response.json();
    if (data.status) {
      navigate("/user/change-password", {
        state: { email: email, role: role },
      });
    } else {
      if (data.message === "Code does not match") {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Code does not match",
        });
      } else {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Something went wrong",
        });
        console.log(data.message);
      }
    }
  };

  useEffect(() => {
    if (role === "") {
      navigate("/user/login");
    }
  }, []);

  return (
    <div className="h-[100vh] md:h-[100%] xl:h-screen flex p-0 m-auto flex items-center  bg-white   md:min-w-[1000px]">
      <div className="w-full md:w-1/3 b flex justify-center md:items-center">
        <div className="mx-16 my-10 relative ">
          <div className="text-left space-y-3">
            <div className=" text-2xl lg:text-4xl font-bold">
              Forgot password?
            </div>
            <div>Please enter your email address. </div>
          </div>
          <div className="border-y-2 border-dashed space-y-2 lg:space-y-4 my-5 py-5  ">
            <div className="flex flex-col relative text-left space-y-1">
              <label className="text-sm" htmlFor="email">
                Email Address
              </label>
              <input
                type="email"
                placeholder="john@gmail.com"
                className=" border p-2"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
              <div
                className="flex justify-end text-xs text-[#00B5DA] font-semibold hover:cursor-pointer"
                onClick={() => {
                  sendCode();
                }}
              >
                Send Code
              </div>
            </div>
            <div className="flex flex-col relative text-left space-y-1">
              <label className="text-sm" htmlFor="code">
                Verification Code
              </label>
              <input
                type="number"
                placeholder="****"
                className=" border p-2"
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                value={code}
              />
            </div>
            <button
              className="bg-[#00B5DA]  text-white p-2 px-3 m-auto w-full  flex items-center justify-center text-sm"
              onClick={() => {
                verifyCode();
              }}
            >
              Request password change
            </button>
          </div>
          <div className="absolute flex flex-col w-full text-sm space-y-2 items-center justify-center ">
            <div className="text-[#333333]">Do you need help?</div>
            <div className="text-[#00B5DA]">Customer support</div>
          </div>
        </div>
      </div>
      <div className="hidden md:flex xl:w-2/3 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 h-full"></div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default ForgetPassword;
