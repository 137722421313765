import React, { useState } from "react";
import url from "../../../Config/url";
import { useNavigate } from "react-router";
import { SHA512 } from "crypto-js";
import validator from "validator";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";

const UserSignup = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [identity, setIdentity] = useState("");
  const [address, setAddress] = useState("");
  const [agree, setAgree] = useState(false);
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const sendCode = async () => {
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter a valid email",
      });
      return;
    } else {
      const response = await fetch(`${url}/api/user/sendCode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      const data = await response.json();
      if (data.status) {
        HandleErrorAndSuccessMessage({
          type: "success",
          message: "Code sent to " + email,
        });
      } else {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Something went wrong",
        });
        console.log(data.message);
      }
    }
  };

  const signup = async () => {
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (agree) {
      if (validator.isMobilePhone(`+${phone}`)) {
        if (emailRegex.test(email)) {
          if (name.length > 0) {
            if (validator.isStrongPassword(password)) {
              if (code.length > 0) {
                const hashPassword = SHA512(password).toString();
                const response = await fetch(`${url}/api/user/add`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    //"Access-Control-Allow-Origin": "*",
                  },
                  body: JSON.stringify({
                    name: name,
                    email: email,
                    contact: `+${phone}`,
                    password: hashPassword,
                    identityno: identity,
                    address: address,
                    code: code,
                    role: "customer",
                  }),
                });
                const data = await response.json();
                if (data.status) {
                  HandleErrorAndSuccessMessage({
                    type: "success",
                    message: "Your account has been created successfully",
                  });
                  navigate("/user/login");
                } else {
                  if (data.message === "Code does not match") {
                    HandleErrorAndSuccessMessage({
                      type: "error",
                      message: "Code does not match",
                    });
                  } else {
                    HandleErrorAndSuccessMessage({
                      type: "error",
                      message: "Something went wrong",
                    });
                    console.log(data.message);
                  }
                }
              } else {
                HandleErrorAndSuccessMessage({
                  type: "error",
                  message: "Please enter verification code",
                });
              }
            } else {
              HandleErrorAndSuccessMessage({
                type: "error",
                message: "Please enter a strong password.",
              });
            }
          } else {
            HandleErrorAndSuccessMessage({
              type: "error",
              message: "Please enter your name",
            });
          }
        } else {
          HandleErrorAndSuccessMessage({
            type: "error",
            message: "Please enter a valid email",
          });
        }
      } else {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Please enter a valid phone number",
        });
      }
    } else {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please agree to terms and conditions",
      });
    }
  };

  return (
    <div className="h-[100vh] md:h-[100%] xl:h-screen flex p-0 m-auto flex   bg-white   md:min-w-[1000px]">
      <div className="w-full md:w-2/3  flex justify-center md:items-center">
        <div className="mx-10  my-10 relative ">
          <div className="text-left space-y-3">
            <div className=" text-2xl lg:text-4xl font-bold">Sign Up</div>
            <div>
              Please input your information in the fields below to login.{" "}
            </div>
          </div>
          <div className="border-y-2 border-dashed space-y-3 lg:space-y-4 my-5 py-5  ">
            <div className="flex flex-col md:flex-row md:space-x-8 space-y-3 md:space-y-0">
              <div className="flex flex-col relative text-left space-y-1">
                <label className="text-sm" htmlFor="email">
                  Full Name
                </label>
                <input
                  type="text"
                  placeholder="John Doe"
                  className=" border p-2"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col relative text-left space-y-1">
                <label className="text-sm" htmlFor="email">
                  Email Address
                </label>
                <input
                  type="email"
                  placeholder="john@gmail.com"
                  className=" border p-2"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <div
                  className="flex justify-end text-xs text-[#00B5DA] font-semibold hover:cursor-pointer"
                  onClick={() => {
                    sendCode();
                  }}
                >
                  Send Code
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:space-x-8 space-y-3 md:space-y-0">
              <div className="flex flex-col relative text-left space-y-1">
                <label className="text-sm" htmlFor="code">
                  Phone Number
                </label>
                <input
                  type="number"
                  placeholder="92123456789"
                  className=" border p-2"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col relative text-left space-y-1">
                <label className="text-sm" htmlFor="code">
                  Verification Code
                </label>
                <input
                  type="number"
                  placeholder="******"
                  className=" border p-2"
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:space-x-8 space-y-3 md:space-y-0">
              <div className="flex flex-col relative text-left space-y-1">
                <label className="text-sm" htmlFor="code">
                  Password
                </label>
                <input
                  type="password"
                  placeholder="****"
                  className=" border p-2"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col relative text-left space-y-1">
                <label className="text-sm" htmlFor="code">
                  Identity Number
                </label>
                <input
                  type="number"
                  placeholder="123456789"
                  className=" border p-2"
                  value={identity}
                  onChange={(e) => {
                    setIdentity(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col relative text-left space-y-1">
              <label className="text-sm" htmlFor="code">
                Address
              </label>
              <input
                type="text"
                placeholder="Address"
                className=" border p-2"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
            <div className="flex justify-between">
              <div className="flex space-x-2 items-center text-xs font-semibold ">
                <input
                  type="checkbox"
                  name="terms"
                  id="terms"
                  onChange={(e) => {
                    setAgree(e.target.checked);
                  }}
                  value={agree}
                />
                <label htmlFor="terms">I agree with terms and conditins.</label>
              </div>
              <button
                className="bg-[#00B5DA]  text-white p-2 px-3    flex items-center justify-center text-sm"
                onClick={() => {
                  signup();
                }}
              >
                Register
              </button>
            </div>
          </div>
          <div
            className="absolute flex flex-col text-[#01ADDD] hover:cursor-pointer hover:underline font-semibold w-full text-sm space-y-2 items-center justify-center"
            onClick={() => {
              navigate("/user/login");
            }}
          >
            Already a member?
          </div>
        </div>
      </div>
      <div className="hidden md:flex xl:w-1/3 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 h-full"></div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default UserSignup;
