import { useEffect, useState } from "react";
import { FilterIcon, TruckIcon } from "../icons/icons";
import OrderDetails from "./SellerOrderDetails";
import img from "../../assets/images/samsungLED.webp";
import cn from "classnames";
import url from "../../Config/url";
import Loader from "../Loader/Loader";

const SellerOrders = ({ store }) => {
  const [orderDetailVisible, setOrderDetailVisible] = useState(false);
  const [orders, setOrders] = useState([]);
  const [obj, setObj] = useState({});
  const [loading, setLoading] = useState(false);

  const getOrders = async () => {
    try {
      setLoading(true);
      let arr = [];
      const res = await fetch(
        `${url}/api/order/getOrdersForSeller?store=${store.name}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            //"Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await res.json();
      setLoading(false);
      if (!data.status) throw new Error(data.message);
      else {
        arr = data.data;

        arr.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA; // To sort in descending order (newest date first)
        });

        setOrders(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.location.hash = "#orders";
    getOrders();
  }, []);

  return (
    <div className="space-y-3 relative p-2 w-full h-full">
      {loading && (
        <div className="w-full h-full flex justify-center items-center">
          <Loader width={100} height={100} />
        </div>
      )}
      {!loading && (
        <>
          <div
            className={`h-full space-y-2 p-4 border bg-white border-[#D1D1D1] rounded-lg ${
              orderDetailVisible && "blur-sm"
            }`}
          >
            <div className="flex relative  items-center ">
              <h1 className="text-sm sm:text-lg text-[#5D6881] font-semibold ">
                Orders <span className="text-[#BDBDBD]">({orders.length})</span>
              </h1>
              {/* <div className="flex w-32 h-8 sm:h-auto sm:w-auto absolute right-0 hover:cursor-pointer   ">
            <label
              htmlFor="filter"
              className="absolute right-3 text-[#7C7C7C] hover:cursor-pointer"
            >
              <FilterIcon />
            </label>
            <select
              id="filter"
              // ref={selectRef}
              // onChange={(e) => setFilter(e.target.value)}
              className="bg-[#F2F7FF] rounded-lg w-24 sm:w-auto  hover:cursor-pointer p-1 appearance-none"
            >
              <option value="highToLow">Filter</option>
              <option value="highToLow">High to Low</option>
              <option value="lowToHigh">Low to High</option>
              <option value="aToZ">A to Z</option>
              <option value="zToA">Z to A</option>
            </select>
          </div> */}
            </div>

            <div className="overflow-y-scroll no-scrollbar p-2 max-h-[80vh]">
              <table id="table" className="inline-table w-full">
                <tbody className="">
                  <tr className="border-b  border-[#D1D1D1]">
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">No</td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">Order ID</td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">
                      Customer Name
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">Address</td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">Date</td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">Amount</td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">Status</td>
                  </tr>
                  {orders.map((item, index) => {
                    return (
                      <tr key={item._id} className="border-b  border-[#D1D1D1]">
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          {index + 1}
                        </td>
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          {item._id}
                        </td>
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          {item.name}
                        </td>
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          {item.address}
                        </td>
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          {new Date(item.date).toLocaleDateString()}
                        </td>
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          {item.price * item.qty}
                        </td>
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          <div
                            className={cn(
                              "py-1 px-2 text-white rounded-full mx-auto w-fit ",
                              {
                                "bg-[#039855]": item.status === "Delivered",
                                "bg-[#344054]": item.status === "Returned",
                                "bg-[#D92D20]": item.status === "Cancelled",
                                "bg-[#7F56D9]": item.status === "Dispatched",
                                "bg-[#6BAAFC]": item.status === "Processing",
                                "bg-[#F2C94C]": item.status === "Approved",
                              }
                            )}
                          >
                            {item.status}
                          </div>
                        </td>
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          <div
                            className={
                              "p-1.5 text-white rounded-md mx-auto w-fit bg-[#00B5DA] hover:cursor-pointer"
                            }
                            onClick={() => {
                              setOrderDetailVisible(true);
                              setObj(item);
                            }}
                          >
                            View
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {obj.products && (
            <div
              className={
                !orderDetailVisible
                  ? "hidden"
                  : "bg-white rounded-xl absolute shadow-lg bottom-2 top-0 inset-x-40 flex items-center justify-center"
              }
            >
              <OrderDetails
                setOrderDetailVisible={setOrderDetailVisible}
                obj={obj}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SellerOrders;
