import React from "react";
import { useNavigate } from "react-router-dom";
import laptop from "../../../assets/images/laptop.png";
import {
  CartIcon,
  ProductCartIcon,
  ProductStarIcon,
  StarIcon,
} from "../../icons/icons";
import url from "../../../Config/url";

const CategoryItem = ({ product }) => {
  const navigate = useNavigate();

  const getProductsByCategory = async (category) => {
    let response = await fetch(
      `${url}/api/product/getProductsByFilter?category=${category}&isActive=true`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      }
    );
    let data = await response.json();
    if (data.status) {
      navigate("/products", { state: { products: data.data } });
    } else {
      if (data.message === "No products found") {
        navigate("/products", { state: { products: [] } });
      } else {
        console.log(data.message);
      }
    }
  };

  return (
    <div
      className="border flex flex-col bg-white hover:cursor-pointer space-y-1 relative text-center p-4 shadow-md rounded-lg"
      onClick={() => getProductsByCategory(product.name)}
    >
      <img
        src={product.image}
        alt={product.name}
        className="w-28 m-auto h-28 object-contain"
      />
      <p className="text-center text-lg font-medium ">{product.name}</p>
    </div>
  );
};

export default CategoryItem;
