import create from "zustand";


export const useErrorAndSuccessMessageStore = create((set) => ({
    type : "",
    message: "",
    // Function to update error message
    updatePopupMessage: (type, message) => set({ type, message }),

    // Function to clear message
    clearPopupMessage: () => set({ type: "", message: "" }),
}));

