import { useEffect, useState } from "react";
import {
  TruckIcon,
  ShopingBagIcon,
  ShoppingTrollyIcon,
  FilterIcon,
} from "../icons/icons";
import AddStore from "./stores/AddStore";
import url from "../../Config/url";
import Loader from "../Loader/Loader";

const Products = () => {
  const [tableRowsData, setTableRowsData] = useState([]);
  const [addStoreVisible, setAddStoreVisible] = useState(false);
  const [inStockProducts, setInStockProducts] = useState(0);
  const [loading, setLoading] = useState(true);

  const getStores = async () => {
    try {
      setLoading(true);
      const res = await fetch(`${url}/api/product/getProductsForAdmin`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      });
      const data = await res.json();
      setLoading(false);
      if (data.status) {
        const allStores = data.data;
        let isStock = 0;
        for (let i = 0; i < allStores.length; i++) {
          if (allStores[i].isActive) {
            isStock++;
          }
        }
        setInStockProducts(isStock);
        setTableRowsData(allStores);
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.location.hash = "#products";
    getStores();
  }, []);

  return (
    <div className="space-y-3 p-2 relative  overflow-x-auto w-full h-full ">
      {loading && (
        <div className="w-full h-full flex justify-center items-center">
          <Loader width={100} height={100} />
        </div>
      )}
      {!loading && (
        <>
          <div
            className={`space-x-10 h-1/5 flex ${addStoreVisible && "blur-sm"} `}
          >
            <div className=" min-w-[490px] w-3/4 flex flex-col justify-center space-y-2 ">
              <div className="flex flex h-full space-x-4 ">
                <div className="w-full rounded-2xl min-w-[150px] text-white bg-gradient-to-r from-blue-300  to-blue-700 ">
                  <div className="text-left mx-3 mt-1 font-bold text-lg">
                    Total Products
                  </div>
                  <div className="absolute flex w-fit ">
                    <TruckIcon />
                  </div>
                  <div className="float-right mx-6 text-5xl font-semibold">{`${tableRowsData.length}`}</div>
                </div>
                <div className="w-full rounded-2xl min-w-[150px] text-white bg-gradient-to-r from-rose-300  to-rose-700 ">
                  <div className="text-left mx-3 mt-1 font-bold text-lg">
                    Active Products
                  </div>
                  <div className="absolute flex w-fit ">
                    <ShoppingTrollyIcon />
                  </div>
                  <div className="float-right mx-6 text-5xl font-semibold">{`${inStockProducts}`}</div>
                </div>
                {/* <div className="w-full rounded-2xl min-w-[150px] text-white bg-gradient-to-r from-fuchsia-300  to-fuchsia-600 ">
              <div className="text-left mx-3 mt-1 font-bold text-lg">
                Approved Store
              </div>
              <div className="absolute flex w-fit ">
                <ShopingBagIcon />
              </div>
              <div className="float-right mx-6 text-5xl font-semibold">{`${approvedStores}`}</div>
            </div> */}
              </div>
            </div>
            {/* <div className="m-auto w-1/5">
          <button
            className="py-2 text-sm text-[white] font-semibold px-4 rounded-lg bg-[#00B5DA]"
            onClick={() => setAddStoreVisible(true)}
          >
            Add Store
          </button>
        </div> */}
          </div>
          <div
            className={`h-[75%] space-y-2 p-4 border bg-white border-[#D1D1D1] rounded-lg ${
              addStoreVisible && "blur-sm"
            } `}
          >
            <div className="flex relative  items-center ">
              <h1 className="text-sm sm:text-lg text-[#5D6881] font-semibold ">
                Products{" "}
                <span className="text-[#BDBDBD]">{`(${tableRowsData.length})`}</span>
              </h1>
              {/* <div className="flex w-32 h-8 sm:h-auto sm:w-auto absolute right-0 hover:cursor-pointer   ">
                        <label 
                        htmlFor="filter"
                        className="absolute right-3 text-[#7C7C7C] hover:cursor-pointer"
                        >
                            <FilterIcon /></label>
                    <select
                    id="filter"
                        // ref={selectRef}
                        // onChange={(e) => setFilter(e.target.value)}
                        className="bg-[#F2F7FF] rounded-lg w-24 sm:w-auto  hover:cursor-pointer p-1 appearance-none"
                        
                    >
                        <option value="highToLow">Filter</option>
                        <option value="highToLow">High to Low</option>
                        <option value="lowToHigh">Low to High</option>
                        <option value="aToZ">A to Z</option>
                        <option value="zToA">Z to A</option>
                    </select>
                    </div> */}
            </div>

            <div className="overflow-y-scroll no-scrollbar p-2 max-h-[100vh] sm:max-h-[90vh]  md:max-h-[80vh] lg:max-h-[60vh]  xl:max-h-[52vh] ">
              <table id="table" className="inline-table w-full">
                <tbody className="">
                  <tr className="border-b  border-[#D1D1D1]">
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[5%]">
                      No
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[20%]">
                      Name
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[40%]">
                      Description
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[12.5%]">
                      Category
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[15%]">
                      Store
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[7.5%]">
                      Status
                    </td>
                  </tr>
                  {tableRowsData.map((row, index) => {
                    return (
                      <tr key={row._id}>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {index + 1}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {row.name}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {row.description}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {row.category}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {row.store}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {row.isActive ? (
                            <span className="text-green-500">Active</span>
                          ) : (
                            <span className="text-red-500">InActive</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className={
              !addStoreVisible
                ? "hidden"
                : "bg-white rounded-lg absolute shadow-lg bottom-3 top-0 inset-x-72 flex items-center justify-center"
            }
          >
            <AddStore
              setAddStoreVisible={setAddStoreVisible}
              getStores={getStores}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Products;
