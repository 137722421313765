import AllCategories from "../components/customer-dashboard/categories/AllCategories";
import Footer from "../components/customer-dashboard/footer/Footer";
import SubscribeOurNewsLetter from "../components/customer-dashboard/subscribe-newsletter/SubscribeOurNewsLetter";
import CustomerNavbar from "../components/navbar/customer-navbar/CustomerNavbar";
import url from "../Config/url";
import { useState, useEffect } from "react";
import Loader from "../components/Loader/Loader";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCategories = async () => {
    const response = await fetch(`${url}/api/category/getAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    const data = await response.json();
    setLoading(false);
    if (data.status) {
      setCategories(data.data);
    } else {
      console.log(data.message);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="">
      <div>
        <CustomerNavbar />
      </div>
      <div className=" mx-3 md:mx-20 space-y-12 my-4 md:my-10">
        {loading ? (
          <Loader width={100} height={100} />
        ) : (
          <AllCategories categories={categories} />
        )}
      </div>
      <SubscribeOurNewsLetter />
      <Footer />
    </div>
  );
};

export default Categories;
