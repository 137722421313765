import React, { useState } from 'react';
import ProductItem from './ProductItem';

const ProductDisplay = ({ products, itemsPerRow }) => {
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const last = startIndex + itemsPerPage;
  const [endIndex, setEndIndex] = useState(last);

  const visibleProducts = products.slice(startIndex, endIndex);

  const rows = [];
  for (let i = 0; i < visibleProducts.length; i += itemsPerRow) {
    const row = visibleProducts.slice(i, i + itemsPerRow);
    rows.push(row);
  }

  const loadMore = () => {
        setEndIndex(endIndex+20)
    // setCurrentPage(currentPage + 1);
  };

  return (
    <div className=''>
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className="flex  ">
          {row.map((product) => (
            <div key={product.id} className={`w-1/${itemsPerRow} m-2 `}>
              <ProductItem product={product} />
            </div>
          ))}
        </div>
      ))}
      {endIndex < products.length && (
        <div className="w-full text-center my-4 ">
          <button
            className="bg-[#00B5DA] text-white py-2 px-4 rounded-md"
            onClick={loadMore}
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductDisplay;
