import React, { useState } from "react";
import img from "../../../assets/images/shirt.jpeg";
import {
  DeliveryIcon,
  ReturnPolicyIcon,
  StarIcon,
  ThumbIcon,
  TickIcon,
  VerifiedIcon,
  WorldIcon,
} from "../../icons/icons";
import url from "../../../Config/url";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";

const ProductDescriptionAndReview = ({ product }) => {
  const [isDescription, setIsDescription] = useState(true);
  const [totalRating, setTotalRating] = useState(
    product.reviews.length > 0
      ? product.reviews.reduce((acc, item) => acc + item.rating, 0) /
          product.reviews.length
      : 0
  );
  const [comments, setComments] = useState("");
  const [rating, setRating] = useState(0);
  const [name, setName] = useState("");
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const addReview = async () => {
    let newReview = {
      name: name,
      comment: comments,
      rating: rating,
    };
    let response = await fetch(`${url}/api/product/addReview`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ id: product._id, newReview: newReview }),
    });
    let data = await response.json();
    if (data.status) {
      HandleErrorAndSuccessMessage({
        type: "success",
        message: "Review added successfully",
      });
      setComments("");
      setRating(0);
      setName("");
      window.location.reload();
    } else {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Something went wrong",
      });
      console.log(data.message);
    }
  };

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted review:", { comments, rating });
  };

  return (
    <div className="border border-[#DEE2E7] rounded-lg bg-white ">
      <div className="text-left space-x-6 border-b py-2 px-3">
        <span
          className={`p-2 font-semibold hover:cursor-pointer ${
            isDescription
              ? "text-[#00B5DA]  border-b-2  border-[#00B5DA]"
              : "text-[#8B96A5]"
          }`}
          onClick={() => setIsDescription(true)}
        >
          Description
        </span>
        <span
          className={`p-2 font-semibold hover:cursor-pointer ${
            !isDescription
              ? "text-[#00B5DA]  border-b-2  border-[#00B5DA]"
              : "text-[#8B96A5]"
          }`}
          onClick={() => setIsDescription(false)}
        >
          Reviews
        </span>
      </div>
      <div>
        {isDescription ? (
          <div className="text-left p-3 space-y-10">
            <div>
              {product.description.length > 0 ? (
                <div className="text-[#667085]">{product.description}</div>
              ) : (
                <div className="text-[#8B96A5]">No description available</div>
              )}
            </div>
            {/* <div className="space-y-5">
              <div className="md:w-2/3 flex">
                <div className="w-1/4 border flex bg-[#EFF2F4] flex-col">
                  <span className="border border-[#E0E7E9] px-3 py-2">
                    Model
                  </span>
                  <span className="border border-[#E0E7E9] px-3 py-2">
                    Style
                  </span>
                  <span className="border border-[#E0E7E9] px-3 py-2">
                    Certificate
                  </span>
                  <span className="border border-[#E0E7E9] px-3 py-2">
                    Size
                  </span>
                  <span className="border border-[#E0E7E9] px-3 py-2">
                    Memory
                  </span>
                </div>
                <div className="w-3/4 flex flex-col">
                  <span className="border border-[#E0E7E9] px-4 py-2">
                    #8786867
                  </span>
                  <span className="border border-[#E0E7E9] px-4 py-2">
                    Classic style
                  </span>
                  <span className="border border-[#E0E7E9] px-4 py-2">
                    ISO-898921212
                  </span>
                  <span className="border border-[#E0E7E9] px-4 py-2">
                    34mm x 450mm x 19mm
                  </span>
                  <span className="border border-[#E0E7E9] px-4 py-2">
                    36GB RAM
                  </span>
                </div>
              </div>
              <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-20">
                <div className="space-y-2">
                  {desc.map(
                    (item, index) =>
                      index % 2 === 0 && (
                        <div className="space-x-3  flex  ">
                          <TickIcon className="text-[#8B96A5] flex items-center " />
                          <span className=" ">
                            Some great feature name here
                          </span>
                        </div>
                      )
                  )}
                </div>
                <div className="space-y-2">
                  {desc.map(
                    (item, index) =>
                      index % 2 !== 0 && (
                        <div className="space-x-3  flex  ">
                          <TickIcon className="text-[#8B96A5] flex items-center " />
                          <span className=" ">
                            Some great feature name here
                          </span>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div> */}
            <div></div>
          </div>
        ) : (
          <div className=" px-2 md:px-10 space-y-4 md:space-y-0 py-3 flex flex-col md:flex-row md:space-x-7 ">
            <div className="space-y-3 p-6 bg-[#F9FAFB] md:w-[300px] h-[250px] flex flex-col items-center justify-center">
              <div className="text-4xl text-[#164C96] font-bold">
                {totalRating.toFixed(1)}
              </div>
              {/* <div className="text-[#E59819] space-x-2">
                {Array.from({ length: Math.floor(totalRating) }).map((_, i) => (
                  <StarIcon key={i} />
                ))}
              </div> */}
              <div>Product Rating</div>
            </div>
            <div className="flex flex-col w-full  ">
              <div className="w-full px-0 md:px-4">
                <form
                  onSubmit={handleSubmit}
                  className="mb-4 space-y-2 text-left"
                >
                  <div className="flex items-center">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <button
                        key={star}
                        type="button"
                        onClick={() => handleStarClick(star)}
                        className={`text-xl ${
                          star <= rating ? "text-yellow-400" : "text-gray-400"
                        } focus:outline-none`}
                      >
                        ★
                      </button>
                    ))}
                  </div>
                  <input
                    id="name"
                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                    type="text"
                    placeholder="Name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <textarea
                    id="comments"
                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                    rows="2"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    required
                    placeholder="Comments"
                  />
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-[#00B5DA] text-white font-medium py-1 px-3 rounded-lg  focus:outline-none focus:ring focus:bg-blue-600"
                      onClick={addReview}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <div className=" px-0 py-5 md:px-3 md:py-3 space-y-5 overflow-y-scroll  max-h-[600px] no-scrollbar">
                {product.reviews.length > 0 ? (
                  product.reviews.map((review) => (
                    <div className="flex  space-x-4">
                      <span className="bg-[#164C96] text-white h-fit  text-base py-2 flex items-center justify-center px-4 rounded-full ">
                        {review.name[0].toUpperCase()}
                      </span>
                      <div className="text-left space-y-3">
                        <div>
                          <div className="space-x-3">
                            <span className="font-semibold">{review.name}</span>
                            {/* <span className="text-xs">3 Days Ago</span> */}
                          </div>
                          <div className="text-[#E59819] text-left space-x-1 text-sm">
                            {Array.from({
                              length: Math.floor(review.rating),
                            }).map((_, i) => (
                              <StarIcon key={i} />
                            ))}
                          </div>
                        </div>
                        {/* <div className="font-semibold">
                        The best product In Market
                      </div> */}
                        <div className="text-[#667085]">{review.comment}</div>
                        {/* <div className="text-[#667085] space-x-2 flex items-center">
                        <ThumbIcon className="" />
                        <span className="">Like</span>
                      </div> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-[#8B96A5]">No reviews available</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default ProductDescriptionAndReview;
