import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.svg";
import SellerAddressInfo from "../components/seller-dashboard/seller-signup/AddressInfo";
import SellerBankInfo from "../components/seller-dashboard/seller-signup/BankInfo";
import ProductBasicInfo from "../components/seller-dashboard/adding-product/ProductBasicInfo";
import ProductDescription from "../components/seller-dashboard/adding-product/ProductDescription";
import ProductStockAndPrice from "../components/seller-dashboard/adding-product/StockAndPrice";
import ProductList from "../components/seller-dashboard/adding-product/ProductList";
import { useNavigate } from "react-router-dom";
import url from "../Config/url";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import { useErrorAndSuccessMessageStore } from "../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../components/seller-dashboard/ErrorAndSuccessMessage";

const AddingProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 3000);
  };

  const [productInfo, setProductInfo] = useState("product-basic-info");
  const [seller, setSeller] = useState(
    localStorage.getItem("seller")
      ? JSON.parse(localStorage.getItem("seller"))
      : null
  );
  const [status, setStatus] = useState("");
  const url1 = window.location.href;
  const hash = window.location.hash.substring(1);
  const [product, setProduct] = useState(location?.state?.product ?? {});
  const [buttonText, setButtonText] = useState(
    product.hasOwnProperty("_id") ? "Edit Product" : "Add Product"
  );
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState("");

  const handleStatus = async () => {
    const res = await fetch(
      `${url}/api/store/getStoreByFilter?store_holder=${seller.email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      }
    );
    const data = await res.json();
    if (data.data[0].isActive) {
      setStatus(data.data[0].status);
      setStore(data.data[0].name);
      setLoading(false);
    } else {
      HandleErrorAndSuccessMessage({
        type: "error",
        message:
          "There is a problem with your store. Please contact admin for more details",
      });
    }
  };

  useEffect(() => {
    if (seller === null) {
      navigate("/user/login");
    } else {
      handleStatus();
    }
  }, []);

  useEffect(() => {
    window.location.hash = productInfo;
  }, [productInfo]);

  return (
    <>
      {loading && (
        <div className="flex items-center justify-center w-[100vw] h-[100vh]">
          <Loader width={100} height={100} />
        </div>
      )}
      {!loading && (
        <div>
          <div
            className=" h-full m-10 space-y-6 "
            style={{
              display: status === "Approved" ? "flex" : "none",
            }}
          >
            <div>
              <img src={logo} alt="" />
            </div>
            <div className="">
              {productInfo === "product-basic-info" && (
                <ProductBasicInfo
                  setProductInfo={setProductInfo}
                  product={product}
                  setProduct={setProduct}
                  store={store}
                />
              )}
              {productInfo === "product-description" && (
                <ProductDescription
                  setProductInfo={setProductInfo}
                  product={product}
                  setProduct={setProduct}
                />
              )}
              {productInfo === "product-stock&price" && (
                <ProductStockAndPrice
                  setProductInfo={setProductInfo}
                  product={product}
                  setProduct={setProduct}
                  buttonText={buttonText}
                />
              )}
            </div>
          </div>
          <div
            className="container min-w-[98%] h-[100vh]  flex flex-row items-center justify-center bg-[#F8F7FC]"
            style={{
              display: status !== "Approved" ? "flex" : "none",
            }}
          >
            <div className="w-full h-full flex flex-col items-center justify-center">
              <h1 className="text-2xl text-red-500">
                Your Store is not Approved yet
              </h1>
              <span className="text-2xl text-green-500 mt-1">
                You will be notified by email when your store is approved
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </>
  );
};

export default AddingProduct;
