import React, { useState, useEffect } from "react";
import CustomerNavbar from "../components/navbar/customer-navbar/CustomerNavbar";
import ProductDetailWithPicture from "../components/customer-dashboard/product-detail/ProductDetailWithPicture";
import ProductDescriptionAndReview from "../components/customer-dashboard/product-detail/ProductDescriptionAndReviews";
import PeopleAlsoViewed from "../components/customer-dashboard/product-detail/PeopleAlsoViewed";
import DiscountBanner from "../components/customer-dashboard/product-detail/DiscountBanner";
import Footer from "../components/customer-dashboard/footer/Footer";
import { useParams } from "react-router";
import url from "../Config/url";
import Loader from "../components/Loader/Loader";

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);

  const getProduct = async () => {
    let response = await fetch(`${url}/api/product/getProductById?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    let data = await response.json();
    if (data.status) {
      setProduct(data.data);
      setLoading(false);
    } else {
      console.log(data.message);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);
  return (
    <div className=" md:min-w-[1000px] ">
      <div>
        <CustomerNavbar />
      </div>
      {loading && <Loader width={100} height={100} />}
      {!loading && (
        <div className=" mx-3 md:mx-20 space-y-3 my-4 md:my-10  ">
          <ProductDetailWithPicture product={product} />
          <ProductDescriptionAndReview product={product} />
          {/* <PeopleAlsoViewed />
        <DiscountBanner /> */}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ProductDetail;
