import React from 'react';
import { useNavigate } from 'react-router-dom';
import laptop from '../../../assets/images/laptop.png'
import { CloseIcon } from '../../icons/icons';


const MyCartNavbar = ({setMyCart}) => {
    const navigate = useNavigate();
    const product = [
        'samsung',
        'samsung',
        'samsung',
        'samsung',
        'samsung'
    ]
  return (
    <div className='bg-white py-3 space-y-3  rounded-lg border border-[#E3E3E3] shadow-md  px-5 '>
        <div className='text-left text-sm'>You have <span className='text-[#0B67F7]'>{product.length}</span> item in your cart</div>
        <div className='space-y-2 max-h-[265px] overflow-y-scroll no-scrollbar'>
            { product.map((item, index)=>(
            <div key={index} className='flex relative border items-center justify-between shadow-md p-2 rounded-xl '>
            <div className='flex space-x-4'>
                <img className='h-16 w-16' src={laptop} alt="" />
                <div className='text-left'>
                    <p className='text-md font-medium'>GoPro HERO6 4K Camera</p>
                    <p className='text-xs font-normal'>Smart Electronics</p>
                </div>
            </div>
            <div className='absolute right-6 top-2 hover:cursor-pointer' ><CloseIcon className="text-xs" /></div>
            <div className='flex space-x-6 absolute right-3 bottom-3 '>
                <div className='flex space-x-2 items-center'>
                    <span className='bg-[#83838333] rounded-full flex items-center justify-center w-fit h-4 w-4 pb-1  px-1'>-</span>
                    <span>1</span>
                    <span className='bg-[#83838333] rounded-full flex items-center justify-center w-fit h-4 w-4 pb-1  px-1'>+</span>
                </div>
                <span>PKR 20000</span>
            </div>
            </div>
            ))
            }
        </div>
        <button className="bg-[#00B5DA]   text-white h-8 py-1 px-4 rounded-md"
        onClick={()=>(navigate('/cart'), setMyCart(false))}
        >
        Proceed to checkout
        </button>
    </div>
  );
};

export default MyCartNavbar;
