import { EmailIcon } from "../../icons/icons";
import { useState } from "react";
import url from "../../../Config/url";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";

const SubscribeOurNewsLetter = () => {
  const [email, setEmail] = useState("");
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const subscribe = async () => {
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter a valid email",
      });
    } else {
      const response = await fetch(`${url}/api/newsletter/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ email: email }),
      });
      const data = await response.json();
      if (data.status) {
        HandleErrorAndSuccessMessage({
          type: "success",
          message: "Subscribed successfully",
        });
        setEmail("");
      } else {
        if (data.message === "Email already subscribed") {
          HandleErrorAndSuccessMessage({
            type: "error",
            message: "Email already subscribed",
          });
          setEmail("");
        } else {
          console.log(data.message);
        }
      }
    }
  };

  return (
    <div className="space-y-6 flex flex-col py-10 bg-[#EFF2F4]">
      <div>
        <div className="text-lg font-semibold">Subscribe on our newsletter</div>
        <div className="text-[#606060] text-sm">
          Get daily news on upcoming offers from many suppliers all over the
          world
        </div>
      </div>
      <div className="flex m-auto space-x-2">
        <div className="h-8 w-64 flex items-center text-[#606060] space-x-1 border-2 bg-white flex px-1 rounded-md">
          <EmailIcon />
          <input
            type="email"
            className=" border-none outline-none w-60"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <button
          className="bg-[#00B5DA] text-white h-8 py-1 px-4 rounded-md"
          onClick={() => {
            subscribe();
          }}
        >
          Subscribe
        </button>
      </div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default SubscribeOurNewsLetter;
