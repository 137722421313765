import React, { useEffect, useState } from "react";
import CategoryDisplay from "./CategoryDisplay";
import useScreenWidth from "./useScreenWidth";

const AllCategories = ({ categories }) => {
  const screenWidth = useScreenWidth();
  const [itemsPerRow, setItemsPerRow] = useState(2);

  useEffect(() => {
    if (screenWidth >= 1024) {
      setItemsPerRow(4);
    } else if (screenWidth < 1024 && screenWidth >= 640) {
      setItemsPerRow(3);
    } else if (screenWidth < 600) {
      setItemsPerRow(2);
    }
  }, [screenWidth]);

  return (
    <div className="space-y-2">
      {categories.length === 0 && (
        <div className="text-center text-2xl font-semibold">
          No Categories found
        </div>
      )}
      {categories.length > 0 && (
        <>
          <h1 className="text-2xl font-semibold text-left ">All Categories</h1>
          <CategoryDisplay categories={categories} itemsPerRow={itemsPerRow} />
        </>
      )}
    </div>
  );
};

export default AllCategories;
