import { useEffect, useState } from "react";
import DealsAndOffers from "../components/customer-dashboard/deals-and-offers/DealsAndOffers";
import ExploreCategories from "../components/customer-dashboard/explore-categories/ExploreCategories";
import Footer from "../components/customer-dashboard/footer/Footer";
import RecommendedItems from "../components/customer-dashboard/recommended-items/RecommendedItems";
import { SlideShow } from "../components/customer-dashboard/slide-show/SlideShow";
import SubscribeOurNewsLetter from "../components/customer-dashboard/subscribe-newsletter/SubscribeOurNewsLetter";
import CustomerNavbar from "../components/navbar/customer-navbar/CustomerNavbar";
import url from "../Config/url";
import Loader from "../components/Loader/Loader";

const CustomerDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const getProducts = async () => {
    const response = await fetch(`${url}/api/product/getProducts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    const data = await response.json();
    if (data.status) {
      setProducts(data.data);
      const response1 = await fetch(`${url}/api/category/getAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      });
      const data1 = await response1.json();
      setLoading(false);
      if (data1.status) {
        let arr = [];
        for (let i = 0; i <= 7; i++) {
          arr.push(data1.data[i]);
        }
        setCategories(arr);
      } else {
        console.log(data1.message);
      }
    } else {
      setLoading(false);
      console.log(data.message);
    }
  };
  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div className="">
      <div>
        <CustomerNavbar />
      </div>
      <div className="flex h-[170px] md:h-[500px]">
        <SlideShow />
      </div>
      <div className=" mx-3 md:mx-20 space-y-12 my-4 md:my-10">
        {loading ? (
          <Loader width={100} height={100} />
        ) : (
          <>
            {/* <DealsAndOffers /> */}
            <ExploreCategories categories={categories} />
            <RecommendedItems products={products} />
          </>
        )}
      </div>
      <SubscribeOurNewsLetter />
      <Footer />
    </div>
  );
};

export default CustomerDashboard;
