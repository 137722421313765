import url from "../../../Config/url";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";
import { PayPalButton } from "react-paypal-button-v2";

const CheckoutForm = ({
  amount,
  orderid,
  setLoading,
  order,
  navigate,
  location,
}) => {
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  return (
    <>
      <PayPalButton
        amount={amount}
        currency="USD"
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        onSuccess={async (details, data1) => {
          try {
            setLoading(true);
            let newObj = {
              ...order,
              paymentMethod: "Card",
              status: "Processing",
            };
            const res = await fetch(`${url}/api/order/add`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                //"Access-Control-Allow-Origin": "*",
              },
              body: JSON.stringify(newObj),
            });
            const data = await res.json();
            if (data.status) {
              setLoading(false);
              localStorage.removeItem("cart");
              navigate(location.pathname, { replace: true });
              window.location.href = "/orders";
            }
          } catch (error) {
            setLoading(false);
            HandleErrorAndSuccessMessage({
              type: "error",
              message:
                "Something went wrong. Contact support team if payment is deducted",
            });
            console.log(error);
          }
        }}
        options={{
          clientId:
            "AUifAkFctTOlvCpBDdWgXl8MPErQTAQEx95uTRkbu8JsGxOSNJZvmENiBJKqlpXK4zlR8ASYBzlA-r2O",
        }}
        onError={(err) => {
          setLoading(false);
          HandleErrorAndSuccessMessage({
            type: "error",
            message: "Something went wrong with payment",
          });
          console.log(err);
        }}
        onCancel={(data2) => {
          setLoading(false);
          HandleErrorAndSuccessMessage({
            type: "error",
            message: "Payment cancelled",
          });
          console.log(data2);
        }}
      />
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </>
  );
};

export default CheckoutForm;
