import { MessageIcon, NotificationIcon, SearchIcon } from "../icons/icons";
import React, { useState, useEffect } from "react";

const Navbar = () => {
  const [dateTime, setDateTime] = useState(new Date());
  const [name, setName] = useState(
    localStorage.getItem("admin")
      ? JSON.parse(localStorage.getItem("admin")).name
      : ""
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (date) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
    };

    return date.toLocaleTimeString(undefined, options);
  };

  return (
    <div className="flex flex-row  bg-white h-full">
      <div className="flex flex-col justify-center mx-8">
        <div className="text-base md:text-xl font-medium md:font-bold">
          Welcome, {name}
        </div>
        <div className="text-xs md:text-sm font-normal md:font-medium text-[#7C7C7C]">
          {formatDate(dateTime)} | {formatTime(dateTime)}
        </div>
      </div>
      {/* <div className="flex flex-row float-right m-auto mr-2 space-x-6">
        <div className="flex items-center hover:cursor-pointer">
          {" "}
          <MessageIcon />{" "}
        </div>
        <div className="flex items-center hover:cursor-pointer">
          {" "}
          <NotificationIcon />{" "}
        </div>
        <div className="border-l-2 my-1"></div>
        <div className="flex items-center border border-[#D1D1D1]  rounded relative border h-9">
          <label htmlFor="search" className="flex mx-2 hover:cursor-pointer">
            <SearchIcon className="" />{" "}
          </label>
          <input
            name="search"
            id="search"
            className=" outline-none"
            placeholder="Search"
          />
        </div>
      </div> */}
    </div>
  );
};

export default Navbar;
