import React, { useState, useRef } from "react";
import { storage } from "../../../Config/db";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const ProductPic = ({ images, setImages }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleImageUpload = () => {
    if (!selectedImage || isUploading) return;

    setIsUploading(true);
    const storageRef = ref(storage, `products/${selectedImage.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedImage);

    uploadTask.on("state_changed", null, null, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        setImages([...images, downloadURL]);
        setSelectedImage(null);
        setIsUploading(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      });
    });
  };

  const handleDeleteImage = (index) => {
    const deletedLink = images[index];

    // Delete the image from Firebase Storage
    const storageRef = ref(storage, deletedLink);
    deleteObject(storageRef)
      .then(() => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
      })
      .catch((error) => {
        console.error("Error deleting image:", error);
      });
  };

  return (
    <div className="py-4">
      <input
        type="file"
        onChange={handleImageChange}
        accept="image/*"
        className="mb-2"
        ref={fileInputRef}
      />
      <button
        onClick={handleImageUpload}
        className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
          isUploading ? "cursor-not-allowed opacity-50" : "" // Disable button when uploading
        }`}
        disabled={isUploading} // Disable the button when uploading
      >
        {isUploading ? "Uploading..." : "Upload Image"}
      </button>
      <div className="flex flex-wrap -mx-2 mt-4">
        {images.map((link, index) => (
          <div
            key={index}
            className="w-[100px] h-[80px] px-2 mb-4 flex flex-col item-center"
          >
            <img
              src={link}
              alt={`Uploaded ${index}`}
              className="w-[100]% h-[100%]"
            />
            <button
              onClick={() => handleDeleteImage(index)}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded mt-2"
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductPic;
