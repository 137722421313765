import { useEffect, useState } from "react";
import { CloseIcon } from "../../icons/icons";
import url from "../../../Config/url";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";

const OrderDetails = ({ setOrderDetailVisible, obj, getOrders }) => {
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();
  const updateFunc = async (status) => {
    try {
      fetch(`${url}/api/order/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          id: obj._id,
          status: status,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            getOrders();
            setOrderDetailVisible(false);
          } else {
            HandleErrorAndSuccessMessage({
              type: "error",
              message: "Something went wrong",
            });
            console.log(data.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };
  return (
    <div className=" w-full px-8 py-4 h-full space-y-7 ">
      <div className="flex text-left relative font-medium text-lg text-[#5D6881]">
        <label htmlFor="" className="">
          Order #{obj._id}
        </label>
        <div
          className="absolute right-0 text-black hover:cursor-pointer"
          onClick={() => {
            setOrderDetailVisible(false);
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className=" space-y-1">
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Customer name:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.name}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Phone:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.contact}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Email:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.customer}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Address:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.address}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Total Price:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.products.reduce((acc, item) => acc + item.price * item.qty, 0)}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Status:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.status}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Payment Method:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.paymentMethod}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Date:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {new Date(obj.date).toLocaleDateString()}
          </div>
        </div>
        <div className="flex flex-col space-x-12">
          <div className=" text-[#5D6881] w-40 mx-20 text-left font-medium">
            Products:
          </div>
          <div className="overflow-y-scroll no-scrollbar max-h-[25vh]">
            <table id="table" className="inline-table w-11/12 m-2">
              <thead className="sticky top-0">
                <tr className="border-b  border-[#D1D1D1]">
                  <td className=" text-sm py-1.5 text-[#7C7C7C]">No</td>
                  <td className=" text-sm py-1.5 text-[#7C7C7C]">Name</td>
                  <td className=" text-sm py-1.5 text-[#7C7C7C]">Price</td>
                  <td className=" text-sm py-1.5 text-[#7C7C7C]">Quantity</td>
                  <td className=" text-sm py-1.5 text-[#7C7C7C]">Store</td>
                  <td className=" text-sm py-1.5 text-[#7C7C7C]">Variant</td>
                </tr>
              </thead>
              <tbody>
                {obj.products.map((item, index) => {
                  return (
                    <tr key={item._id} className="border-b  border-[#D1D1D1]">
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {index + 1}
                      </td>
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {item.name}
                      </td>
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {item.price}
                      </td>
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {item.qty}
                      </td>
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {item.store}
                      </td>
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {item.variant}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-6 items-center justify-center">
          {obj.status === "Processing" && (
            <>
              <button
                className="bg-[#D92D20] text-white py-2 rounded-md w-24"
                onClick={() => {
                  updateFunc("Cancelled");
                }}
              >
                Cancel
              </button>
              <button
                className="bg-[#F2C94C] text-white py-2 rounded-md w-24"
                onClick={() => {
                  updateFunc("Approved");
                }}
              >
                Approve
              </button>
            </>
          )}
          {obj.status === "Approved" && (
            <>
              <button
                className="bg-[#7F56D9] text-white py-2 rounded-md w-24"
                onClick={() => {
                  updateFunc("Dispatched");
                }}
              >
                Dispatch
              </button>
            </>
          )}
          {obj.status === "Dispatched" && (
            <>
              <button
                className="bg-[#039855] text-white py-2 rounded-md w-24"
                onClick={() => {
                  updateFunc("Delivered");
                }}
              >
                Delivered
              </button>
            </>
          )}
        </div>
      </div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default OrderDetails;
