const AccountDetails = ({
  reason,
  setReason,
  iban,
  setIban,
  accountHolder,
  setAccountHolder,
  bankName,
  setBankName,
  address,
  setAddress,
}) => {
  return (
    <div className="p-8  bg-white space-y-2 border border-[#E0E0E0] rounded-lg">
      <div className="flex ">
        <label className="flex flex-col w-1/2 text-left mx-1" htmlFor="">
          {" "}
          Account Holder Name{" "}
          <input
            className="h-8 border rounded-md my-1 px-2"
            type="text"
            placeholder="Account Holder Name"
            value={accountHolder}
            onChange={(e) => {
              setAccountHolder(e.target.value);
            }}
          />
        </label>
        <label className="flex flex-col w-1/2 text-left mx-1" htmlFor="">
          {" "}
          IBAN{" "}
          <input
            className="h-8 border rounded-md my-1 px-2"
            type="text"
            placeholder="IBAN"
            value={iban}
            onChange={(e) => {
              setIban(e.target.value);
            }}
          />
        </label>
      </div>
      <div className="flex ">
        <label className="flex flex-col w-1/2 text-left mx-1" htmlFor="">
          {" "}
          Bank Name{" "}
          <input
            className="h-8 border rounded-md my-1 px-2"
            type="text"
            placeholder="Bank Name"
            value={bankName}
            onChange={(e) => {
              setBankName(e.target.value);
            }}
          />
        </label>
        <label className="flex flex-col w-1/2 text-left mx-1" htmlFor="">
          {" "}
          Address{" "}
          <input
            className="h-8 border rounded-md my-1 px-2"
            type="text"
            placeholder="Address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </label>
      </div>
      <div className="">
        <label className="flex flex-col  text-left mx-1" htmlFor="">
          {" "}
          Reason{" "}
          <input
            className="h-8 border rounded-md my-1 px-2"
            type="text"
            placeholder="Reason"
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
            }}
          />
        </label>
      </div>
    </div>
  );
};

export default AccountDetails;
