import React, { useState, useEffect } from "react";
import cn from "classnames";
import { DropImageIcon } from "../../icons/icons";
import validator from "validator";
import ProductPic from "./ProductPictures";
import url from "../../../Config/url";

const ProductBasicInfo = ({ setProductInfo, product, setProduct, store }) => {
  const obj = ["Basic Info", "Description", "Stocks & Price"];
  const [productName, setProductName] = useState(
    product.hasOwnProperty("_id") ? product.name : ""
  );
  const [productCategory, setProductCategory] = useState(
    product.hasOwnProperty("_id") ? product.category : ""
  );

  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState(
    product.hasOwnProperty("_id") ? product.images : []
  );
  const [error, setError] = useState("");

  const getCategories = async () => {
    const response = await fetch(`${url}/api/category/getAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    const data = await response.json();
    if (data.status) {
      setCategories(data.data);
      if (Object.keys(product).length !== 0) {
        setProductCategory(product.category);
      } else {
        setProductCategory(data.data[0].name);
      }
    } else {
      console.log(data.message);
    }
  };

  useEffect(() => {
    setProductInfo("product-basic-info");
    getCategories();
  }, []);

  const handleBasicNext = () => {
    if (productName === "" || productCategory === "") {
      setError("Please write all Details");
    } else if (images.length === 0) {
      setError("Please Upload at least one image");
    } else {
      setError("");
      if (product.hasOwnProperty("_id")) {
        let obj = product;
        delete obj.name;
        delete obj.category;
        delete obj.store;
        delete obj.images;
        obj.name = productName;
        obj.category = productCategory;
        obj.store = store;
        obj.images = images;
        setProduct(obj);
      } else {
        let obj = {
          name: productName,
          store: store,
          category: productCategory,
          images: images,
        };

        setProduct(obj);
      }
      setProductInfo("product-description");
    }
  };

  return (
    <div className=" mx-2 md:mx-44 space-y-4 md:space-y-8 ">
      <div className="flex w-full justify-between space-x-2 md:space-x-16 flex-grow">
        {obj.map((item, index) => (
          <div className="flex  items-center justify-between  flex-grow w-fit ">
            <div className="flex space-x-1">
              <div
                className={cn(
                  ` flex items-center font-semibold md:font-bold px-[5px] md:px-2 text-[8px] md:text-xs py-[2px] md:py-1 rounded-full `,
                  {
                    "bg-[#00B5DA] text-white ":
                      obj.indexOf("Basic Info") === index,
                    "bg-[#E1E1E6] text-[#8D8D99]":
                      obj.indexOf("Basic Info") !== index,
                  }
                )}
              >
                {index + 1}
              </div>
              <div
                className={cn("font-normal md:font-bold text-xs md:text-base", {
                  "text-[#323238] ": obj.indexOf("Basic Info") === index,
                  "text-[#8D8D99]": obj.indexOf("Basic Info") !== index,
                })}
              >
                {item}
              </div>
            </div>
            {index + 1 !== obj.length && (
              <div
                className="w-2 md:w-4 font-bold text-xs md:text-base text-center"
                style={{ flexShrink: 0 }}
              >
                {">"}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="bg-white border border-[#D0D5DD] rounded-lg px-6 md:px-12 py-6 space-y-6 ">
        <div className="space-y-2">
          <div className="flex flex-col text-[#344054] text-left space-y-1">
            <label htmlFor="" className="text-xs md:text-sm ">
              Product Pictures
            </label>
            <ProductPic images={images} setImages={setImages} />
          </div>
          <div className="flex flex-col text-[#344054] text-left space-y-1">
            <label htmlFor="" className="text-xs md:text-sm ">
              Product Name
            </label>
            <input
              type="text"
              placeholder="Product Name"
              className="py-2 px-3 border border-[#D0D5DD] rounded-lg"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>
          <div className="flex flex-col text-[#344054] text-left space-y-1">
            <label htmlFor="" className="text-xs md:text-sm ">
              Category
            </label>
            <select
              className="py-2 px-3 border border-[#D0D5DD] rounded-lg"
              value={productCategory}
              onChange={(e) => setProductCategory(e.target.value)}
            >
              {categories.map((item) => (
                <option key={item._id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          {error && (
            <div className="text-left text-xs md:text-sm  text-red-500">
              {error}
            </div>
          )}
          <div className="flex justify-end">
            <button
              className="bg-[#00B5DA]  text-white p-1 px-4 mt-6 rounded-lg   flex items-center justify-center text-xs md:text-sm "
              onClick={() => {
                handleBasicNext();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductBasicInfo;
