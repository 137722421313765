import { useEffect, useState } from "react";
import {
  TruckIcon,
  ShopingBagIcon,
  EditIcon,
  FilterIcon,
  ShoppingTrollyIcon,
  IncreaseArrowIcon,
} from "../icons/icons";
import url from "../../Config/url";
import setting from "../../Config/settings.json";
import Loader from "../Loader/Loader";
import { useErrorAndSuccessMessageStore } from "../../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "./ErrorAndSuccessMessage";

const SellerFinance = ({ store, seller }) => {
  const [tableRowsData, setTableRowsData] = useState([]);
  const [commissionRate, setCommissionRate] = useState(setting.commission);
  const [monthlySale, setMonthlySales] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [request, setRequest] = useState([]);
  const [loading, setLoading] = useState(true);
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const getOrders = async () => {
    try {
      setLoading(true);
      let date = new Date();
      let start = new Date(date.getFullYear(), date.getMonth(), 1);
      let end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let arr = [];
      const res = await fetch(
        `${url}/api/order/getOrdersForSeller?store=${store.name}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            //"Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await res.json();
      setLoading(false);
      if (!data.status) throw new Error(data.message);
      else {
        arr = data.data;

        arr.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA; // To sort in descending order (newest date first)
        });

        const monthlySales = arr.reduce((total, order) => {
          const orderDate = new Date(order.date);
          if (orderDate >= start && orderDate <= end) {
            const orderTotal = order.price * order.qty;
            return total + orderTotal;
          }
          return total;
        }, 0);

        const totalSales = arr.reduce((total, order) => {
          const orderTotal = order.price * order.qty;
          return total + orderTotal;
        }, 0);

        setTotalSale(totalSales);
        setMonthlySales(monthlySales);

        setTableRowsData(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getRequest = async () => {
    try {
      const res = await fetch(
        `${url}/api/fund/getFundsBySeller?seller=${seller._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            //"Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await res.json();
      if (data.status) {
        let arr = data.data;
        arr.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA; // To sort in descending order (newest date first)
        });

        setRequest(arr);
      } else {
        console.log(data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addRequest = async (amount) => {
    try {
      const res = await fetch(`${url}/api/fund/addFund`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          seller: seller._id,
          requestAmount: amount,
        }),
      });
      const data = await res.json();
      if (data.status) {
        HandleErrorAndSuccessMessage({
          type: "success",
          message: "Requested Successfully",
        });
        getRequest();
        getOrders();
      } else {
        if (data.message === "Fund is already requested") {
          HandleErrorAndSuccessMessage({
            type: "error",
            message: "Fund is already requested",
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.location.hash = "finance";
    getOrders();
    getRequest();
  }, []);

  return (
    <div className="space-y-3 p-2 w-full h-full">
      {loading && (
        <div className="w-full h-full flex justify-center items-center">
          <Loader width={100} height={100} />
        </div>
      )}
      {!loading && (
        <>
          <div className=" h-[40%] flex flex-col space-y-2 justify-between">
            <div className={`space-x-10 w-full h-1/2 flex  `}>
              <div className=" min-w-[490px] w-3/5 flex flex-col justify-center space-y-2 ">
                <div className="flex h-full space-x-4 ">
                  <div className="w-full rounded-2xl min-w-[150px] text-white bg-gradient-to-r from-blue-400  to-blue-800 ">
                    <div className="text-left mx-3 mt-1 font-bold text-lg">
                      Total Earning
                    </div>
                    <div className="float-right mx-6 text-4xl font-semibold">
                      {totalSale}
                    </div>
                  </div>
                  <div className="w-full rounded-2xl min-w-[150px] text-white bg-gradient-to-r from-fuchsia-400  to-fuchsia-700 ">
                    <div className="text-left mx-3 mt-1 font-bold text-lg">
                      Paid Amount
                    </div>
                    <div className="float-right mx-6 text-4xl font-semibold">
                      {store.paidAmount}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`space-x-10 w-full  h-1/2 flex `}>
              <div className="flex flex-col justify-center text-left py-2 px-5  rounded-2xl  bg-white w-1/3">
                <div className="text-left text-sm text-[#A1A0BD] font-bold ">
                  {new Date().toLocaleString("default", { month: "long" })}{" "}
                  Sales
                </div>
                <div className="text-2xl font-bold">{monthlySale}</div>
              </div>
              <div className="flex flex-col justify-center text-left py-2 px-5  rounded-2xl  bg-white w-1/3">
                <div className="text-left text-sm text-[#A1A0BD] font-bold ">
                  Pending Funds at {commissionRate}% commission
                </div>
                <div className="text-2xl font-bold">
                  {totalSale -
                    totalSale * (commissionRate / 100) -
                    store.paidAmount}
                </div>
              </div>
              <div className="flex justify-left items-center w-1/3">
                <button
                  className="bg-[#00b5da] text-white px-4 py-2 rounded-lg"
                  onClick={() => {
                    let amount =
                      totalSale -
                      totalSale * (commissionRate / 100) -
                      store.paidAmount;
                    if (amount > 0) {
                      addRequest(amount);
                    } else {
                      HandleErrorAndSuccessMessage({
                        type: "error",
                        message: "No Pending Funds",
                      });
                    }
                  }}
                >
                  Request Fund
                </button>
              </div>
            </div>
          </div>
          {request.length > 0 && (
            <div className=" rounded-2xl px-2 bg-white h-[285px]">
              <div className="space-y-2 flex justify-between ">
                <div>
                  <div className="text-[#3D4465] flex space-x-3 font-medium text-lg text-left">
                    <span>Requests</span>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="overflow-y-scroll no-scrollbar p-2 max-h-[100vh] sm:max-h-[90vh]  md:max-h-[80vh] lg:max-h-[60vh]  xl:max-h-[52vh] ">
                  <table id="table" className="inline-table w-full">
                    <tbody className="">
                      <tr className="border-b  border-[#D1D1D1]">
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">No</td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">Date</td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          Requested
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">Paid</td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          Status
                        </td>
                      </tr>
                      {request.map((row, index) => (
                        <tr key={row._id}>
                          <td className=" text-sm py-1.5 text-[#7C7C7C]">
                            {index + 1}
                          </td>
                          <td className=" text-sm py-1.5 text-[#7C7C7C]">
                            {new Date(row.date).toLocaleDateString()}
                          </td>
                          <td className=" text-sm py-1.5 text-[#7C7C7C]">
                            {row.requestAmount}
                          </td>
                          <td className=" text-sm py-1.5 text-[#7C7C7C]">
                            {row.paidAmount}
                          </td>
                          <td
                            className="text-sm text-white p-1 rounded-md w-1"
                            style={{
                              backgroundColor:
                                row.status == "Pending"
                                  ? "orange"
                                  : row.status == "Approved"
                                  ? "green"
                                  : row.status == "Rejected"
                                  ? "red"
                                  : "white",
                            }}
                          >
                            {row.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {request.length == 0 && (
            <div className=" flex items-center justify-center w-full h-[200px]">
              <h3>No Request Found</h3>
            </div>
          )}
        </>
      )}
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default SellerFinance;
