import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import { useState, useEffect } from "react";
import Login from "./pages/Login";
import AdminDashboard from "./pages/Admin-Dashboard";
import CustomerDashboard from "./pages/Customer-Dashboard";
import ProductDetail from "./pages/ProductDetails";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Payment from "./pages/Payment";
import Orders from "./pages/Orders";
import UserLogin from "./pages/UserLogin";
import ForgetPassword from "./components/customer-dashboard/user-login/ForgetPassword";
import ChangePassword from "./components/customer-dashboard/user-login/ChangePassword";
import PasswordChangeSuccessfull from "./components/customer-dashboard/user-login/PasswordChangeSuccessfull";
import UserSignup from "./components/customer-dashboard/user-login/UserSignUp";
import SellerBasicInfo from "./components/seller-dashboard/seller-signup/BasicInfo";
import SellerSignup from "./pages/SellerSignup";
import AddingProduct from "./pages/AddingProduct";
import Categories from "./pages/Categories";
import CategoryAllProducts from "./components/customer-dashboard/category-products/CategoryAllProducts";
import AllProducts from "./pages/AllProducts";
import SellerDashboard from "./pages/SellerDashboard";
import ErrorAndSuccessMessage from "./components/seller-dashboard/ErrorAndSuccessMessage";
import NoInternetConnection from "./NoInternetConnection";
import Contact from "./components/customer-dashboard/Contact";
import Refund from "./pages/Refund";

function App() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    // Add event listeners to monitor online/offline status changes
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };
  return (
    <div className="App max-w-[1840px]  mx-auto">
      {isOnline ? (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<CustomerDashboard />} />
            <Route path="/admin" element={<Login />} />
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/product/:id" element={<ProductDetail />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/products" element={<AllProducts />} />
            <Route path="/user/login" element={<UserLogin />} />
            <Route path="/user/forgot-password" element={<ForgetPassword />} />
            <Route path="/user/change-password" element={<ChangePassword />} />
            <Route
              path="/user/change-password-successfull"
              element={<PasswordChangeSuccessfull />}
            />
            <Route path="/user/signup" element={<UserSignup />} />
            <Route path="/seller/signup" element={<SellerSignup />} />
            <Route path="/seller/adding-product" element={<AddingProduct />} />
            <Route path="/seller/dashboard" element={<SellerDashboard />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <NoInternetConnection />
      )}
    </div>
  );
}

export default App;
