import { useEffect, useState } from "react";
import { CloseIcon, DropImageIcon } from "../../icons/icons";
import url from "../../../Config/url";
import { storage } from "../../../Config/db";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";

const AddStore = ({ setAddStoreVisible, getStores }) => {
  const [storeName, setStoreName] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [storeIcon, setStoreIcon] = useState(null);
  const [error, setError] = useState("");
  const [emails, setEmails] = useState([]);
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const getSellers = async () => {
    const res = await fetch(`${url}/api/user/getUserByRole`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ role: "seller" }),
    });
    const data = await res.json();
    if (data.status) {
      let count = 1;
      for (let i = 0; i < data.data.length; i++) {
        if (count === 1) {
          setOwnerEmail(data.data[i].email);
        }
        count++;
      }
      setEmails(data.data);
    } else {
      console.log(data.message);
    }
  };

  const handleSubmit = () => {
    if (
      (storeName === "",
      ownerEmail === "",
      storeAddress === "",
      storeIcon === null)
    ) {
      setError("Please fill all fields");
      return 0;
    } else {
      setError("");
      let data = {
        name: storeName,
        store_holder: ownerEmail,
        address: storeAddress,
        isActive: true,
        status: "Approved",
        account: "",
      };
      const storageRef = ref(storage, `store/${storeIcon.name}`);
      const uploadTask = uploadBytesResumable(storageRef, storeIcon);
      uploadTask.on(
        "state_changed",
        () => {},
        (err) => {
          console.log(err);
        },
        async () => {
          let url1 = await getDownloadURL(uploadTask.snapshot.ref);
          data.image = url1;
          const res = await fetch(`${url}/api/store/add`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              //"Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(data),
          });
          const resData = await res.json();
          if (resData.status) {
            HandleErrorAndSuccessMessage({
              type: "success",
              message: "Store added successfully",
            });
            setOwnerEmail("");
            setStoreName("");
            setStoreAddress("");
            setStoreIcon(null);
            getStores();
            setAddStoreVisible(false);
          } else {
            console.log(resData.message);
          }
        }
      );
    }
  };

  useEffect(() => {
    getSellers();
  }, []);

  return (
    <div className=" w-full px-8 py-4 h-ful space-y-3">
      <div className="flex text-xl relative text-[#5D6881] text-left font-medium">
        <label htmlFor="" className="">
          Add Store
        </label>
        <div
          className="absolute right-0 text-black hover:cursor-pointer"
          onClick={() => {
            setAddStoreVisible(false);
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="storeName" className="text-left ">
            Store Name
          </label>
          <input
            type="text"
            className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            placeholder="Olivia Store"
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="ownerEmail" className="text-left ">
            Owner Email
          </label>
          <select
            className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            value={ownerEmail}
            onChange={(e) => setOwnerEmail(e.target.value)}
          >
            {emails.map((email) => (
              <option value={email.email}>{email.email}</option>
            ))}
          </select>
        </div>
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="shopAddress" className="text-left ">
            Shop Address
          </label>
          <input
            type="text"
            className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            placeholder="Blue Area, Islamabad"
            value={storeAddress}
            onChange={(e) => setStoreAddress(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="storeIcon" className="text-left ">
            Store Icon
          </label>
          <label
            htmlFor="storeIconInput"
            className=" mx-2 bg-[#F6F6F6] border-dashed h-7 px-2 border border-[#D0D5DD] rounded-md flex justify-center p-0.5 space-x-4 hover:cursor-pointer"
          >
            <DropImageIcon className="text-[#373737] p-0.5 rounded-md bg-[#EBEBEB]" />
            <span className="text-gray-600">
              {storeIcon ? storeIcon.name : "Upload Store Icon"}
            </span>
          </label>
          <input
            type="file"
            id="storeIconInput"
            className="hidden h-1 mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            placeholder="Olivia Store"
            onChange={(e) => setStoreIcon(e.target.files[0])}
          />
        </div>
        {error && <div className="text-left text-[red]">{error}</div>}
      </div>
      <button
        className="bg-[#00B5DA] rounded-lg px-5 py-2 text-white"
        onClick={() => {
          handleSubmit();
        }}
      >
        Add Store
      </button>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default AddStore;
