import { useEffect, useState } from "react";
import {
  TruckIcon,
  ShopingBagIcon,
  EditIcon,
  FilterIcon,
} from "../icons/icons";
import CategoriesPieChart from "./categories/CategoriesPieChart";
import AddCategories from "./categories/AddCategories";
import AddParentCategory from "./categories/AddParentCategory";
import url from "../../Config/url";
import Loader from "../Loader/Loader";

const Categories = () => {
  const [tableRowsData, setTableRowsData] = useState([]);
  const [pieTableRowData, setPieTableRowData] = useState([]);
  const [isAddCategoryVisible, setIsAddCategoryVisible] = useState(false);
  const [isAddParentCategoryVisible, setIsAddParentCategoryVisible] =
    useState(false);
  const [parentCategoryCount, setParentCategoryCount] = useState(0);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const getAllCategories = async () => {
    setLoading(true);
    let res = await fetch(`${url}/api/category/getAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    let result = await res.json();
    setLoading(false);
    if (result.status) {
      setTableRowsData(result.data);
    } else {
      console.log(result.message);
    }
  };

  const deleteCategory = async (id) => {
    let res = await fetch(`${url}/api/category/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ id: id }),
    });
    let result = await res.json();
    if (result.status) {
      getAllCategories();
    } else {
      console.log(result.message);
    }
  };

  const getAllParentCategories = async () => {
    let res = await fetch(`${url}/api/parentCategory/getAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    let result = await res.json();
    if (result.status) {
      setParentCategoryCount(result.data.length);
    } else {
      console.log(result.message);
    }
  };

  const getAllCategoriesPieChart = async () => {
    let res = await fetch(`${url}/api/category/getAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    let result = await res.json();
    if (result.status) {
      let data = [];
      for (let i = 0; i < result.data.length; i++) {
        let obj = {};
        let category = result.data[i].name;
        let res1 = await fetch(
          `${url}/api/product/getProductsByFilter?category=${category}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              //"Access-Control-Allow-Origin": "*",
            },
          }
        );
        let result1 = await res1.json();
        if (result1.status) {
          obj.category = category;
          obj.products = result1.data.length;
          data.push(obj);
        }
      }
      setPieTableRowData(data);
    } else {
      console.log(result.message);
    }
  };

  useEffect(() => {
    window.location.hash = "categories";
    getAllCategories();
    getAllParentCategories();
    getAllCategoriesPieChart();
  }, []);

  return (
    <div className="space-y-3 p-2 w-full h-full relative">
      {loading && (
        <div className="w-full h-full flex justify-center items-center">
          <Loader width={100} height={100} />
        </div>
      )}
      {!loading && (
        <>
          <div
            className={`space-x-10 h-[40%] flex ${
              (isAddCategoryVisible || isAddParentCategoryVisible) && "blur-sm"
            }`}
          >
            <div className=" w-2/4 flex flex-col justify-center space-y-2 ">
              <div className="flex items-center h-full">
                <div className="w-full h-[48%] rounded-2xl text-white bg-gradient-to-r from-blue-300  to-blue-700 ">
                  <div className="text-left mx-3 mt-1 font-bold text-lg">
                    Parent
                  </div>
                  <div className="absolute flex w-fit ">
                    <TruckIcon />
                  </div>
                  <div className="float-right mx-6 text-5xl font-semibold">
                    {parentCategoryCount}
                  </div>
                </div>
                <div className="w-full h-[48%] rounded-2xl mx-2 text-white bg-gradient-to-r from-fuchsia-300  to-fuchsia-600 ">
                  <div className="text-left mx-3 mt-1 font-bold text-lg">
                    Categories
                  </div>
                  <div className="absolute flex w-fit ">
                    <ShopingBagIcon />
                  </div>
                  <div className="float-right mx-6 text-5xl font-semibold">
                    {tableRowsData.length}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white flex border rounded-lg border-[#D1D1D1] w-1/2">
              <div className="w-1/2 py-4 pl-10">
                <CategoriesPieChart />
              </div>
              <div className="w-1/2 items-center m-6  justify-center flex">
                <tbody className="text-left">
                  <tr className="">
                    <td className=" text-sm font-semibold pb-2 border-b-2 border-[#5D6881] pr-2  text-[#7C7C7C]">
                      Category
                    </td>
                    <td className=" text-sm font-semibold pb-2 border-b-2 border-[#5D6881] pr-2 text-[#7C7C7C]">
                      Products
                    </td>
                  </tr>
                  {pieTableRowData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className=" text-sm pr-2   text-[#7C7C7C] text-left">
                          {item.category}
                        </td>
                        <td className=" text-sm pr-2   text-[#7C7C7C] text-center">
                          {item.products}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </div>
            </div>
            <div className="m-auto space-y-4 w-1/5">
              <button
                className="py-2 text-sm text-[white] font-semibold px-4 w-44 rounded-lg bg-[#00B5DA]"
                onClick={() => {
                  setIsAddParentCategoryVisible(true);
                  setIsAddCategoryVisible(false);
                  getAllCategories();
                  getAllParentCategories();
                }}
              >
                Add Parent Category
              </button>
              <button
                className="py-2 text-sm text-[white] font-semibold px-4 w-44 rounded-lg bg-[#00B5DA]"
                onClick={() => {
                  setIsAddCategoryVisible(true);
                  setIsAddParentCategoryVisible(false);
                  getAllCategories();
                  getAllParentCategories();
                }}
              >
                Add Category
              </button>
            </div>
          </div>
          <div
            className={`h-[57%] space-y-2 p-4 border bg-white border-[#D1D1D1] rounded-lg ${
              (isAddCategoryVisible || isAddParentCategoryVisible) && "blur-sm"
            }`}
          >
            <div className="flex relative  items-center ">
              <h1 className="text-sm sm:text-lg text-[#5D6881] font-semibold ">
                Categories{" "}
                <span className="text-[#BDBDBD]">({tableRowsData.length})</span>
              </h1>
              {/* <div className="flex w-32 h-8 sm:h-auto sm:w-auto absolute right-0 hover:cursor-pointer   ">
            <label
              htmlFor="filter"
              className="absolute right-3 text-[#7C7C7C] hover:cursor-pointer"
            >
              <FilterIcon />
            </label>
            <select
              id="filter"
              // ref={selectRef}
              // onChange={(e) => setFilter(e.target.value)}
              className="bg-[#F2F7FF] rounded-lg w-24 sm:w-auto  hover:cursor-pointer p-1 appearance-none"
            >
              <option value="highToLow">Filter</option>
              <option value="highToLow">High to Low</option>
              <option value="lowToHigh">Low to High</option>
              <option value="aToZ">A to Z</option>
              <option value="zToA">Z to A</option>
            </select>
          </div> */}
            </div>

            <div className="overflow-y-scroll no-scrollbar p-2 max-h-[100vh] sm:max-h-[65vh]  md:max-h-[55vh] lg:max-h-[40vh]  ">
              <table id="table" className="inline-table w-full">
                <tbody className="">
                  <tr className="border-b  border-[#D1D1D1]">
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">No</td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">Category</td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">
                      Parent Category
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]"></td>
                  </tr>
                  {tableRowsData.map((row, index) => (
                    <tr key={row._id} className="border-b  border-[#D1D1D1]">
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {index + 1}
                      </td>
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {row.name}
                      </td>
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {row.parent_category}
                      </td>
                      <td className=" text-sm py-1.5 text-[#7C7C7C] hover:cursor-pointer">
                        <button
                          className="bg-red-500 text-white rounded-md py-1 px-2"
                          onClick={() => {
                            deleteCategory(row._id);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className={
              !isAddCategoryVisible
                ? "hidden"
                : "bg-white rounded-lg absolute shadow-lg inset-y-20 inset-x-72 flex items-center justify-center"
            }
          >
            <AddCategories setIsAddCategoryVisible={setIsAddCategoryVisible} />
          </div>
          <div
            className={
              !isAddParentCategoryVisible
                ? "hidden"
                : "bg-white rounded-lg absolute shadow-lg inset-y-32 inset-x-72 flex items-center justify-center"
            }
          >
            <AddParentCategory
              setIsAddParentCategoryVisible={setIsAddParentCategoryVisible}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Categories;
