import React from "react";
import styles from "./loader.module.css";

const Loader = ({ width, height }) => {
  return (
    <div className={styles.loaderContainer}>
      <div
        className={styles.loader}
        style={{ width: `${width}px`, height: `${height}px` }}
      ></div>
    </div>
  );
};

export default Loader;
