import { useEffect, useState } from "react";
import {
  TruckIcon,
  ShopingBagIcon,
  EditIcon,
  FilterIcon,
  StarIcon,
} from "../icons/icons";
// import QueriesPieChart from "./queries/QueriesPieChart";
import cn from "classnames";
import MessageDetail from "./queries/MessageDetail";
import url from "../../Config/url";
import Loader from "../Loader/Loader";

const Queries = () => {
  const [tableRowsData, setTableRowsData] = useState([]);
  //   const [pieTableRowData, setPieTableRowData] = useState([""]);
  const [messageDetailVisible, setMessageDetailVisible] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [isSolved, setIsSolved] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const getAllMessages = async () => {
    setLoading(true);
    const response = await fetch(`${url}/api/feedback/getFeedbacks`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    const data = await response.json();
    setLoading(false);
    if (data.status) {
      let count = 0;
      for (let i = 0; i < data.feedbacks.length; i++) {
        if (!data.feedbacks[i].isSolved) {
          count++;
        }
      }
      setPendingCount(count);
      setTableRowsData(data.feedbacks);
    } else {
      console.log(data.message);
    }
  };

  const fetchData = async () => {
    getAllMessages();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.location.hash = "#queries";
    fetchData();
    // setPieTableRowData(
    //   pieTableData.map((item, index) => (
    //     <tr key={index}>
    //       <td className=" text-sm pr-2   text-[#7C7C7C]">{item.category}</td>
    //       <td className=" text-sm pr-2   text-[#7C7C7C]">{item.order}</td>
    //       <td className=" text-sm pr-2   text-[#7C7C7C]">
    //         {item.percentage} %
    //       </td>
    //     </tr>
    //   ))
    // );
  }, []);

  return (
    <div className="space-y-3 p-2 w-full relative h-full">
      {loading && (
        <div className="w-full h-full flex justify-center items-center">
          <Loader width={100} height={100} />
        </div>
      )}
      {!loading && (
        <>
          <div
            className={`mx-auto justify-center space-x-16 h-[20%] flex ${
              messageDetailVisible && "blur-sm"
            }`}
          >
            <div className=" w-2/4 flex flex-col justify-center space-y-2 ">
              <div className="flex h-full">
                <div className="w-full h-[98%] rounded-2xl text-white bg-gradient-to-r from-blue-400  to-blue-800 ">
                  <div className="text-left mx-3 mt-1 font-bold text-lg">
                    All Messages
                  </div>
                  <div className="absolute flex w-fit ">
                    <TruckIcon />
                  </div>
                  <div className="float-right mx-6 text-5xl font-semibold">
                    {tableRowsData.length}
                  </div>
                </div>
                <div className="w-full h-[98%] rounded-2xl mx-2 text-white bg-gradient-to-r from-fuchsia-400  to-fuchsia-800 ">
                  <div className="text-left mx-3 mt-1 font-bold text-lg">
                    Pending Messages
                  </div>
                  <div className="absolute flex w-fit ">
                    <ShopingBagIcon />
                  </div>
                  <div className="float-right mx-6 text-5xl font-semibold">
                    {pendingCount}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="bg-white flex justify-center items-center border rounded-lg border-[#D1D1D1] w-1/2">
          <div className="w-[70%] py-4 flex justify-center items-center">
            <QueriesPieChart />
          </div> */}
            {/* <div className="w-1/2 items-center m-6  justify-center flex">
            <tbody className="text-left">
              <tr className="">
                <td className=" text-sm font-semibold pb-2 border-b-2 border-[#5D6881] pr-2  text-[#7C7C7C]">
                  Category
                </td>
                <td className=" text-sm font-semibold pb-2 border-b-2 border-[#5D6881] pr-2 text-[#7C7C7C]">
                  Order
                </td>
                <td className=" text-sm text-center font-semibold pb-2 border-b-2 border-[#5D6881] pr-2 text-[#7C7C7C]">
                  %
                </td>
              </tr>
              {pieTableRowData}
            </tbody>
          </div> */}
            {/* </div> */}
          </div>
          <div
            className={`h-[77%] space-y-2 p-4 border bg-white border-[#D1D1D1] rounded-lg ${
              messageDetailVisible && "blur-sm"
            }`}
          >
            <div className="flex relative  items-center ">
              <h1 className="text-sm sm:text-lg text-[#5D6881] font-semibold ">
                Messages{" "}
              </h1>
              {/* <div className="flex w-32 h-8 sm:h-auto sm:w-auto absolute right-0 hover:cursor-pointer   ">
                        <label 
                        htmlFor="filter"
                        className="absolute right-3 text-[#7C7C7C] hover:cursor-pointer"
                        >
                            <FilterIcon /></label>
                    <select
                    id="filter"
                        // ref={selectRef}
                        // onChange={(e) => setFilter(e.target.value)}
                        className="bg-[#F2F7FF] rounded-lg w-24 sm:w-auto  hover:cursor-pointer p-1 appearance-none"
                        
                    >
                        <option value="highToLow">Filter</option>
                        <option value="highToLow">High to Low</option>
                        <option value="lowToHigh">Low to High</option>
                        <option value="aToZ">A to Z</option>
                        <option value="zToA">Z to A</option>
                    </select>
                    </div> */}
            </div>

            <div className="overflow-y-scroll no-scrollbar p-2 max-h-[100vh] sm:max-h-[65vh]  md:max-h-[55vh] lg:max-h-[40vh]  ">
              <table id="table" className="inline-table w-full">
                <tbody className="">
                  {tableRowsData.map((item) => (
                    <tr
                      key={item._id}
                      className="border-b-2 bg-[#F5F7F7]  border-[#FFFFFF] hover:cursor-pointer"
                    >
                      <td className=" text-sm py-1.5 text-left  text-[#5D6881]">
                        {item.name}
                      </td>
                      <td
                        className=" text-sm py-1.5 text-[#5D6881] text-left flex items-center space-x-2"
                        onClick={() => {
                          if (item.isSolved) return;
                          else {
                            setMessageDetailVisible(true);
                            setName(item.name);
                            setType(item.type);
                            setMessage(item.message);
                            setDate(item.date);
                            setEmail(item.email);
                            setId(item._id);
                            setIsSolved(item.isSolved);
                          }
                        }}
                      >
                        <div
                          className={cn(
                            "py-1 px-1.5 text-white rounded-lg  w-fit ",
                            {
                              "bg-[#FB4C2F]": item.type === "Complaint",
                              "bg-[#FFC740]": item.type === "Order Inquiry",
                              "bg-[#BB2AF7]": item.type === "Suggestion",
                              "bg-[#396BEE]": item.type === "Query",
                            }
                          )}
                        >
                          {item.type}
                        </div>
                        <div>{item.message}</div>
                      </td>
                      {/* <td className=" text-sm py-1.5 text-[#5D6881]">{item.message}</td> */}
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {item.date.split("T")[0]}
                      </td>
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {item.isSolved ? (
                          <p className="text-green-500">Solved</p>
                        ) : (
                          <p className="text-red-500">Pending</p>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className={
              !messageDetailVisible
                ? "hidden"
                : "bg-white rounded-xl absolute shadow-lg inset-y-20  inset-x-52 flex items-center justify-center max-h-[500px]"
            }
          >
            <MessageDetail
              setMessageDetailVisible={setMessageDetailVisible}
              fetchData={fetchData}
              name={name}
              type={type}
              message={message}
              date={date}
              email={email}
              id={id}
              isSolved={isSolved}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Queries;
