import React, { useEffect, useState } from "react";
import laptop from "../../../assets/images/laptop.png";
import { CloseIcon } from "../../icons/icons";
import { useNavigate } from "react-router-dom";
import url from "../../../Config/url";
import Loader from "../../Loader/Loader";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";

const MYCheckout = ({ billButton, cart }) => {
  const pro = ["1", "2", "3", "4", "5"];
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState(cart);
  const [displayItems, setDisplayItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState(
    localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer")).name
      : ""
  );
  const [email, setEmail] = useState(
    localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer")).email
      : ""
  );
  const [phone, setPhone] = useState(
    localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer")).contact
      : ""
  );
  const [city, setCity] = useState("");
  const [address, setAddress] = useState(
    localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer")).address
      : ""
  );
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const filterProducts = async () => {
    let arr = [];
    let arr1 = [];
    let response1 = await fetch(`${url}/api/offer/getLive`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    let data1 = await response1.json();
    if (data1.status) {
      let offers = data1.data;
      for (const product of cartItems) {
        let cartItemqty = product.qty;
        let res = await fetch(
          `${url}/api/product/getProductById?id=${product.id}`
        );
        let data = await res.json();
        let newDiscount = data.data.sale ? data.data.sale : 0;
        offers.forEach((offer) => {
          if (offer.categories.includes(data.data.category)) {
            if (offer.percentage >= newDiscount) {
              newDiscount = offer.percentage;
            }
          }
        });
        let variations = data.data.variations;
        let variation = variations.find((variation) => {
          return variation.variant === product.variant;
        });
        if (variation) {
          if (cartItemqty <= variation.qty) {
            arr.push({
              id: product.id,
              qty: product.qty,
              variant: product.variant,
              price:
                newDiscount !== 0
                  ? variation.price - variation.price * (newDiscount / 100)
                  : variation.price,
            });
            arr1.push({
              id: product.id,
              qty: product.qty,
              variant: product.variant,
              price:
                newDiscount !== 0
                  ? variation.price - variation.price * (newDiscount / 100)
                  : variation.price,
              name: data.data.name,
              store: data.data.store,
            });
          }
        }
      }
      setLoading(false);
      setCartItems(arr);
      setDisplayItems(arr1);
    } else {
      for (const product of cartItems) {
        let cartItemqty = product.qty;
        let res = await fetch(
          `${url}/api/product/getProductById?id=${product.id}`
        );
        let data = await res.json();
        let newDiscount = data.data.sale ? data.data.sale : 0;
        let variations = data.data.variations;
        let variation = variations.find((variation) => {
          return variation.variant === product.variant;
        });
        if (variation) {
          if (cartItemqty <= variation.qty) {
            arr.push({
              id: product.id,
              qty: product.qty,
              variant: product.variant,
              price:
                newDiscount !== 0
                  ? variation.price - variation.price * (newDiscount / 100)
                  : variation.price,
            });
            arr1.push({
              id: product.id,
              qty: product.qty,
              variant: product.variant,
              price:
                newDiscount !== 0
                  ? variation.price - variation.price * (newDiscount / 100)
                  : variation.price,
              name: data.data.name,
              store: data.data.store,
            });
          }
        }
      }
      setLoading(false);
      setCartItems(arr);
      setDisplayItems(arr1);
    }
  };

  const proceed = () => {
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let phoneRegex =
      /(\+)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(\d{7,14})$/;
    if (name === "") {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter your name",
      });
    } else if (email === "") {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter your email",
      });
    } else if (!emailRegex.test(email)) {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter a valid email",
      });
    } else if (phone === "") {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter your phone number",
      });
    } else if (!phoneRegex.test(phone)) {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter a valid phone number",
      });
    } else if (city === "") {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter your city",
      });
    } else if (address === "") {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter your address",
      });
    } else {
      let order = {
        name: name,
        customer: email,
        contact: phone,
        city: city,
        address: address,
        products: cartItems,
        htmlProducts: displayItems,
      };
      navigate("/payment", { state: { order: order } });
    }
  };

  useEffect(() => {
    filterProducts();
  }, []);
  return (
    <div className="  ">
      {/* <div className='text-left font-semibold text-lg py-3'>
            Checkout
        </div> */}
      <div className="flex flex-col md:flex-row space-y-5 md:space-y-0">
        <div className=" md:w-2/3 space-y-4">
          <div className="text-left font-semibold text-lg">Checkout</div>
          <div className="p-8  bg-white space-y-2 border border-[#E0E0E0] rounded-lg">
            <div className="flex ">
              <label className="flex flex-col w-1/2 text-left mx-1" htmlFor="">
                {" "}
                Name{" "}
                <input
                  className="h-8 border rounded-md my-1 px-2"
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </label>
              <label className="flex flex-col w-1/2 text-left mx-1" htmlFor="">
                {" "}
                Email{" "}
                <input
                  className="h-8 border rounded-md my-1 px-2"
                  type="text"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="flex ">
              <label className="flex flex-col w-1/2 text-left mx-1" htmlFor="">
                {" "}
                Phone no{" "}
                <input
                  className="h-8 border rounded-md my-1 px-2"
                  type="text"
                  placeholder="+92123456789"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </label>
              <label className="flex flex-col w-1/2 text-left mx-1" htmlFor="">
                {" "}
                City{" "}
                <input
                  className="h-8 border rounded-md my-1 px-2"
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="">
              <label className="flex flex-col  text-left mx-1" htmlFor="">
                {" "}
                Address{" "}
                <input
                  className="h-8 border rounded-md my-1 px-2"
                  type="text"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </label>
            </div>
          </div>
          <div className="text-left font-semibold text-lg">Order Items</div>
          <div className=" max-h-[450px] bg-white rounded-lg border border-[#E0E0E0] overflow-y-scroll no-scrollbar">
            {loading && <Loader width={80} height={80} />}
            {cartItems.length !== 0 && (
              <>
                {displayItems.map((item, index) => (
                  <>
                    <div
                      key={index}
                      className="flex  relative items-center justify-between p-2 "
                    >
                      <div className="flex space-x-4">
                        {/* <div className="h-16 w-16 bg-[#F7F7F7] border border-[#E0E0E0] flex items-center justify-center rounded-lg">
                      <img className="h-12 w-12" src={laptop} alt="" />
                    </div> */}
                        <div className="text-left space-y-1">
                          <p className="text-md font-medium">{item.name}</p>
                          <div className="">
                            <div className=" md:space-x-1 flex flex-col md:flex-row text-xs md:text-base text-[#8B96A5]">
                              <span>Variant: {item.variant}</span>
                            </div>
                            <span className="text-[#8B96A5] text-xs md:text-base">
                              Store: {item.store}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="absolute text-right text-sm flex flex-col justify-between h-full space-y-2 right-6 py-3 ">
                        <div className="">${item.price}</div>
                        <div className="">Qty: {item.qty}</div>
                      </div>
                    </div>
                    <hr className="m-2" />
                  </>
                ))}
              </>
            )}
          </div>
        </div>
        <div className=" md:w-1/3 md:mx-5 space-y-6">
          {/* <div className='space-y-3 text-left p-5 bg-white border border-[#DEE2E7] rounded-lg'>
                    <p>Have a coupon?</p>
                    <div className="   rounded-lg">
                    <input 
                        className="w-2/3 h-8  rounded-l-lg px-3 border border-[#E0E0E0]"
                        type="text" 
                        placeholder="Add coupon" />
                    <button className="w-1/3 h-8 border border-[#E0E0E0]  text-[#0D6EFD] rounded-r-lg">
                        Apply
                    </button>
                    </div>
                </div> */}

          <div className="space-y-3 text-left p-5 bg-white border border-[#DEE2E7] rounded-lg mt-11">
            <div className="flex justify-between">
              <span>Subtotal:</span>{" "}
              <span>
                $
                {cartItems.reduce(
                  (acc, item) => acc + item.price * item.qty,
                  0
                )}
              </span>
            </div>
            {/* <div className='flex justify-between'><span>Voucher:</span> <span className='text-[#00B517]'>Rs. -5000</span></div> */}
            <hr />
            <div className="flex justify-between font-bold">
              <span>Total:</span>{" "}
              <span className="text-lg">
                $
                {cartItems.reduce(
                  (acc, item) => acc + item.price * item.qty,
                  0
                )}
              </span>
            </div>
            <button
              className="bg-[#00B5DA] text-white p-2 px-3 m-auto w-full rounded-lg flex items-center justify-center text-sm"
              onClick={() => {
                proceed();
              }}
            >
              {billButton}
            </button>
          </div>
        </div>
      </div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default MYCheckout;
