import CategoryAllProducts from "../components/customer-dashboard/category-products/CategoryAllProducts";
import Footer from "../components/customer-dashboard/footer/Footer";
import SubscribeOurNewsLetter from "../components/customer-dashboard/subscribe-newsletter/SubscribeOurNewsLetter";
import CustomerNavbar from "../components/navbar/customer-navbar/CustomerNavbar";
import { useState } from "react";
import { useLocation } from "react-router";

const  AllProducts = () => {
  const location = useLocation();
  const [products, setProducts] = useState(location?.state?.products ?? []);
  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div>
        <CustomerNavbar />
      </div>
      <div className=" mx-3 md:mx-20 space-y-12 my-4 md:my-10">
        <CategoryAllProducts products={products} />
      </div>
      <SubscribeOurNewsLetter />
      <Footer />
    </div>
  );
};

export default AllProducts;
