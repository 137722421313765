import React, { useEffect, useState } from "react";
import DiscountBanner from "../components/customer-dashboard/product-detail/DiscountBanner";
import Footer from "../components/customer-dashboard/footer/Footer";
import CustomerNavbar from "../components/navbar/customer-navbar/CustomerNavbar";
import MyCart from "../components/customer-dashboard/cart/MyCart";
import PeopleAlsoViewed from "../components/customer-dashboard/product-detail/PeopleAlsoViewed";
import MYCheckout from "../components/customer-dashboard/checkout/MyCheckout";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cart, setCart] = useState(
    location?.state?.cart ? location.state.cart : []
  );

  useEffect(() => {
    if (cart.length === 0) {
      navigate("/cart");
    }
  }, []);
  return (
    <div className="md:min-w-[1000px]">
      <div>
        <CustomerNavbar />
      </div>
      {cart.length > 0 && (
        <div className=" mx-3 md:mx-20 space-y-10 my-4 md:my-10  ">
          <MYCheckout billButton="Pay" cart={cart} />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Checkout;
