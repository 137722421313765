import { useState, useEffect } from "react";
import url from "../../../Config/url";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";

const MyCart = ({ item, setCart, cart }) => {
  const [product, setProduct] = useState({});
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const getProduct = async () => {
    const res = await fetch(`${url}/api/product/getProductById?id=${item.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    const data = await res.json();
    setProduct(data.data);
  };
  useEffect(() => {
    getProduct();
  }, []);
  return (
    <>
      <div className="flex  relative items-center justify-between p-2 ">
        {product.name && (
          <>
            <div className="flex space-x-4">
              {/* <div className="h-16 w-16 bg-[#F7F7F7] border border-[#E0E0E0] flex items-center justify-center rounded-lg">
          <img className="h-12 w-12" src={laptop} alt="" />
        </div> */}
              <div className="text-left space-y-1">
                <p className="text-md font-medium">{product.name}</p>
                <div className="">
                  <div className=" md:space-x-1 flex flex-col md:flex-row text-xs md:text-base text-[#8B96A5]">
                    <span>Variant: {item.variant}</span>
                  </div>
                  <span className="text-[#8B96A5] text-xs md:text-base">
                    Store: {product.store}
                  </span>
                </div>
                <div className="flex space-x-2 ">
                  <button
                    className="border border-[#DEE2E7] text-[#FA3434] p-2 px-3 rounded-lg w-fit m-auto  md:m-0 flex items-center justify-center text-sm"
                    onClick={() => {
                      const newCart = cart.filter(
                        (i) => i.id !== item.id || i.variant !== item.variant
                      );
                      setCart(newCart);
                      localStorage.setItem("cart", JSON.stringify(newCart));
                    }}
                  >
                    Remove
                  </button>
                  {/* <button className="border border-[#DEE2E7] text-[#0D6EFD] p-2 px-3 rounded-lg w-fit m-auto  md:m-0 flex items-center justify-center text-sm">
              Save For Later
            </button> */}
                </div>
              </div>
            </div>
            <div className="absolute text-right space-y-2 right-6 top-2 hover:cursor-pointer flex flex-col justify-between bottom-4">
              <div className="font-semibold">${item.price}</div>
              <div className="flex space-x-2 md:space-x-3  items-center">
                <div
                  className="bg-[#83838333] flex h-5 w-5 items-center justify-center rounded-full pb-1 hover:cursor-pointer"
                  onClick={async () => {
                    if (item.qty > 1) {
                      let response = await fetch(
                        `${url}/api/product/getProductById?id=${item.id}`,
                        {
                          method: "GET",
                          headers: {
                            "Content-Type": "application/json",
                            //"Access-Control-Allow-Origin": "*",
                          },
                        }
                      );
                      let data = await response.json();
                      let product1 = data.data;
                      let newDiscount = product1.sale ? product1.sale : 0;
                      let response1 = await fetch(`${url}/api/offer/getLive`, {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                          //"Access-Control-Allow-Origin": "*",
                        },
                      });
                      let data1 = await response1.json();
                      if (data1.status) {
                        let offers = data1.data;
                        offers.forEach((offer) => {
                          if (offer.categories.includes(product1.category)) {
                            if (offer.percentage >= newDiscount) {
                              newDiscount = offer.percentage;
                            }
                          }
                        });
                        let newQty = product1.variations.find(
                          (v) => v.variant === item.variant
                        ).qty;
                        let newPrice = product1.variations.find(
                          (v) => v.variant === item.variant
                        ).price;
                        let finalPrice =
                          newDiscount !== 0
                            ? newPrice - (newDiscount / 100) * newPrice
                            : newPrice;
                        if (item.qty - 1 <= newQty) {
                          const newCart = cart.map((i) => {
                            if (
                              i.id === item.id &&
                              i.variant === item.variant
                            ) {
                              return {
                                ...i,
                                qty: i.qty - 1,
                                price: finalPrice,
                              };
                            } else {
                              return i;
                            }
                          });
                          setCart(newCart);
                          localStorage.setItem("cart", JSON.stringify(newCart));
                        } else {
                          HandleErrorAndSuccessMessage({
                            type: "error",
                            message:
                              "Sorry! You can't add more than " +
                              newQty +
                              " items.",
                          });
                        }
                      } else {
                        let newQty = product1.variations.find(
                          (v) => v.variant === item.variant
                        ).qty;
                        let newPrice = product1.variations.find(
                          (v) => v.variant === item.variant
                        ).price;
                        let finalPrice =
                          newDiscount !== 0
                            ? newPrice - (newDiscount / 100) * newPrice
                            : newPrice;
                        if (item.qty - 1 <= newQty) {
                          const newCart = cart.map((i) => {
                            if (
                              i.id === item.id &&
                              i.variant === item.variant
                            ) {
                              return {
                                ...i,
                                qty: i.qty - 1,
                                price: finalPrice,
                              };
                            } else {
                              return i;
                            }
                          });
                          setCart(newCart);
                          localStorage.setItem("cart", JSON.stringify(newCart));
                        } else {
                          HandleErrorAndSuccessMessage({
                            type: "error",
                            message:
                              "Sorry! You can't add more than " +
                              newQty +
                              " items.",
                          });
                        }
                      }
                    }
                  }}
                >
                  -
                </div>
                <span>{item.qty}</span>
                <div
                  className="bg-[#83838333] flex h-5 w-5 items-center justify-center rounded-full pb-1 hover:cursor-pointer "
                  onClick={async () => {
                    let response = await fetch(
                      `${url}/api/product/getProductById?id=${item.id}`,
                      {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                          //"Access-Control-Allow-Origin": "*",
                        },
                      }
                    );
                    let data = await response.json();
                    let product1 = data.data;
                    let newDiscount = product1.sale ? product1.sale : 0;
                    let response1 = await fetch(`${url}/api/offer/getLive`, {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json",
                        //"Access-Control-Allow-Origin": "*",
                      },
                    });
                    let data1 = await response1.json();
                    if (data1.status) {
                      let offers = data1.data;
                      offers.forEach((offer) => {
                        if (offer.categories.includes(product1.category)) {
                          if (offer.percentage >= newDiscount) {
                            newDiscount = offer.percentage;
                          }
                        }
                      });
                      let newQty = product1.variations.find(
                        (v) => v.variant === item.variant
                      ).qty;
                      let newPrice = product1.variations.find(
                        (v) => v.variant === item.variant
                      ).price;
                      let finalPrice =
                        newDiscount !== 0
                          ? newPrice - (newDiscount / 100) * newPrice
                          : newPrice;
                      if (item.qty + 1 <= newQty) {
                        const newCart = cart.map((i) => {
                          if (i.id === item.id && i.variant === item.variant) {
                            return { ...i, qty: i.qty + 1, price: finalPrice };
                          } else {
                            return i;
                          }
                        });
                        setCart(newCart);
                        localStorage.setItem("cart", JSON.stringify(newCart));
                      } else {
                        HandleErrorAndSuccessMessage({
                          type: "error",
                          message:
                            "Sorry! You can't add more than " +
                            newQty +
                            " items.",
                        });
                      }
                    } else {
                      let newQty = product1.variations.find(
                        (v) => v.variant === item.variant
                      ).qty;
                      let newPrice = product1.variations.find(
                        (v) => v.variant === item.variant
                      ).price;
                      let finalPrice =
                        newDiscount !== 0
                          ? newPrice - (newDiscount / 100) * newPrice
                          : newPrice;
                      if (item.qty + 1 <= newQty) {
                        const newCart = cart.map((i) => {
                          if (i.id === item.id && i.variant === item.variant) {
                            return { ...i, qty: i.qty + 1, price: finalPrice };
                          } else {
                            return i;
                          }
                        });
                        setCart(newCart);
                        localStorage.setItem("cart", JSON.stringify(newCart));
                      } else {
                        HandleErrorAndSuccessMessage({
                          type: "error",
                          message:
                            "Sorry! You can't add more than " +
                            newQty +
                            " items.",
                        });
                      }
                    }
                  }}
                >
                  +
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <hr className="m-2" />
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </>
  );
};

export default MyCart;
