import { MessageIcon, NotificationIcon, SearchIcon } from "../../icons/icons";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SellerNavbar = () => {
  const navigate = useNavigate();
  const [dateTime, setDateTime] = useState(new Date());
  const [seller, setSeller] = useState(
    localStorage.getItem("seller")
      ? JSON.parse(localStorage.getItem("seller"))
      : null
  );

  useEffect(() => {
    if (seller === null) {
      navigate("/user/login");
      return;
    }
    const interval = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (date) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
    };

    return date.toLocaleTimeString(undefined, options);
  };

  return (
    <div className="flex flex-row  bg-white h-full">
      <div className="flex flex-col justify-center mx-8">
        <div className="text-base md:text-xl font-medium md:font-bold">
          Welcome,{" "}
          {localStorage.getItem("seller")
            ? JSON.parse(localStorage.getItem("seller")).name
            : ""}
        </div>
        <div className="text-xs md:text-sm font-normal md:font-medium text-[#7C7C7C]">
          {formatDate(dateTime)} | {formatTime(dateTime)}
        </div>
      </div>
      {/* <div className="flex flex-row items-center hover:cursor-pointer  m-auto mr-2 px-4  ">
        <NotificationIcon />
      </div> */}
    </div>
  );
};

export default SellerNavbar;
