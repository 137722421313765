import laptopPic from "../../../assets/images/laptop.png";
import exploreCaregoryPic from "../../../assets/images/exploreCategoreis.png";
import { useNavigate } from "react-router-dom";
import url from "../../../Config/url";

const ExploreCategories = ({ categories }) => {
  const navigate = useNavigate();

  const getProductsByCategory = async (category) => {
    let response = await fetch(
      `${url}/api/product/getProductsByFilter?category=${category}&isActive=true`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      }
    );
    let data = await response.json();
    if (data.status) {
      navigate("/products", { state: { products: data.data } });
    } else {
      if (data.message === "No products found") {
        navigate("/products", { state: { products: [] } });
      } else {
        console.log(data.message);
      }
    }
  };
  return (
    <div className="hidden md:block ">
      {categories.length > 0 && (
        <div className="flex flex-col bg-white md:flex-row border border-[#DEE2E7] rounded-md h-60">
          <div className=" relative h-full min-w-[224px]">
            <img
              className="rounded-l-md w-full h-full"
              src={exploreCaregoryPic}
              alt=""
            />
            <div className="absolute left-0 top-0 m-4 text-left">
              <div className="text-xl font-semibold ">Explore</div>
              <div className="text-xl font-semibold ">Categories</div>
              <span
                className="text-xs font-normal text-[#3366CC] hover:underline hover:cursor-pointer text-left"
                onClick={() => navigate("/categories")}
              >
                More Categories
              </span>
            </div>
          </div>

          <div className="flex flex-col w-full h-full">
            <div className="grid grid-cols-4 h-1/2 w-full">
              {categories.map((category, index) => {
                if (index <= 3) {
                  return (
                    <div
                      className="border relative text-left flex flex-col  p-1 lg:p-2 hover:cursor-pointer"
                      key={category._id}
                      onClick={() => getProductsByCategory(category.name)}
                    >
                      <span className="text-sm lg:text-md text-left">
                        {category.name}
                      </span>
                      <span className="text-xs lg:text-sm text-left text-gray-400">
                        {category.parent_category}
                      </span>
                      <img
                        className="absolute right-1 lg:right-3 bottom-1 w-12 lg:w-16 h-12 lg:h-16"
                        src={category.image}
                        alt={category.name}
                      />
                    </div>
                  );
                }
              })}
            </div>
            <div className="grid grid-cols-4 h-1/2 w-full">
              {categories.map((category, index) => {
                if (index > 3 && index <= 7) {
                  return (
                    <div
                      className="border relative flex flex-col p-1 lg:p-2 hover:cursor-pointer"
                      key={category._id}
                      onClick={() => getProductsByCategory(category.name)}
                    >
                      <span className="text-sm lg:text-md text-left">
                        {category.name}
                      </span>
                      <span className="text-xs lg:text-sm text-left text-gray-400">
                        {category.parent_category}
                      </span>
                      <img
                        className="absolute right-1 lg:right-3 bottom-1  w-12 lg:w-16 h-12 lg:h-16"
                        src={category.image}
                        alt={category.name}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExploreCategories;
