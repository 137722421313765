import { useEffect, useState } from "react";
import cn from "classnames";
import url from "../../Config/url";
import Loader from "../Loader/Loader";
import ErrorAndSuccessMessage from "../seller-dashboard/ErrorAndSuccessMessage";
import { useErrorAndSuccessMessageStore } from "../../ErrorAndSuccessMessageStore";

const Fund = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const getOrders = async () => {
    try {
      setLoading(true);
      const res = await fetch(`${url}/api/fund/getFunds`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      });
      const data = await res.json();
      setLoading(false);
      if (!data.status) throw new Error(data.message);
      else {
        let arr = data.data;
        arr.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA; // To sort in descending order (newest date first)
        });

        setOrders(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.location.hash = "#fundRequest";
    getOrders();
  }, []);

  return (
    <div className="space-y-3 relative p-2 w-full h-full">
      {loading && (
        <div className="w-full h-full flex justify-center items-center">
          <Loader width={100} height={100} />
        </div>
      )}
      {!loading && (
        <>
          <div
            className={`h-full space-y-2 p-4 border bg-white border-[#D1D1D1] rounded-lg
        }`}
          >
            <div className="flex relative  items-center ">
              <h1 className="text-sm sm:text-lg text-[#5D6881] font-semibold ">
                Request{" "}
                <span className="text-[#BDBDBD]">({orders.length})</span>
              </h1>
              {/* <div className="flex w-32 h-8 sm:h-auto sm:w-auto absolute right-0 hover:cursor-pointer   ">
            <label
              htmlFor="filter"
              className="absolute right-3 text-[#7C7C7C] hover:cursor-pointer"
            >
              <FilterIcon />
            </label>
            <select
              id="filter"
              // ref={selectRef}
              // onChange={(e) => setFilter(e.target.value)}
              className="bg-[#F2F7FF] rounded-lg w-24 sm:w-auto  hover:cursor-pointer p-1 appearance-none"
            >
              <option value="highToLow">Filter</option>
              <option value="highToLow">High to Low</option>
              <option value="lowToHigh">Low to High</option>
              <option value="aToZ">A to Z</option>
              <option value="zToA">Z to A</option>
            </select>
          </div> */}
            </div>

            <div className="overflow-y-scroll no-scrollbar p-2 max-h-[80vh]">
              <table id="table" className="inline-table w-full">
                <tbody className="">
                  <tr className="border-b  border-[#D1D1D1]">
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">No</td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">
                      Seller name
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">
                      Seller email
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">Date</td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">
                      Amount Requested
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]">Status</td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C]"></td>
                  </tr>
                  {orders.map((item, index) => {
                    return (
                      <tr key={item._id} className="border-b  border-[#D1D1D1]">
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          {index + 1}
                        </td>
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          {item.seller.name}
                        </td>
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          {item.seller.email}
                        </td>
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          {new Date(item.date).toLocaleDateString()}
                        </td>
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          {item.requestAmount}
                        </td>
                        <td className=" text-sm py-1.5 text-[#5D6881]">
                          <div
                            className={cn(
                              "py-1 px-2 text-white rounded-full mx-auto w-fit ",
                              {
                                "bg-[#D92D20]": item.status === "Rejected",
                                "bg-[#039855]": item.status === "Pending",
                                "bg-[#F2C94C]": item.status === "Approved",
                              }
                            )}
                          >
                            {item.status}
                          </div>
                        </td>
                        <td>
                          {item.status === "Pending" && (
                            <>
                              <button
                                className="bg-[#D92D20] text-white rounded-md py-1 text-sm w-[60px]"
                                onClick={async () => {
                                  let res = await fetch(
                                    `${url}/api/fund/updateFund`,
                                    {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                        //"Access-Control-Allow-Origin": "*",
                                      },
                                      body: JSON.stringify({
                                        id: item._id,
                                        status: "Rejected",
                                        seller: item.seller.email,
                                      }),
                                    }
                                  );
                                  const response = await res.json();
                                  if (response.status) {
                                    getOrders();
                                  } else {
                                    HandleErrorAndSuccessMessage({
                                      type: "error",
                                      message: "Something went wrong",
                                    });
                                    console.log(response.message);
                                  }
                                }}
                              >
                                Reject
                              </button>
                              <button
                                className="bg-[#039855] text-white rounded-md py-1 ml-1 text-sm w-[70px]"
                                onClick={async () => {
                                  let res = await fetch(
                                    `${url}/api/fund/updateFund`,
                                    {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                        //"Access-Control-Allow-Origin": "*",
                                      },
                                      body: JSON.stringify({
                                        id: item._id,
                                        status: "Approved",
                                        seller: item.seller.email,
                                        paidAmount: parseFloat(
                                          item.requestAmount
                                        ),
                                      }),
                                    }
                                  );
                                  const response = await res.json();
                                  if (response.status) {
                                    getOrders();
                                  } else {
                                    HandleErrorAndSuccessMessage({
                                      type: "error",
                                      message: "Something went wrong",
                                    });
                                    console.log(response.message);
                                  }
                                }}
                              >
                                Approve
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default Fund;
