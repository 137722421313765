import { useEffect, useState } from "react";
import Logo from "../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import MyCartNavbar from "../../customer-dashboard/my-cart-nav/MyCartNavbar";
import { CartIcon, HeartIcon, MenuIcon, ProfileIcon } from "../../icons/icons";
import url from "../../../Config/url";
import logout from "../../../assets/images/logout.png";

const CustomerNavbar = () => {
  const [myCart, setMyCart] = useState(false);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [inputData, setInputData] = useState("");
  const [category, setCategory] = useState("All");

  const getCategories = async () => {
    const response = await fetch(`${url}/api/category/getAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    const data = await response.json();
    if (data.status) {
      setCategories(data.data);
    } else {
      console.log(data.message);
    }
  };

  const getProducts = async (category, inputData) => {
    const response = await fetch(
      `${url}/api/product/getProductsByFilter1?category=${category}&name=${inputData}&description=${inputData}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      }
    );
    const data = await response.json();
    if (data.status) {
      navigate("/products", { state: { products: data.data } });
    } else {
      if (data.message === "No products found") {
        navigate("/products", { state: { products: [] } });
      } else {
        console.log(data.message);
      }
    }
  };

  const getWishlist = async () => {
    if (localStorage.getItem("customer")) {
      let userId = JSON.parse(localStorage.getItem("customer"))._id;
      let response = await fetch(
        `${url}/api/wishlist/getWishlist?user=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            //"Access-Control-Allow-Origin": "*",
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        if (data.data.length === 0) {
          navigate("/products", { state: { products: [] } });
        } else {
          let arr = [];
          for (let i = 0; i < data.data.length; i++) {
            arr.push(data.data[i].productId);
          }
          navigate("/products", { state: { products: arr } });
        }
      } else {
        console.log(data.message);
      }
    } else {
      navigate("/user/login");
    }
  };

  const getOffers = async () => {
    let response = await fetch(`${url}/api/offer/getLive`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    let data = await response.json();
    console.log(data);
    if (response.status === 200) {
      if (data.status === false) {
        navigate("/products", { state: { products: [] } });
      } else {
        let arr = [];
        for (let i = 0; i < data.data.length; i++) {
          let categories = data.data[i].categories;
          for (let j = 0; j < categories.length; j++) {
            const res = await fetch(
              `${url}/api/product/getProductsByFilter?category=${categories[j]}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  //"Access-Control-Allow-Origin": "*",
                },
              }
            );
            const data = await res.json();
            if (data.status) {
              for (let k = 0; k < data.data.length; k++) {
                arr.filter((item) => item._id === data.data[k]._id).length ===
                  0 && arr.push(data.data[k]);
              }
            } else {
              console.log(data.message);
            }
          }
        }
        navigate("/products", { state: { products: arr } });
      }
    } else {
      console.log(data.message);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="bg-white relative border boder-[#E0E0E0]">
      <div className="h-[70px] flex justify-between items-center px-4 md:px-16 ">
        <div
          className="flex h-16  items-center hover:cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          <img className="h-16" src={Logo} alt="" />
        </div>
        <div className="hidden  md:flex w-1/2 h-10   rounded-lg">
          <input
            className="w-3/5  rounded-l-lg px-3 border border-gray-300"
            type="text"
            placeholder="Search"
            onChange={(e) => {
              setInputData(e.target.value);
            }}
            value={inputData}
          />
          <select
            className="w-1/5 border border-gray-300 text-sm"
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
          >
            <option value="All">All Category</option>
            {categories.map((category) => (
              <option value={category.name}>{category.name}</option>
            ))}
          </select>
          <button
            className="w-1/5 bg-[#00B5DA] text-white rounded-r-lg"
            onClick={() => {
              getProducts(category, inputData);
            }}
          >
            Search
          </button>
        </div>
        <div className="flex space-x-3 md:space-x-8   items-center">
          {localStorage.getItem("customer") ? (
            <div
              className="flex flex-col font-normal text-xs md:text-sm text-[#8B96A5] space-y-0 md:space-y-1 hover:cursor-pointer"
              onClick={() => {
                localStorage.removeItem("customer");
                window.location.reload();
              }}
            >
              <img src={logout} alt="" className="w-4 h-4 md:w-5 md:h-5 ml-3" />
              <span>Logout</span>
            </div>
          ) : (
            <div
              className="flex flex-col font-normal text-xs md:text-sm text-[#8B96A5] space-y-0 md:space-y-1 hover:cursor-pointer"
              onClick={() => {
                navigate("/user/login");
              }}
            >
              <ProfileIcon /> <span>Profile</span>{" "}
            </div>
          )}
          {/* <div
            className="flex flex-col font-normal text-xs md:text-sm text-[#8B96A5] space-y-0 md:space-y-1 hover:cursor-pointer"
            onClick={() => {
              navigate("/orders");
            }}
          >
            <HeartIcon /> <span>Orders</span>{" "}
          </div> */}
          <div
            className="flex flex-col font-normal text-xs md:text-sm text-[#8B96A5] space-y-0 md:space-y-1 hover:cursor-pointer"
            onClick={() => {
              navigate("/cart");
            }}
          >
            <CartIcon /> <span>My Cart</span>{" "}
          </div>
        </div>
      </div>
      <div className="hidden md:flex border boder-[#E0E0E0] h-12 space-x-6 justify-center font-medium items-center">
        <div
          className="flex items-center hover:cursor-pointer"
          onClick={() => {
            getOffers();
          }}
        >
          Offers
        </div>
        <div
          className="flex items-center hover:cursor-pointer"
          onClick={() => {
            getWishlist();
          }}
        >
          Wishlist
        </div>
        <div
          className="flex items-center hover:cursor-pointer"
          onClick={() => {
            navigate("/orders");
          }}
        >
          Orders
        </div>
        <div
          className="flex items-center hover:cursor-pointer"
          onClick={() => {
            navigate("/refund");
          }}
        >
          Refund
        </div>
        <div
          className="flex items-center hover:cursor-pointer"
          onClick={() => {
            navigate("/contact");
          }}
        >
          Contact
        </div>
      </div>
      <div className="flex md:hidden text-xs mx-4 my-5 h-10 rounded-lg">
        <input
          className="w-3/5 border border-gray-300  rounded-l-lg px-3"
          type="text"
          placeholder="Search"
        />
        <select className="w-1/5 border border-gray-300 text-sm" name="" id="">
          <option value="a">All Category</option>
          <option value="a">a</option>
          <option value="b">b</option>
        </select>
        <button className="w-1/5 bg-[#00B5DA] text-white rounded-r-lg">
          Search
        </button>
      </div>

      {myCart && (
        <div className="absolute z-10 w-2/5 right-20 top-20">
          <MyCartNavbar setMyCart={setMyCart} />
        </div>
      )}
    </div>
  );
};

export default CustomerNavbar;
