import React, { useState } from "react";
import { EditIcon } from "../../icons/icons";
import url from "../../../Config/url";
import validator from "validator";
import ErrorAndSuccessMessage from "../ErrorAndSuccessMessage";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";

const AccountSetting = ({
  settingOption,
  setSettingOption,
  store,
  setStore,
  seller,
  setSeller,
}) => {
  const [shopName, setShopName] = useState(store.name);
  const [email, setEmail] = useState(seller.email);
  const [mobileNumber, setMobileNumber] = useState(seller.contact);
  const [cnic, setCnic] = useState(seller.identityno);
  const [storeAddress, setStoreAddress] = useState(seller.address);
  const [isEdit, setIsEdit] = useState(false);
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const handleUpdate = async () => {
    if (isEdit === false) {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please click on edit icon to edit",
      });
    } else {
      if (validator.isMobilePhone(mobileNumber)) {
        if (cnic.length >= 7) {
          const res = await fetch(`${url}/api/user/updateUser`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              //"Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
              id: seller._id,
              contact: mobileNumber,
              identityno: cnic,
              address: storeAddress,
            }),
          });
          const response = await res.json();
          if (response.status) {
            let obj = seller;
            delete obj.contact;
            delete obj.identityno;
            delete obj.address;
            obj.contact = mobileNumber;
            obj.identityno = cnic;
            obj.address = storeAddress;

            setSeller(obj);
            localStorage.setItem("seller", JSON.stringify(obj));
            const res1 = await fetch(`${url}/api/store/update`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                //"Access-Control-Allow-Origin": "*",
              },
              body: JSON.stringify({
                id: store._id,
                address: storeAddress,
              }),
            });
            const data = await res1.json();
            if (data.status) {
              let obj1 = store;
              delete obj1.address;

              obj1.address = storeAddress;
              setStore(obj1);
              setIsEdit(false);
              HandleErrorAndSuccessMessage({
                type: "success",
                message: "Updated successfully",
              });
            } else {
              HandleErrorAndSuccessMessage({
                type: "error",
                message: "Something went wrong",
              });
              console.log(data.message);
            }
          } else {
            HandleErrorAndSuccessMessage({
              type: "error",
              message: "Something went wrong",
            });
            console.log(response.message);
          }
        } else {
          HandleErrorAndSuccessMessage({
            type: "error",
            message: "Please Enter valid CNIC",
          });
        }
      } else {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Please Enter valid mobile number with country code",
        });
      }
    }
  };

  return (
    <div className="border border-[#D0D5DD] rounded-lg bg-white p-4 space-y-7 px-6 mx-8 my-3">
      <div className=" flex justify-between text-left text-sm font-semibold">
        <div className="space-x-4">
          <span
            className={`border-b-2 hover:cursor-pointer py-1 ${
              settingOption === "account-settings"
                ? "text-[#00B5DA] border-[#00B5DA]"
                : "border-[#D0D5DD] text-[#D0D5DD]"
            }`}
            onClick={() => {
              setSettingOption("account-settings");
            }}
          >
            Account Setting
          </span>
          <span
            className={`border-b-2 hover:cursor-pointer py-1 ${
              settingOption === "login&security"
                ? "text-[#00B5DA] border-[#00B5DA]"
                : "border-[#D0D5DD] text-[#D0D5DD]"
            }`}
            onClick={() => {
              setSettingOption("login&security");
            }}
          >
            Login & Security
          </span>
          <span
            className={`border-b-2 hover:cursor-pointer py-1 ${
              settingOption === "bank-account"
                ? "text-[#00B5DA] border-[#00B5DA]"
                : "border-[#D0D5DD] text-[#D0D5DD]"
            }`}
            onClick={() => {
              setSettingOption("bank-account");
            }}
          >
            Bank Account
          </span>
        </div>
        <div
          className="bg-[#14ae5c] text-[#D0D5DD] hover:cursor-pointer hover:text-white hover:shadow-md p-2 flex items-center justify-center rounded-full"
          onClick={() => {
            setIsEdit(!isEdit);
          }}
        >
          <EditIcon />
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex space-x-8">
          <div className="flex flex-col w-1/2 text-left space-y-1">
            <label className="text-sm" htmlFor="shopname">
              Shop Name
            </label>
            <input
              className="px-3 border rounded-lg border-[#D0D5DD] focus-none py-1.5"
              type="text"
              name="shopname"
              disabled={true}
              value={shopName}
              id="shopname"
              onChange={(e) => {
                setShopName(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col w-1/2 text-left space-y-1">
            <label className="text-sm" htmlFor="email">
              Email Address
            </label>
            <input
              className="px-3 border rounded-lg border-[#D0D5DD] focus-none py-1.5"
              type="text"
              name="email"
              disabled={true}
              value={email}
              id="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex space-x-8">
          <div className="flex flex-col w-1/2 text-left space-y-1">
            <label className="text-sm" htmlFor="mobilenumber">
              Mobile Number
            </label>
            <input
              className="px-3 border rounded-lg border-[#D0D5DD] focus-none py-1.5"
              type="text"
              name="mobilenumber"
              disabled={!isEdit}
              value={mobileNumber}
              id="mobilenumber"
              onChange={(e) => {
                setMobileNumber(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col w-1/2 text-left space-y-1">
            <label className="text-sm" htmlFor="cnic">
              Identity no
            </label>
            <input
              className="px-3 border rounded-lg border-[#D0D5DD] focus-none py-1.5"
              type="text"
              name="cnic"
              disabled={!isEdit}
              value={cnic}
              id="cnic"
              onChange={(e) => {
                setCnic(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex space-x-8">
          <div className="flex flex-col w-[100%] text-left space-y-1">
            <label className="text-sm" htmlFor="storeaddress">
              Store Address
            </label>
            <input
              className="px-3 border rounded-lg border-[#D0D5DD] focus-none py-1.5"
              type="text"
              name="storeaddress"
              value={storeAddress}
              disabled={!isEdit}
              id="storeaddress"
              onChange={(e) => {
                setStoreAddress(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className="bg-[#00B5DA] flex  text-white p-1 px-4 my-3 rounded-lg    items-center justify-center text-xs md:text-sm "
            onClick={handleUpdate}
          >
            Update
          </button>
        </div>
      </div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default AccountSetting;
