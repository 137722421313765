import { useEffect, useState } from "react";
import { CloseIcon } from "../../icons/icons";
import url from "../../../Config/url";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";

const RefundDetail = ({ setOrderDetailVisible, obj, getOrders }) => {
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };
  return (
    <div className=" w-full px-8 py-4 h-full space-y-7 ">
      <div className="flex text-left relative font-medium text-lg text-[#5D6881]">
        <label htmlFor="" className="">
          Refund Request #{obj._id}
        </label>
        <div
          className="absolute right-0 text-black hover:cursor-pointer"
          onClick={() => {
            setOrderDetailVisible(false);
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className=" space-y-1">
        <div className="font-bold text-lg text-left mx-10">Order Detail</div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Order ID:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.orderId}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Date:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {new Date(obj.date).toLocaleDateString()}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Customer:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.accountHolder}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Email:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.email}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Amount:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.products.reduce((acc, item) => acc + item.price * item.qty, 0)}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Address:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.address}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className="text-[#5D6881] w-40 text-left font-medium">
            Reason:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.reason}
          </div>
        </div>
        <div className="font-bold text-lg text-left mx-10">Account Detail</div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Account Holder:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.accountHolder}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            IBAN:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.iban}
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-12">
          <div className=" text-[#5D6881] w-40 text-left font-medium">
            Bank Name:
          </div>
          <div className=" text-[#5D6881] text-left font-normal">
            {obj.bankName}
          </div>
        </div>
        <div className="flex flex-col space-x-12">
          <div className="overflow-y-scroll no-scrollbar h-[150px]">
            <table id="table" className="inline-table w-11/12 m-2">
              <thead className="sticky top-0">
                <tr className="border-b  border-[#D1D1D1]">
                  <td className=" text-sm py-1.5 text-[#7C7C7C]">No</td>
                  <td className=" text-sm py-1.5 text-[#7C7C7C]">Name</td>
                  <td className=" text-sm py-1.5 text-[#7C7C7C]">Price</td>
                  <td className=" text-sm py-1.5 text-[#7C7C7C]">Quantity</td>
                  <td className=" text-sm py-1.5 text-[#7C7C7C]">Variant</td>
                </tr>
              </thead>
              <tbody>
                {obj.products.map((item, index) => {
                  return (
                    <tr key={item._id} className="border-b  border-[#D1D1D1]">
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {index + 1}
                      </td>
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {item.name}
                      </td>
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {item.price}
                      </td>
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {item.qty}
                      </td>
                      <td className=" text-sm py-1.5 text-[#5D6881]">
                        {item.variant}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex mx-20 flex-row space-x-2 items-center justify-center">
          {obj.status === "Pending" && (
            <>
              <div
                className="py-2 px-4 bg-[#039855] text-white rounded-full hover:cursor-pointer"
                onClick={() => {
                  fetch(`${url}/api/refund/approve`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      //"Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify({ id: obj._id }),
                  })
                    .then((res) => res.json())
                    .then((data) => {
                      if (data.status) {
                        HandleErrorAndSuccessMessage({
                          type: "success",
                          message: data.message,
                        });
                        getOrders();
                        setOrderDetailVisible(false);
                      } else {
                        HandleErrorAndSuccessMessage({
                          type: "error",
                          message: data.message,
                        });
                      }
                    });
                }}
              >
                Approve
              </div>
              <div
                className="py-2 px-4 bg-[#D92D20] text-white rounded-full hover:cursor-pointer"
                onClick={() => {
                  fetch(`${url}/api/refund/reject`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      //"Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify({ id: obj._id }),
                  })
                    .then((res) => res.json())
                    .then((data) => {
                      if (data.status) {
                        HandleErrorAndSuccessMessage({
                          type: "success",
                          message: data.message,
                        });
                        getOrders();
                        setOrderDetailVisible(false);
                      } else {
                        HandleErrorAndSuccessMessage({
                          type: "error",
                          message: data.message,
                        });
                      }
                    });
                }}
              >
                Reject
              </div>
            </>
          )}
          {obj.status === "Approved" && (
            <div
              className="py-2 px-4 bg-[#039855] text-white rounded-full hover:cursor-pointer"
              onClick={() => {
                fetch(`${url}/api/refund/refund`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    //"Access-Control-Allow-Origin": "*",
                  },
                  body: JSON.stringify({ id: obj._id }),
                })
                  .then((res) => res.json())
                  .then((data) => {
                    if (data.status) {
                      HandleErrorAndSuccessMessage({
                        type: "success",
                        message: data.message,
                      });
                      getOrders();
                      setOrderDetailVisible(false);
                    } else {
                      HandleErrorAndSuccessMessage({
                        type: "error",
                        message: data.message,
                      });
                    }
                  });
              }}
            >
              Refund
            </div>
          )}
        </div>
      </div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default RefundDetail;
