import { Carousel, IconButton } from "@material-tailwind/react";
import commingsoon from "../../../assets/images/commingsoonnew.png"

export function SlideShow() {
  const image = [
    commingsoon,
  ];
  // const intervalRef = useRef(null);
  // useEffect(() => {
  //     intervalRef.current = setInterval(handleButtonClick, 2000); // 10000ms = 10 seconds

  //     // Clean up the interval when the component unmounts
  //     return () => {
  //       clearInterval(intervalRef.current);
  //     };
  //   }, []);

  //   const handleButtonClick = () => {
  //     const nextButton = document.getElementById('nextButton');
  //     if (nextButton) {
  //       nextButton.click();
  //     }
  //   };

  return (
    <Carousel
      className=""
      autoplay={true}
      loop={true}
      prevArrow={({ handlePrev }) => (
        <IconButton
          variant="text"
          color="white"
          size="lg"
          onClick={handlePrev}
          className="!absolute top-2/4 left-4 -translate-y-2/4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            />
          </svg>
        </IconButton>
      )}
      nextArrow={({ handleNext }) => (
        <IconButton
          variant="text"
          //   id="nextButton"
          color="white"
          size="lg"
          onClick={handleNext}
          className="!absolute top-2/4 !right-4 -translate-y-2/4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </IconButton>
      )}
    >
      {image.map((imageUrl, index) => (
        <img
          src={imageUrl}
          alt={`pic ${index}`}
          key={index}
          className="h-full w-full object-cover"
        />
      ))}

      {/* <img
        src="https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
        alt="pic 2"
        className="h-full w-full object-cover"
      />
      <img
        src="https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80"
        alt="pic 3"
        className="h-full w-full object-cover"
      /> */}
    </Carousel>
  );
}
