import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import url from "../../../Config/url";
import { useLocation } from "react-router";
import { SHA512 } from "crypto-js";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";

const ChangePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState(location?.state?.email ?? "");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(location?.state?.role ?? "");
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const passwordChange = async () => {
    if (password.length > 0) {
      let hashPassword = SHA512(password).toString();
      const response = await fetch(`${url}/api/user/changePassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          email: email,
          password: hashPassword,
          role: role,
        }),
      });
      const data = await response.json();
      if (data.status) {
        navigate("/user/change-password-successfull");
      } else {
        if (data.message === "User not found") {
          HandleErrorAndSuccessMessage({
            type: "error",
            message:
              "This email does not exist in our database. Please try again",
          });
          navigate("/user/login");
        } else {
          HandleErrorAndSuccessMessage({
            type: "error",
            message: "Something went wrong",
          });
          console.log(data.message);
        }
      }
    } else {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter new password",
      });
    }
  };

  useEffect(() => {
    if (email === "" || role === "") {
      navigate("/user/login");
    }
  }, []);
  return (
    <div className="h-[100vh] md:h-[100%] xl:h-screen flex p-0 m-auto flex items-center  bg-white   md:min-w-[1000px]">
      <div className="w-full md:w-1/3 b flex justify-center md:items-center">
        <div className="mx-16 my-10 relative ">
          <div className="text-left space-y-3">
            <div className=" text-lg lg:text-xl font-bold">
              Change password for{" "}
              <span className="text-[#00B5DA]">{email}</span>
            </div>
            <div>Please enter new Password</div>
          </div>
          <div className="border-y-2 border-dashed space-y-2 lg:space-y-4 my-5 py-5  ">
            <div className="flex flex-col relative text-left space-y-1">
              <label className="text-sm" htmlFor="email">
                New password
              </label>
              <input
                type="password"
                placeholder="******"
                className=" border p-2"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
              />
            </div>
            <button
              className="bg-[#00B5DA]  text-white p-2 px-3 m-auto w-full  flex items-center justify-center text-sm"
              onClick={() => {
                passwordChange();
              }}
            >
              Change password
            </button>
          </div>
          <div className="absolute flex flex-col w-full text-sm space-y-2 items-center justify-center ">
            <div className="text-[#333333]">Do you need help?</div>
            <div className="text-[#00B5DA]">Customer support</div>
          </div>
        </div>
      </div>
      <div className="hidden md:flex xl:w-2/3 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 h-full"></div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default ChangePassword;
