import { useEffect, useState } from "react";
import {
  TruckIcon,
  ShopingBagIcon,
  ShoppingTrollyIcon,
  FilterIcon,
} from "../icons/icons";
import AddStore from "./stores/AddStore";
import url from "../../Config/url";
import Loader from "../Loader/Loader";
const Stores = () => {
  const [tableRowsData, setTableRowsData] = useState([]);
  const [addStoreVisible, setAddStoreVisible] = useState(false);
  const [activeStores, setActiveStores] = useState(0);
  const [approvedStores, setApprovedStores] = useState(0);
  const [loading, setLoading] = useState(true);

  const getStores = async () => {
    try {
      setLoading(true);
      const res = await fetch(`${url}/api/store/getStores`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      });
      const data = await res.json();
      if (data.status) {
        const allStores = data.data;
        const arr = [];
        let count = 0;
        let approvedCount = 0;

        for (const store of allStores) {
          if (store.isActive) {
            count++;
          }
          if (store.status === "Approved") {
            approvedCount++;
          }
          const obj = {
            _id: store._id,
            name: store.name,
            address: store.address,
            isActive: store.isActive,
            status: store.status,
          };

          const userFetch = await fetch(`${url}/api/user/getUserByEmail`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              //"Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({ email: store.store_holder }),
          });

          const userData = await userFetch.json();

          if (userData.status) {
            obj.store_holder = userData.data.name;
            arr.push(obj);
          }
        }
        setActiveStores(count);
        setApprovedStores(approvedCount);
        setTableRowsData(arr);
        setLoading(false);
      } else {
        setLoading(false);
        console.log(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const approveStore = async (id) => {
    try {
      const res = await fetch(`${url}/api/store/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ id: id, status: "Approved" }),
      });
      const data = await res.json();
      if (data.status) {
        getStores();
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const activeStatus = async (id, isActive, name) => {
    try {
      const res = await fetch(`${url}/api/store/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ id: id, isActive: isActive, name: name }),
      });
      const data = await res.json();
      if (data.status) {
        getStores();
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.location.hash = "#stores";
    getStores();
  }, []);

  return (
    <div className="space-y-3 p-2 relative  overflow-x-auto w-full h-full ">
      {loading && (
        <div className="w-full h-full flex justify-center items-center">
          <Loader width={100} height={100} />
        </div>
      )}
      {!loading && (
        <>
          <div
            className={`space-x-10 h-1/5 flex ${addStoreVisible && "blur-sm"} `}
          >
            <div className=" min-w-[490px] w-3/4 flex flex-col justify-center space-y-2 ">
              <div className="flex flex h-full space-x-4 ">
                <div className="w-full rounded-2xl min-w-[150px] text-white bg-gradient-to-r from-blue-300  to-blue-700 ">
                  <div className="text-left mx-3 mt-1 font-bold text-lg">
                    Total Stores
                  </div>
                  <div className="absolute flex w-fit ">
                    <TruckIcon />
                  </div>
                  <div className="float-right mx-6 text-5xl font-semibold">{`${tableRowsData.length}`}</div>
                </div>
                <div className="w-full rounded-2xl min-w-[150px] text-white bg-gradient-to-r from-rose-300  to-rose-700 ">
                  <div className="text-left mx-3 mt-1 font-bold text-lg">
                    Active Store
                  </div>
                  <div className="absolute flex w-fit ">
                    <ShoppingTrollyIcon />
                  </div>
                  <div className="float-right mx-6 text-5xl font-semibold">{`${activeStores}`}</div>
                </div>
                <div className="w-full rounded-2xl min-w-[150px] text-white bg-gradient-to-r from-fuchsia-300  to-fuchsia-600 ">
                  <div className="text-left mx-3 mt-1 font-bold text-lg">
                    Approved Store
                  </div>
                  <div className="absolute flex w-fit ">
                    <ShopingBagIcon />
                  </div>
                  <div className="float-right mx-6 text-5xl font-semibold">{`${approvedStores}`}</div>
                </div>
              </div>
            </div>
            <div className="m-auto w-1/5">
              <button
                className="py-2 text-sm text-[white] font-semibold px-4 rounded-lg bg-[#00B5DA]"
                onClick={() => setAddStoreVisible(true)}
              >
                Add Store
              </button>
            </div>
          </div>
          <div
            className={`h-[75%] space-y-2 p-4 border bg-white border-[#D1D1D1] rounded-lg ${
              addStoreVisible && "blur-sm"
            } `}
          >
            <div className="flex relative  items-center ">
              <h1 className="text-sm sm:text-lg text-[#5D6881] font-semibold ">
                Stores{" "}
                <span className="text-[#BDBDBD]">{`(${tableRowsData.length})`}</span>
              </h1>
              {/* <div className="flex w-32 h-8 sm:h-auto sm:w-auto absolute right-0 hover:cursor-pointer   ">
                        <label 
                        htmlFor="filter"
                        className="absolute right-3 text-[#7C7C7C] hover:cursor-pointer"
                        >
                            <FilterIcon /></label>
                    <select
                    id="filter"
                        // ref={selectRef}
                        // onChange={(e) => setFilter(e.target.value)}
                        className="bg-[#F2F7FF] rounded-lg w-24 sm:w-auto  hover:cursor-pointer p-1 appearance-none"
                        
                    >
                        <option value="highToLow">Filter</option>
                        <option value="highToLow">High to Low</option>
                        <option value="lowToHigh">Low to High</option>
                        <option value="aToZ">A to Z</option>
                        <option value="zToA">Z to A</option>
                    </select>
                    </div> */}
            </div>

            <div className="overflow-y-scroll no-scrollbar p-2 max-h-[100vh] sm:max-h-[90vh]  md:max-h-[80vh] lg:max-h-[60vh]  xl:max-h-[52vh] ">
              <table id="table" className="inline-table w-full">
                <tbody className="">
                  <tr className="border-b  border-[#D1D1D1]">
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[5%]">
                      No
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[17.5%]">
                      Store Name
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[40%]">
                      Address
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[17.5%]">
                      Store Holder
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[7%]">
                      Status
                    </td>
                    <td className=" text-sm py-1.5 text-[#7C7C7C] w-[13%]"></td>
                  </tr>
                  {tableRowsData.map((row, index) => {
                    return (
                      <tr key={row._id}>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {index + 1}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {row.name}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {row.address}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {row.store_holder}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {row.status === "Approved" ? (
                            row.status
                          ) : (
                            <button
                              className="text-sm text-white font-semibold px-4 py-2 rounded-lg bg-[#00B5DA]"
                              onClick={() => approveStore(row._id)}
                            >
                              Approve
                            </button>
                          )}
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          {row.isActive ? (
                            <button
                              className="text-sm text-white font-semibold px-4 py-2 rounded-lg bg-yellow-500"
                              onClick={() =>
                                activeStatus(row._id, false, row.name)
                              }
                            >
                              Deactivate
                            </button>
                          ) : (
                            <button
                              className="text-sm text-white font-semibold px-4 py-2 rounded-lg bg-red-500"
                              onClick={() =>
                                activeStatus(row._id, true, row.name)
                              }
                            >
                              Activate
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div
            className={
              !addStoreVisible
                ? "hidden"
                : "bg-white rounded-lg absolute shadow-lg bottom-3 top-0 inset-x-72 flex items-center justify-center"
            }
          >
            <AddStore
              setAddStoreVisible={setAddStoreVisible}
              getStores={getStores}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Stores;
