import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.svg";
import SellerBasicInfo from "../components/seller-dashboard/seller-signup/BasicInfo";
import SellerAddressInfo from "../components/seller-dashboard/seller-signup/AddressInfo";
import SellerBankInfo from "../components/seller-dashboard/seller-signup/BankInfo";

const SellerSignup = () => {
  const [sellerInfo, setSellerInfo] = useState("basic-info");
  const url = window.location.href;
  const hash = window.location.hash.substring(1);
  const [store, setStore] = useState({});

  useEffect(() => {
    setSellerInfo("basic-info");
  }, [url]);

  useEffect(() => {
    window.location.hash = sellerInfo;
  }, [sellerInfo]);

  return (
    <div className=" h-full m-10 space-y-6 ">
      <div>
        <img src={logo} alt="" />
      </div>
      <div className="">
        {sellerInfo === "basic-info" && (
          <SellerBasicInfo
            setSellerInfo={setSellerInfo}
            store={store}
            setStore={setStore}
          />
        )}
        {sellerInfo === "store-info" && (
          <SellerAddressInfo
            setSellerInfo={setSellerInfo}
            store={store}
            setStore={setStore}
          />
        )}
        {sellerInfo === "bank-info" && (
          <SellerBankInfo
            setSellerInfo={setSellerInfo}
            store={store}
            setStore={setStore}
          />
        )}
      </div>
    </div>
  );
};

export default SellerSignup;
