import React, { useState } from "react";
import DiscountBanner from "../components/customer-dashboard/product-detail/DiscountBanner";
import Footer from "../components/customer-dashboard/footer/Footer";
import CustomerNavbar from "../components/navbar/customer-navbar/CustomerNavbar";
import MyCart from "../components/customer-dashboard/cart/MyCart";
import PeopleAlsoViewed from "../components/customer-dashboard/product-detail/PeopleAlsoViewed";
import PaymentOption from "../components/customer-dashboard/payment/PaymentOption";

const Payment = () => {
  return (
    <div className="md:min-w-[1000px]">
      <div>
        <CustomerNavbar />
      </div>
      <div className=" mx-3 md:mx-20 space-y-10 my-4 md:my-10  ">
        <PaymentOption billButton="Confirm Order" />
      </div>
      <Footer />
    </div>
  );
};

export default Payment;
