import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CartIcon,
  ProductCartIcon,
  ProductStarIcon,
  StarIcon,
  HeartIcon,
} from "../../icons/icons";
import { useState, useEffect } from "react";
import url from "../../../Config/url";

const ProductItem = ({ product }) => {
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);
  const [isFavourite, setIsFavourite] = useState(false);
  const [discount, setDiscount] = useState(
    product.sale !== 0 ? product.sale : 0
  );

  const getWishlist = async () => {
    if (localStorage.getItem("customer")) {
      let userId = JSON.parse(localStorage.getItem("customer"))._id;
      let response = await fetch(
        `${url}/api/wishlist/getOneFromWishlist?user=${userId}&product=${product._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            //"Access-Control-Allow-Origin": "*",
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        setIsFavourite(true);
      } else if (response.status === 500) {
        console.log(data.message);
      }
    }
  };

  const getOffers = async () => {
    try {
      let response = await fetch(`${url}/api/offer/getLive`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      });
      let data = await response.json();
      if (data.status) {
        let offers = data.data;
        let newDiscount = discount;
        offers.forEach((offer) => {
          if (offer.categories.includes(product.category)) {
            if (offer.percentage >= newDiscount) {
              newDiscount = offer.percentage;
            }
          }
        });
        setDiscount(newDiscount);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let ratingCount = 0;
    let rating = 0;
    product.reviews.forEach((review) => {
      ratingCount++;
      rating += review.rating;
    });
    setRatingCount(ratingCount);
    setRating(rating === 0 ? 0 : rating / ratingCount);
    getWishlist();
    getOffers();
  }, []);
  return (
    <div className="border flex flex-col bg-white space-y-1 relative text-center p-4 shadow-md rounded-lg hover:cursor-pointer">
      <span
        className={`absolute top-2 right-2 p-1 cursor-pointer ${
          isFavourite ? "text-red-500" : "text-gray-400"
        }`}
        onClick={async () => {
          if (localStorage.getItem("customer")) {
            if (!isFavourite) {
              let userId = JSON.parse(localStorage.getItem("customer"))._id;
              let response = await fetch(`${url}/api/wishlist/addToWishlist`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  //"Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify({
                  userId: userId,
                  productId: product._id,
                }),
              });
              let data = await response.json();
              if (data.message === "Product added to wishlist") {
                setIsFavourite(true);
              } else {
                console.log(data.message);
              }
            } else {
              let userId = JSON.parse(localStorage.getItem("customer"))._id;
              let response = await fetch(
                `${url}/api/wishlist/deleteFromWishlist`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    //"Access-Control-Allow-Origin": "*",
                  },
                  body: JSON.stringify({
                    userId: userId,
                    productId: product._id,
                  }),
                }
              );
              let data = await response.json();
              if (data.message === "Product removed from wishlist") {
                setIsFavourite(false);
              } else {
                console.log(data.message);
              }
            }
          } else {
            navigate("/user/login");
          }
        }}
      >
        <HeartIcon />
      </span>
      <img
        src={product.images[0]}
        alt={product.name}
        className="w-32 m-auto h-32 object-contain"
        loading="lazy"
      />
      <div onClick={() => navigate("/product/" + product._id)}>
        {discount !== 0 && (
          <>
            <p className="text-left text-lg font-medium ">
              $
              {product.variations[0].price -
                product.variations[0].price * (discount / 100)}
            </p>
            <p className="text-left text-xs font-medium space-x-2 ">
              <span className="line-through text-red-500">
                ${product.variations[0].price}
              </span>
              <span>-{discount}%</span>
            </p>
          </>
        )}
        {discount === 0 && (
          <>
            <p className="text-left text-lg font-medium ">
              ${product.variations[0].price}
            </p>
            <p className="text-left text-xs font-medium space-x-2 ">
              <span className="line-through text-red-500"></span>
              <span></span>
            </p>
          </>
        )}
        <h2 className="text-sm text-left text-[#8B96A5]  font-base">
          {product.name}
        </h2>
        <p className="text-left text-xs flex items-center text-[#8B96A5] space-x-1 ">
          <ProductStarIcon className="text-[#FFC831]" />{" "}
          <span>
            {rating}({ratingCount})
          </span>
          {/* <span className="absolute right-4 p-1 flex items-center bg-[#F3F5F7] text-sm rounded-full">
            <ProductCartIcon className="hover:cursor-pointer" />
          </span> */}
        </p>
      </div>
    </div>
  );
};

export default ProductItem;
