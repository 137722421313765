import Logo from "../assets/images/logo.png";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import url from "../Config/url";
import { useNavigate } from "react-router-dom";
import { SHA512 } from "crypto-js";
import { useErrorAndSuccessMessageStore } from "../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../components/seller-dashboard/ErrorAndSuccessMessage";

const Login = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = async () => {
    if (email === "" || password === "") {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please Fill All The Fields",
      });
      return;
    }
    const hashPassword = SHA512(password).toString();
    const data = {
      email: email,
      password: hashPassword,
      role: "admin",
    };
    const res = await fetch(`${url}/api/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(data),
    });
    const resData = await res.json();
    if (resData.status) {
      localStorage.setItem("admin", JSON.stringify(resData.data));
      navigate("/admin/dashboard");
    } else {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: resData.message,
      });
      console.log(resData.message);
    }
  };

  useEffect(() => {
    localStorage.removeItem("admin");
  }, []);

  return (
    <div className="flex flex-col w-screen h-screen bg-gradient-to-tr from-blue-950 via-stone-800 to-blue-950">
      <div className="m-6 ">
        <img className="h-16" src={Logo} alt="logo" />
      </div>
      <div className="m-auto px-16 py-8 space-y-5 bg-white rounded-md">
        <div className=" text-xl md:text-3xl font-bold">
          Login To Admin Panel
        </div>
        <div className="space-y-3 font-normal text-base">
          <label htmlFor="" className="flex flex-col gap-2 text-left ">
            Email
            <input
              type="email"
              placeholder="Enter Your Email"
              className="border-2 rounded-md px-4 py-3 h-11  border-[#BEBEBF]"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </label>

          <label htmlFor="" className="flex flex-col gap-2 text-left ">
            Password
            <div className=" flex relative items-center ">
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Enter Your Password"
                className="border-2 w-full rounded-md px-4 py-3 h-11  border-[#BEBEBF]"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <button
                className="absolute right-3   "
                onClick={() => {
                  handlePasswordVisibility();
                }}
              >
                {isPasswordVisible ? (
                  <EyeInvisibleOutlined className="flex text-gray-400 " />
                ) : (
                  <EyeOutlined className="flex text-gray-400 " />
                )}
              </button>
            </div>
          </label>
        </div>
        <div className="space-y-4">
          <button
            onClick={() => {
              handleLogin();
            }}
            className="text-base font-semibold h-12 rounded-md bg-[#00B5DA] text-white w-full"
          >
            Login
          </button>
          {/* <div className="text-[#BEBEBF] text-base font-normal">
            Forget Password?{" "}
            <a href="" className="text-[#00B5DA]">
              Reset
            </a>
          </div> */}
        </div>
      </div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default Login;
