import React, { useState, useEffect } from "react";
import cn from "classnames";

const SellerAddressInfo = ({ setSellerInfo, store, setStore }) => {
  const obj = ["Basic Info", "Store Info", "Bank Info"];
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setSellerInfo("store-info");
  }, []);

  const handleAddressNext = () => {
    if (name === "") {
      setError("Please write all Details");
      return;
    } else {
      let obj = store;
      obj.name = name;
      obj.image = "";
      obj.status = "Pending";
      obj.isActive = true;
      setStore(obj);
      setError("");
      setSellerInfo("bank-info");
    }
  };

  return (
    <div className=" mx-2 md:mx-44 space-y-4 md:space-y-8 ">
      <div className="flex w-full justify-between space-x-2 md:space-x-16 flex-grow">
        {obj.map((item, index) => (
          <div className="flex  items-center justify-between  flex-grow w-fit ">
            <div className="flex space-x-1">
              <div
                className={cn(
                  `flex items-center font-semibold md:font-bold px-[5px] md:px-2 text-[8px] md:text-xs py-[2px] md:py-1 rounded-full `,
                  {
                    "bg-[#037400] text-white":
                      obj.indexOf("Store Info") > index,
                    "bg-[#00B5DA] text-white ":
                      obj.indexOf("Store Info") === index,
                    "bg-[#E1E1E6] text-[#8D8D99]":
                      obj.indexOf("Store Info") < index,
                  }
                )}
              >
                {index + 1}
              </div>
              <div
                className={cn("font-normal md:font-bold text-xs md:text-base", {
                  "text-[#323238] ": obj.indexOf("Store Info") === index,
                  "text-[#8D8D99]": obj.indexOf("Store Info") < index,
                  "text-[#037400]": obj.indexOf("Store Info") > index,
                })}
              >
                {item}
              </div>
            </div>
            {index + 1 !== obj.length && (
              <div
                className=" w-2 md:w-4 font-bold text-xs md:text-base text-center"
                style={{ flexShrink: 0 }}
              >
                {">"}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="bg-white border border-[#D0D5DD] rounded-lg px-6 md:px-12 py-6 space-y-6 ">
        <div className="text-left">
          <div className="text-lg md:text-2xl font-semibold text-[#5D6881]">
            Create Seller Account
          </div>
          <div className="text-xs">
            Please complete the todo as soon as possible, and then start the
            business journey!
          </div>
        </div>
        <div className="space-y-2">
          <div className="flex flex-col text-[#344054] text-left space-y-1">
            <label htmlFor="" className="text-xs md:text-sm ">
              Store Name
            </label>
            <input
              type="text"
              placeholder="Store Name"
              className="py-2 px-3 border border-[#D0D5DD] rounded-lg"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {error && (
            <div className="text-left text-xs md:text-sm  text-red-500">
              {error}
            </div>
          )}
          <div className="flex justify-end space-x-4">
            {/* <button
              className="border border-[#00B5DA]  text-[#00B5DA] p-1 px-4 mt-6 rounded-lg   flex items-center justify-center text-xs md:text-sm"
              onClick={() => {
                setSellerInfo("basic-info");
              }}
            >
              Back
            </button> */}
            <button
              className="bg-[#00B5DA]  text-white p-1 px-4 mt-6 rounded-lg   flex items-center justify-center text-xs md:text-sm"
              onClick={() => {
                handleAddressNext();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerAddressInfo;
