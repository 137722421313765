import React from "react";
import { Doughnut } from "react-chartjs-2";
import {Chart} from 'chart.js';
import { ArcElement } from "chart.js";

Chart.register(ArcElement);

export const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};


const options = {
  title: {
    display: true,
    text: "Top 5 highest Price Product",
    fontSize: 10,
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  cutout: 50,
  maintainAspectRatio: true,
};

export default function CategoriesPieChart() {
  return <Doughnut  data={data} options={options} />;
}
