// import Logo from "../assets/images/logo.svg"

import { useEffect, useState } from "react";
import AccountSetting from "./settings/AccountSetting";
import LoginAndSecurity from "./settings/LoginAndSecurity";
import BankAccountSetting from "./settings/BankAccountSetting";
import url from "../../Config/url";

const SellerSetting = ({ seller, setSeller, store, setStore }) => {
  const [isSelected, setIsSelected] = useState("");
  const [settingOption, setSettingOption] = useState("account-settings");

  useEffect(() => {
    const link = window.location.href;
    const hash = window.location.hash.substring(1);

    if (link === `${url}/seller/dashboard`) {
      setIsSelected("settings");
    } else {
      setIsSelected(hash);
    }
  }, []);

  return (
    <div>
      {settingOption === "account-settings" && (
        <AccountSetting
          settingOption={settingOption}
          setSettingOption={setSettingOption}
          seller={seller}
          setSeller={setSeller}
          store={store}
          setStore={setStore}
        />
      )}
      {settingOption === "login&security" && (
        <LoginAndSecurity
          settingOption={settingOption}
          setSettingOption={setSettingOption}
          seller={seller}
          setSeller={setSeller}
        />
      )}
      {settingOption === "bank-account" && (
        <BankAccountSetting
          settingOption={settingOption}
          setSettingOption={setSettingOption}
          seller={seller}
          setSeller={setSeller}
          store={store}
          setStore={setStore}
        />
      )}
    </div>
  );
};

export default SellerSetting;
