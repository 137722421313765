import React, { useState, useEffect } from "react";
import cn from "classnames";
import MyEditor from "./reactQuillEditor/ReactQuills.js";

const ProductDescription = ({ setProductInfo, product, setProduct }) => {
  const obj = ["Basic Info", "Description", "Stocks & Price"];
  const [itemDescription, setItemDescription] = useState(
    product.hasOwnProperty("_id") ? product.description : ""
  );
  const [sale, setSale] = useState(
    product.hasOwnProperty("_id") ? product.sale : 0
  );
  const [error, setError] = useState("");

  useEffect(() => {
    setProductInfo("product-description");
  }, []);

  const handleProductDescription = () => {
    let productSale = sale;
    if (productSale == "") {
      productSale = 0;
    } else {
      productSale = parseInt(productSale);
    }
    if (itemDescription === "") {
      setError("Please write Description");
    } else if (productSale < 0 || productSale > 100) {
      setError("Sale% should be between 0 to 100");
    } else {
      setError("");
      let obj = product;
      if (product.hasOwnProperty("_id")) {
        delete obj.description;
        delete obj.sale;
        obj.description = itemDescription;
        obj.sale = parseInt(productSale);

        setProduct(obj);
      } else {
        obj.description = itemDescription;
        obj.sale = parseInt(productSale);

        setProduct(obj);
      }
      setProductInfo("product-stock&price");
    }
  };

  return (
    <div className="mx-2 md:mx-44 space-y-4 md:space-y-8 ">
      <div className="flex w-full justify-between space-x-2 md:space-x-16 flex-grow">
        {obj.map((item, index) => (
          <div className="flex  items-center justify-between  flex-grow w-fit ">
            <div className="flex space-x-1">
              <div
                className={cn(
                  ` flex items-center font-semibold md:font-bold px-[5px] md:px-2 text-[8px] md:text-xs py-[2px] md:py-1 rounded-full `,
                  {
                    "bg-[#037400] text-white":
                      obj.indexOf("Description") > index,
                    "bg-[#00B5DA] text-white ":
                      obj.indexOf("Description") === index,
                    "bg-[#E1E1E6] text-[#8D8D99]":
                      obj.indexOf("Description") < index,
                  }
                )}
              >
                {index + 1}
              </div>
              <div
                className={cn("font-normal md:font-bold text-xs md:text-base", {
                  "text-[#323238] ": obj.indexOf("Description") === index,
                  "text-[#8D8D99]": obj.indexOf("Description") < index,
                  "text-[#037400]": obj.indexOf("Description") > index,
                })}
              >
                {item}
              </div>
            </div>
            {index + 1 !== obj.length && (
              <div
                className="w-2 md:w-4 font-bold text-xs md:text-base text-center"
                style={{ flexShrink: 0 }}
              >
                {">"}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="bg-white border border-[#D0D5DD] rounded-lg px-6 md:px-12 py-6 space-y-6 ">
        <div className="space-y-2">
          <div className="flex flex-col max-h-[390px] text-[#344054] text-left space-y-1">
            <label htmlFor="" className="text-xs md:text-sm ">
              Product Description
            </label>
            <textarea
              value={itemDescription}
              onChange={(e) => {
                setItemDescription(e.target.value);
              }}
              className="py-2 px-3 border border-[#D0D5DD] rounded-lg"
            ></textarea>
            <div className="flex flex-col text-[#344054] text-left space-y-1">
              <label htmlFor="" className="text-xs md:text-sm ">
                Sale% on Product
              </label>
              <input
                type="number"
                placeholder="Product Name"
                className="py-2 px-3 border border-[#D0D5DD] rounded-lg"
                value={sale}
                onChange={(e) => setSale(e.target.value)}
              />
            </div>
          </div>
          {error && (
            <div className="text-left text-xs md:text-sm  text-red-500">
              {error}
            </div>
          )}
          <div className="flex justify-end space-x-4">
            <button
              className="bg-[#00B5DA]  text-white p-1 px-4 mt-6 rounded-lg   flex items-center justify-center text-xs md:text-sm "
              onClick={() => {
                handleProductDescription();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDescription;
