// import Logo from "../assets/images/logo.svg"

import { useEffect, useState } from "react";
import {
  TruckIcon,
  ShopingBagIcon,
  ShoppingTrollyIcon,
  CircleIcon,
  FilterIcon,
} from "../icons/icons";
import CategoryAllProducts from "../customer-dashboard/category-products/CategoryAllProducts";
import ProductDisplay from "../customer-dashboard/category-products/ProductDisplay";
import cn from "classnames";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import url from "../../Config/url";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
// import PieChart from "./dashboard/OrdersByCategory";
// import { PieCharDt, PieChart } from 'react-minimal-pie-chart';
// import DonutChart from 'react-donut-chart';
// import BarPlot from "./dashboard/OrdersStatics";
// import OrdersByCategoryPieChart from "./dashboard/OrdersByCategory";

const Products = ({ store }) => {
  const [tableRowsData, setTableRowsData] = useState([]);
  const [isActiveStock, setIsActiveStock] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getStores = async () => {
    try {
      setLoading(true);
      const res = await fetch(
        `${url}/api/product/getProductsByFilter?store=${store.name}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            //"Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await res.json();
      setLoading(false);
      if (data.status) {
        const allStores = data.data;
        let isStock = 0;
        for (let i = 0; i < allStores.length; i++) {
          if (allStores[i].isActive) {
            isStock++;
          }
        }
        setIsActiveStock(isStock);
        setTableRowsData(allStores);
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.location.hash = "products";
    getStores();
  }, []);

  return (
    <div className=" p-2 px-10 w-full  space-y-4">
      {loading && (
        <div className="w-full h-full flex justify-center items-center">
          <Loader width={100} height={100} />
        </div>
      )}
      {!loading && (
        <>
          <div className="  space-x-2 flex">
            <div className=" w-full flex flex-col justify-center space-y-2 ">
              <div className="flex space-x-4">
                <div className="w-1/3 h-full rounded-2xl p-4 text-white bg-gradient-to-r from-blue-300  to-blue-700 ">
                  <div className="text-left mx-3 mt-1 font-bold text-lg">
                    Total Products
                  </div>
                  <div className="absolute flex w-fit ">
                    <TruckIcon />
                  </div>
                  <div className="float-right mx-6 text-5xl font-semibold">
                    {tableRowsData.length}
                  </div>
                </div>
                <div className="w-1/3 h-full rounded-2xl p-4 text-white bg-gradient-to-r from-rose-300  to-rose-700 ">
                  <div className="text-left mx-3 mt-1 font-bold text-lg">
                    Active Products
                  </div>
                  <div className="absolute flex w-fit ">
                    <ShoppingTrollyIcon />
                  </div>
                  <div className="float-right mx-6 text-5xl font-semibold">
                    {isActiveStock}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" rounded-2xl px-2 bg-white">
            <div className="h-1/4 space-y-2 flex justify-between ">
              <div>
                <div className="text-[#3D4465] flex space-x-3 font-medium text-lg text-left">
                  <span>Products</span>
                  <button
                    className="bg-[#00B5DA]  text-white px-4  rounded-lg   flex items-center justify-center text-xs md:text-sm "
                    onClick={() => {
                      navigate("/seller/adding-product");
                    }}
                  >
                    Add Product
                  </button>
                </div>
              </div>
            </div>
            <div className="">
              {tableRowsData.length > 0 ? (
                <div className="overflow-y-scroll no-scrollbar p-2 max-h-[100vh] sm:max-h-[90vh]  md:max-h-[80vh] lg:max-h-[60vh]  xl:max-h-[52vh] ">
                  <table id="table" className="inline-table w-full">
                    <tbody className="">
                      <tr className="border-b  border-[#D1D1D1]">
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">No</td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">Name</td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          Description
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          Category
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]">
                          Status
                        </td>
                        <td className=" text-sm py-1.5 text-[#7C7C7C]"></td>
                      </tr>
                      {tableRowsData.map((row, index) => {
                        return (
                          <tr key={row._id}>
                            <td className=" text-sm py-1.5 text-[#7C7C7C]">
                              {index + 1}
                            </td>
                            <td className=" text-sm py-1.5 text-[#7C7C7C]">
                              {row.name}
                            </td>
                            <td className=" text-sm py-1.5 text-[#7C7C7C]">
                              {row.description}
                            </td>
                            <td className=" text-sm py-1.5 text-[#7C7C7C]">
                              {row.category}
                            </td>
                            <td className=" text-sm py-1.5 text-[#7C7C7C]">
                              {row.isActive ? (
                                <button
                                  className="bg-green-500 text-white py-1 rounded-md cursor-pointer w-[70px] border-none outline-none"
                                  onClick={async () => {
                                    try {
                                      const res = await fetch(
                                        `${url}/api/product/update`,
                                        {
                                          method: "POST",
                                          headers: {
                                            "Content-Type": "application/json",
                                            //"Access-Control-Allow-Origin": "*",
                                          },
                                          body: JSON.stringify({
                                            id: row._id,
                                            isActive: false,
                                          }),
                                        }
                                      );
                                      const data = await res.json();

                                      if (data.status) {
                                        getStores();
                                      } else {
                                        console.log(data.message);
                                      }
                                    } catch (error) {
                                      console.error(error);
                                    }
                                  }}
                                >
                                  Active
                                </button>
                              ) : (
                                <button
                                  className="bg-red-500 text-white py-1 rounded-md cursor-pointer w-[70px]  border-none outline-none"
                                  onClick={async () => {
                                    try {
                                      const res = await fetch(
                                        `${url}/api/product/update`,
                                        {
                                          method: "POST",
                                          headers: {
                                            "Content-Type": "application/json",
                                            //"Access-Control-Allow-Origin": "*",
                                          },
                                          body: JSON.stringify({
                                            id: row._id,
                                            isActive: true,
                                          }),
                                        }
                                      );
                                      const data = await res.json();

                                      if (data.status) {
                                        getStores();
                                      } else {
                                        console.log(data.message);
                                      }
                                    } catch (error) {
                                      console.error(error);
                                    }
                                  }}
                                >
                                  InActive
                                </button>
                              )}
                            </td>
                            <td className=" text-sm py-1.5 text-[#7C7C7C]">
                              <button
                                className="bg-yellow-500 text-white py-1 rounded-md cursor-pointer w-[50px]  border-none outline-none "
                                onClick={() => {
                                  navigate("/seller/adding-product", {
                                    state: {
                                      product: row,
                                    },
                                  });
                                }}
                              >
                                Edit
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="py-14 flex items-center justify-center">
                  <h2>No Product Found</h2>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Products;
