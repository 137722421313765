import React, { useState, useEffect } from "react";
import cn from "classnames";
import validator from "validator";
import url from "../../../Config/url";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../ErrorAndSuccessMessage";

const SellerBankInfo = ({ setSellerInfo, store, setStore }) => {
  const obj = ["Basic Info", "Store Info", "Bank Info"];
  const [ibanNumber, setIbanNumber] = useState("");
  const [error, setError] = useState("");
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  useEffect(() => {
    setSellerInfo("bank-info");
  }, []);

  const handleBankInfo = async () => {
    setError("");
    if (ibanNumber === "") {
      setError("Please write all Details");
      return;
    } else if (!validator.isIBAN(ibanNumber)) {
      setError("Please write Valid IBAN");
      return;
    } else {
      let obj = store;
      obj.account = ibanNumber;
      const res = await fetch(`${url}/api/store/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(obj),
      });
      const data = await res.json();
      if (data.status) {
        HandleErrorAndSuccessMessage({
          type: "success",
          message:
            "The Request for your store is send to admin. Will notify you with email once it is approved",
        });
        window.location.href = "/user/login";
      } else {
        setError("Something went wrong");
        console.log(data.message);
      }
    }
  };

  return (
    <div className=" mx-2 md:mx-44 space-y-4 md:space-y-8 ">
      <div className="flex w-full justify-between space-x-2 md:space-x-16 flex-grow">
        {obj.map((item, index) => (
          <div className="flex  items-center justify-between  flex-grow w-fit ">
            <div className="flex space-x-1">
              <div
                className={cn(
                  `flex items-center font-semibold md:font-bold px-[5px] md:px-2 text-[8px] md:text-xs py-[2px] md:py-1 rounded-full  `,
                  {
                    "bg-[#037400] text-white": obj.indexOf("Bank Info") > index,
                    "bg-[#00B5DA] text-white ":
                      obj.indexOf("Bank Info") === index,
                    "bg-[#E1E1E6] text-[#8D8D99]":
                      obj.indexOf("Bank Info") < index,
                  }
                )}
              >
                {index + 1}
              </div>
              <div
                className={cn("font-normal md:font-bold text-xs md:text-base", {
                  "text-[#323238] ": obj.indexOf("Store Info") === index,
                  "text-[#8D8D99]": obj.indexOf("Store Info") < index,
                  "text-[#037400]": obj.indexOf("Store Info") > index,
                })}
              >
                {item}
              </div>
            </div>
            {index + 1 !== obj.length && (
              <div
                className=" w-2 md:w-4 font-bold text-xs md:text-base text-center"
                style={{ flexShrink: 0 }}
              >
                {">"}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="bg-white border border-[#D0D5DD] rounded-lg px-6 md:px-12 py-6 space-y-6 ">
        <div className="text-left">
          <div className="text-lg md:text-2xl font-semibold text-[#5D6881]">
            Create Seller Account
          </div>
          <div className="text-xs">
            Please complete the todo as soon as possible, and then start the
            business journey!
          </div>
        </div>
        <div className="space-y-2">
          <div className="flex flex-col text-[#344054] text-left space-y-1">
            <label htmlFor="" className="text-xs md:text-sm ">
              IBAN Number
            </label>
            <input
              type="text"
              placeholder="IBAN Number"
              className="py-2 px-3 border border-[#D0D5DD] rounded-lg"
              value={ibanNumber}
              onChange={(e) => setIbanNumber(e.target.value)}
            />
          </div>
          {error && (
            <div className="text-left text-xs md:text-sm  text-red-500">
              {error}
            </div>
          )}
          <div className="flex justify-end space-x-4">
            <button
              className="bg-[#00B5DA]  text-white p-1 px-4 mt-6 rounded-lg   flex items-center justify-center text-xs md:text-sm "
              onClick={() => {
                handleBankInfo();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default SellerBankInfo;
