import { useEffect, useState } from "react";
import { CloseIcon, DropImageIcon } from "../../icons/icons";
import validator from "validator";
import url from "../../../Config/url";
import { storage } from "../../../Config/db";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";

const AddCategories = ({ setIsAddCategoryVisible }) => {
  const [parentCategory, setParentCategory] = useState("");
  const [category, setCategory] = useState("");
  const [error, setError] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  const [storeIcon, setStoreIcon] = useState(null);
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const getAllParentCategories = async () => {
    let res = await fetch(`${url}/api/parentCategory/getAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    let result = await res.json();
    if (result.status) {
      let count = 1;
      for (let i = 0; i < result.data.length; i++) {
        if (count === 1) {
          setParentCategory(result.data[i].name);
          break;
        }
        count++;
      }
      setParentCategories(result.data);
    } else {
      console.log(result.message);
    }
  };

  const handleSubmit = async () => {
    if (parentCategory === "" || category === "" || storeIcon === null) {
      setError("Please fill all fields");
      return 0;
    }
    setError("");
    let data = {
      name: category,
      parent_category: parentCategory,
    };
    const storageRef = ref(storage, `categories/${storeIcon.name}`);
    const uploadTask = uploadBytesResumable(storageRef, storeIcon);
    uploadTask.on(
      "state_changed",
      () => {},
      (err) => {
        console.log(err);
      },
      async () => {
        let url1 = await getDownloadURL(uploadTask.snapshot.ref);
        data.image = url1;
        let res = await fetch(`${url}/api/category/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            //"Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(data),
        });
        let result = await res.json();
        if (result.status) {
          setError(result.message);
          setIsAddCategoryVisible(false);
        } else {
          setError("Some error occured, view console for more details");
          console.log(result.message);
        }
      }
    );
  };

  useEffect(() => {
    getAllParentCategories();
  }, []);

  return (
    <div className=" w-full px-8 py-4 h-ful space-y-6">
      <div className="flex text-xl relative text-[#5D6881] text-left font-medium">
        <label htmlFor="" className="">
          Add Category
        </label>
        <div
          className="absolute right-0 text-black hover:cursor-pointer"
          onClick={() => {
            setIsAddCategoryVisible(false);
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="space-y-4">
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="parentCategory" className="text-left ">
            Parent Category
          </label>
          <select
            name=""
            id=""
            className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            value={parentCategory}
            onChange={(e) => {
              setParentCategory(e.target.value);
            }}
          >
            {parentCategories.map((item, index) => {
              return (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="category" className="text-left ">
            Category
          </label>
          <input
            type="text"
            className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            placeholder="Electronics"
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
          />
        </div>
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="storeIcon" className="text-left ">
            Category Image
          </label>
          <label
            htmlFor="storeIconInput"
            className=" mx-2 bg-[#F6F6F6] border-dashed h-7 px-2 border border-[#D0D5DD] rounded-md flex justify-center p-0.5 space-x-4 hover:cursor-pointer"
          >
            <DropImageIcon className="text-[#373737] p-0.5 rounded-md bg-[#EBEBEB]" />
            <span className="text-gray-600">
              {storeIcon ? storeIcon.name : "Upload Category Image"}
            </span>
          </label>
          <input
            type="file"
            id="storeIconInput"
            className="hidden h-1 mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            placeholder="Electronics"
            onChange={(e) => setStoreIcon(e.target.files[0])}
          />
        </div>

        {error && <div className="text-left text-[red]">{error}</div>}
      </div>
      <button
        className="bg-[#00B5DA] rounded-lg px-5 py-2 text-white"
        onClick={() => {
          handleSubmit();
        }}
      >
        Add Category
      </button>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default AddCategories;
