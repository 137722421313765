import laptop from "../../../assets/images/laptop.png";
import { useState, useEffect } from "react";

const RefundItem = ({ item, selectedProducts, setSelectedProducts }) => {
  const [options, setOptions] = useState([]);
  const [qty, setQty] = useState(1);

  useEffect(() => {
    let options = [];
    for (let i = 1; i <= item.qty; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    setOptions(options);
  }, [item.qty]);

  return (
    <>
      <div className="flex  relative items-center justify-between p-2 ">
        <div className="flex space-x-4">
          <div className="text-left space-y-1">
            <p className="text-md font-medium">{item.name}</p>
            <div className="">
              <div className=" md:space-x-1 flex flex-col md:flex-row text-xs md:text-base text-[#8B96A5]">
                <span>Variant: {item.variant}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute text-right space-y-2 right-6 top-2 hover:cursor-pointer">
          <div className="font-semibold">${item.price}</div>
          <div className="space-x-2">
            <select
              className="border border-gray-300 rounded-lg px-3"
              value={qty}
              onChange={(e) => setQty(e.target.value)}
            >
              {options}
            </select>
            <button
              className="bg-[#00B5DA] text-white rounded-lg px-3 py-1"
              onClick={() => {
                if (
                  selectedProducts.find((product) => product.id === item.id)
                ) {
                  setSelectedProducts(
                    selectedProducts.filter((product) => product.id !== item.id)
                  );
                } else {
                  let product = {
                    id: item.id,
                    variant: item.variant,
                    price: item.price,
                    qty: parseInt(qty),
                    name: item.name,
                  };
                  setSelectedProducts([...selectedProducts, product]);
                }
              }}
            >
              {selectedProducts.find((product) => product.id === item.id)
                ? "Remove"
                : "Add"}
            </button>
          </div>
        </div>
      </div>
      <hr className="m-2" />
    </>
  );
};

export default RefundItem;
