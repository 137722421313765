import Logo from "../../assets/images/logo.png";
import {
  DashboardIcon,
  CategoryIcon,
  StoresIcon,
  OrdersIcon,
  FinanceIcon,
  QueriesIcon,
  OffersIcon,
  LogoutIcon,
  RefundIcon,
} from "../icons/icons";
import { useState, useEffect } from "react";
import url from "../../Config/url";

const SideBar = ({ isSelected, setIsSelected }) => {
  const [counts, setCounts] = useState(null);

  const getCounts = async () => {
    try {
      const res = await fetch(`${url}/api/admin/count`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      });
      const data = await res.json();
      if (data.status) {
        console.log(data.data);
        setCounts(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCounts();
  }, []);

  setInterval(() => {
    getCounts();
  }, 60000);
  return (
    <div className="  min-w-[200px] bg-white border h-full overflow-y-scroll no-scrollbar">
      <div className="mx-8 my-6 space-y-6">
        <div className="h-16 ">
          <img className="h-16 " src={Logo} alt="logo" />
        </div>
        <div className=" space-y-6">
          <div className="space-y-3">
            {/* <div
              className={`${
                isSelected === "dashboard"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("dashboard");
              }}
            >
              <DashboardIcon className="  mr-2" />
              Dashboard
            </div> */}
            <div
              className={`${
                isSelected === "categories"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("categories");
              }}
            >
              <CategoryIcon className=" mr-2" /> Categories
            </div>
            <div
              className={`${
                isSelected === "stores"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("stores");
              }}
            >
              <StoresIcon className=" mr-2" /> Stores{" "}
              {counts !== null && <span>({counts.store})</span>}
            </div>
            <div
              className={`${
                isSelected === "orders"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("orders");
              }}
            >
              <OrdersIcon className=" mr-2" /> Orders
              {counts !== null && <span>({counts.order})</span>}
            </div>
            <div
              className={`${
                isSelected === "products"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("products");
              }}
            >
              <CategoryIcon className=" mr-2" /> Products
            </div>
            <div
              className={`${
                isSelected === "finance"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("finance");
              }}
            >
              <FinanceIcon className=" mr-2" /> Finance
            </div>
            <div
              className={`${
                isSelected === "queries"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("queries");
              }}
            >
              <QueriesIcon className="mr-2" /> Queries
              {counts !== null && <span>({counts.feedback})</span>}
            </div>
            <div
              className={`${
                isSelected === "users"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("users");
              }}
            >
              <StoresIcon className=" mr-2" /> Users
            </div>
            <div
              className={`${
                isSelected === "offers"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("offers");
              }}
            >
              <OffersIcon className=" mr-2" /> Offers
            </div>
            <div
              className={`${
                isSelected === "fundRequest"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("fundRequest");
              }}
            >
              <DashboardIcon className="  mr-2" /> fundRequest
              {counts !== null && <span>({counts.fund})</span>}
            </div>
            <div
              className={`${
                isSelected === "refund"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("refund");
              }}
            >
              <RefundIcon className="  mr-2" /> Refund
              {counts !== null && <span>({counts.refund})</span>}
            </div>
            <hr className=" border" />
          </div>
          <div
            className={`${
              isSelected === "logout"
                ? "bg-[#00B5DA] text-[white]"
                : "text-[#7C7C7C] hover:text-[#00B5DA]"
            } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
            onClick={() => {
              localStorage.removeItem("admin");
              window.location.reload();
            }}
          >
            <LogoutIcon className=" mr-2" /> Logout
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
