import React, { useEffect, useState } from "react";
import ProductDisplay from "./ProductDisplay";
import useScreenWidth from "./useScreenWidth";

const RecommendedItems = ({ products }) => {
  const screenWidth = useScreenWidth();
  const [itemsPerRow, setItemsPerRow] = useState(2);

  useEffect(() => {
    if (screenWidth >= 1024) {
      setItemsPerRow(5);
    } else if (screenWidth < 1024 && screenWidth >= 640) {
      setItemsPerRow(3);
    } else if (screenWidth < 600) {
      setItemsPerRow(2);
    }
  }, [screenWidth]);

  return (
    <div className="space-y-2">
      <h1 className="text-2xl font-semibold text-left ">Products</h1>
      {products.length === 0 && (
        <h1 className="text-xl font-semibold text-center ">
          No Products available
        </h1>
      )}
      {products.length > 0 && (
        <ProductDisplay products={products} itemsPerRow={itemsPerRow} />
      )}
    </div>
  );
};

export default RecommendedItems;
