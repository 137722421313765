import React, { useState } from "react";
import greenTick from "../../../assets/images/greenTick.png";
import { useNavigate } from "react-router";

const PasswordChangeSuccessfull = () => {
  const navigate = useNavigate();

  return (
    <div className="h-[100vh] md:h-[100%] xl:h-screen flex p-0 m-auto flex items-center  bg-white   md:min-w-[1000px]">
      <div className="w-full md:w-1/3 b flex justify-center md:items-center">
        <div className="mx-16 my-10 relative ">
          <div className=" space-y-5 lg:space-y-4 my-5 py-5  ">
            <img
              className="border-b-2 h-48   border-dashed"
              src={greenTick}
              alt=""
            />
            <button
              className="bg-[#00B5DA]  text-white p-2 px-3 m-auto w-full  flex items-center justify-center text-sm"
              onClick={() => {
                navigate("/user/login");
              }}
            >
              Login
            </button>
          </div>
          <div className="absolute flex flex-col w-full text-sm space-y-2 items-center justify-center ">
            <div className="text-[#333333]">Do you need help?</div>
            <div className="text-[#00B5DA]">Customer support</div>
          </div>
        </div>
      </div>
      <div className="hidden md:flex xl:w-2/3 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 h-full"></div>
    </div>
  );
};

export default PasswordChangeSuccessfull;
