import { useState } from "react";
import url from "../../Config/url";
import CustomerNavbar from "../navbar/customer-navbar/CustomerNavbar";
import { useErrorAndSuccessMessageStore } from "../../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../seller-dashboard/ErrorAndSuccessMessage";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [type, setType] = useState("Complaint");
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  return (
    <>
      <CustomerNavbar />
      <div className="w-full h-[100vh] flex items-center justify-center">
        <div className="w-[500px] bg-white rounded-lg shadow-lg p-8">
          <h1 className="text-2xl font-bold text-center mb-4">Contact Us</h1>
          <form
            className="flex flex-col gap-1"
            onSubmit={async (e) => {
              e.preventDefault();
              let res = await fetch(`${url}/api/feedback/add`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  name: name,
                  email: email,
                  message: message,
                  type: type,
                }),
              });
              let data = await res.json();
              if (data.status) {
                HandleErrorAndSuccessMessage({
                  type: "success",
                  message: "Feedback submitted successfully",
                });
                setName("");
                setEmail("");
                setMessage("");
                setType("Complaint");
              } else {
                HandleErrorAndSuccessMessage({
                  type: "error",
                  message: "Something went wrong",
                });
                console.log(data.message);
              }
            }}
          >
            <label className="w-full text-left mb-1" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="border border-gray-300 rounded-md p-2"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <label className="w-full text-left mb-1" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="border border-gray-300 rounded-md p-2"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <label className="w-full text-left mb-1" htmlFor="message">
              Message
            </label>
            <textarea
              id="message"
              className="border border-gray-300 rounded-md p-2"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />

            <label className="w-full text-left mb-1" htmlFor="type">
              Type
            </label>
            <select
              id="type"
              className="border border-gray-300 rounded-md p-2"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            >
              <option value="Complaint">Complaint</option>
              <option value="Order Inquiry">Order Inquiry</option>
              <option value="Suggestion">Suggestion</option>
              <option value="Query">Query</option>
            </select>

            <button
              type="submit"
              className="bg-[#00B5DA] text-white rounded-md py-2 mt-2"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </>
  );
};

export default Contact;
