import React, { useEffect, useState } from "react";
import { CloseIcon, SuccessTicketIcon, TickIcon } from "../icons/icons";
import { useErrorAndSuccessMessageStore } from "../../ErrorAndSuccessMessageStore";

const ErrorAndSuccessMessage = () => {
    const { message, type } = useErrorAndSuccessMessageStore();

  return (
    <>
    { type !== "" && <div className={`transition transition-duration-300 ease-in-out flex mt-10 justify-center items-center`}>
      <div
        className={`flex justify-center space-x-2 items-center ${
            type === "error" ? "bg-red-500" : "bg-green-500"
        } rounded-md px-4 py-2 text-white`}
      >
        <span>{message}</span>
        <div className="bg-white  flex justify-center p-2 m-0 rounded-full">
        { type === "error" ?
          <CloseIcon className="text-red-500" />
            : 
        <SuccessTicketIcon className='text-green-500' />
        }
      </div>
      </div>
    </div>
    }
    </>
  );
}

export default ErrorAndSuccessMessage