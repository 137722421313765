import { useEffect, useState } from "react";
import { CloseIcon, DropImageIcon } from "../../icons/icons";
import validator from "validator";
import url from "../../../Config/url";

const AddParentCategory = ({ setIsAddParentCategoryVisible }) => {
  const [parentCategory, setParentCategory] = useState("");

  const array = ["abc", "def", "ghi"];
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    if (parentCategory === "") {
      setError("Please fill all fields");
      return 0;
    }
    setError("");
    let data = {
      name: parentCategory,
    };
    let res = await fetch(`${url}/api/parentCategory/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(data),
    });
    let result = await res.json();
    if (result.status) {
      setError(result.message);
      setParentCategory("");
    } else {
      setError("Some error occured, view console for more details");
      console.log(result.message);
    }
  };

  return (
    <div className=" w-full px-8 py-4 h-ful space-y-6">
      <div className="flex text-xl relative text-[#5D6881] text-left font-medium">
        <label htmlFor="" className="">
          Add parent Category
        </label>
        <div
          className="absolute right-0 text-black hover:cursor-pointer"
          onClick={() => {
            setIsAddParentCategoryVisible(false);
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="space-y-4">
        <div className="flex flex-col space-y-1 text-gray-600 text-sm font-normal">
          <label htmlFor="parnetCategory" className="text-left ">
            Parent Category
          </label>
          <input
            type="text"
            className="mx-2 h-7 px-2 border border-[#D0D5DD] rounded-md"
            placeholder="Electronics"
            value={parentCategory}
            onChange={(e) => {
              setParentCategory(e.target.value);
            }}
          />
        </div>

        {error && <div className="text-left text-[green]">{error}</div>}
      </div>
      <button
        className="bg-[#00B5DA] rounded-lg px-5 py-2 text-white"
        onClick={() => {
          handleSubmit();
        }}
      >
        Add Parent Category
      </button>
    </div>
  );
};

export default AddParentCategory;
