import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart } from "chart.js";
import { ArcElement } from "chart.js";
import url from "../../../Config/url";
import { useEffect, useState } from "react";

Chart.register(ArcElement);
export default function CategoriesPieChart() {
  const [pieTableRowData, setPieTableRowData] = useState([]);
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const options = {
    title: {
      display: true,
      text: "Top 5 Categories with most products",
      fontSize: 10,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  const getAllCategoriesPieChart = async () => {
    let res = await fetch(`${url}/api/category/getAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
    });
    let result = await res.json();
    if (result.status) {
      let data = [];
      for (let i = 0; i < result.data.length; i++) {
        let obj = {};
        let category = result.data[i].name;
        let res1 = await fetch(
          `${url}/api/product/getProductsByFilter?category=${category}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              //"Access-Control-Allow-Origin": "*",
            },
          }
        );
        let result1 = await res1.json();
        if (result1.status) {
          obj.category = category;
          obj.products = result1.data.length;
          data.push(obj);
        }
      }
      data.sort((a, b) => {
        return b.products - a.products;
      });
      let pieTableData = [];
      for (let i = 0; i < 5; i++) {
        pieTableData.push(data[i]);
      }
      setPieTableRowData(pieTableData);
      let labels = [];
      let values = [];
      for (let i = 0; i < pieTableData.length; i++) {
        if (pieTableData[i] !== undefined) {
          labels.push(pieTableData[i].category);
          values.push(pieTableData[i].products);
        }
      }
      setData({
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
    } else {
      console.log(result.message);
    }
  };

  useEffect(() => {
    getAllCategoriesPieChart();
  }, []);

  return <Doughnut data={data} options={options} />;
}
