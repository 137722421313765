import React, { useState, useEffect } from "react";
import {
  DeliveryIcon,
  ReturnPolicyIcon,
  VerifiedIcon,
  WorldIcon,
} from "../../icons/icons";
import cn from "classnames";
import url from "../../../Config/url";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";

const ProductDetailWithPicture = ({ product }) => {
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();
  const [variant, setVariant] = useState(product.variations[0].variant);
  const [titleImage, setTitleImage] = useState(product.images[0]);
  const [stock, setStock] = useState(product.variations[0].qty);
  const [price, setPrice] = useState(product.variations[0].price);
  const [store, setStore] = useState({});
  const [cartPrice, setCartPrice] = useState(0);
  const [qty, setQty] = useState(
    localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart")).find(
          (item) =>
            item.id == product._id &&
            item.variant == product.variations[0].variant
        )
        ? JSON.parse(localStorage.getItem("cart")).find(
            (item) =>
              item.id == product._id &&
              item.variant == product.variations[0].variant
          ).qty
        : 1
      : 1
  );
  const [discount, setDiscount] = useState(
    product.sale !== 0 ? product.sale : 0
  );

  const getStore = async () => {
    let response = await fetch(
      `${url}/api/store/getStoreByFilter?name=${product.store}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      }
    );
    let data = await response.json();
    if (data.status) {
      setStore(data.data[0]);
    } else {
      console.log(data.message);
    }
  };

  const getOffers = async () => {
    try {
      let response = await fetch(`${url}/api/offer/getLive`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
      });
      let data = await response.json();
      if (data.status) {
        let offers = data.data;
        let newDiscount = discount;
        offers.forEach((offer) => {
          if (offer.categories.includes(product.category)) {
            if (offer.percentage >= newDiscount) {
              newDiscount = offer.percentage;
            }
          }
        });
        setDiscount(newDiscount);
        setCartPrice(
          newDiscount != 0
            ? price - (price * newDiscount) / 100
            : product.variations[0].price
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getStore();
    getOffers();
  }, []);

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  return (
    <div className="border border-[#DEE2E7] rounded-lg flex flex-col md:flex-row bg-white md:h-[428px] flex space-x-3 justify-center p-3">
      <div className="md:w-[30%] space-y-3">
        <div className="border border-[#DEE2E7] rounded-lg h-[320px] p-3 w-full ">
          <img
            className=" h-full w-full flex m-auto"
            src={titleImage}
            alt="large"
          />
        </div>
        <div className="flex space-x-2">
          {product.images.map((img) => (
            <div className="border border-[#DEE2E7] h-14 w-14 rounded-md">
              <img
                className="h-full w-full rounded-md hover:cursor-pointer"
                src={img}
                alt="small"
                onClick={() => setTitleImage(img)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className=" md:w-[45%] space-y-3 text-left p-2 md:p-3">
        <div className="space-y-1">
          <div
            className={`text-sm ${
              stock != 0 ? "text-[#00B517]" : "text-[#FF0000]"
            }`}
          >
            {stock != 0 ? "In Stock" : "Out Of Stock"}
          </div>
          <div className="text-lg font-semibold">{product.name}</div>
          <div className="text-sm text-[#8B96A5]">{product.category}</div>
        </div>
        <div className="text-lg">
          {discount != 0 ? (
            <div className="flex items-center">
              <span className="font-semibold">
                ${price - (price * discount) / 100}
              </span>
              <span className="text-[#FF0000] line-through text-sm ml-2">
                ${price}
              </span>
              <span className="text-sm ml-1">-{discount}%</span>
            </div>
          ) : (
            <span className="font-semibold">${price}</span>
          )}
        </div>
        <hr />
        <div className="space-y-2">
          <div className="flex text-sm flex-col">
            <span className="flex w-20 text-[#8B96A5]">Variant</span>
            <div className="flex flex-wrap space-x-2 mt-2">
              {product.variations.map((variant1) => (
                <div
                  className={cn(
                    "border rounded-md p-1 hover:cursor-pointer",
                    variant1.variant == variant
                      ? "border-[#00B5DA] bg-[#00B5DA] text-white"
                      : "border-[#DEE2E7] text-[#8B96A5]"
                  )}
                  onClick={() => {
                    setVariant(variant1.variant);
                    setStock(variant1.qty);
                    setPrice(variant1.price);
                    setCartPrice(
                      discount != 0
                        ? variant1.price - variant1.price * (discount / 100)
                        : variant1.price
                    );
                    setQty(
                      localStorage.getItem("cart")
                        ? JSON.parse(localStorage.getItem("cart")).find(
                            (item) =>
                              item.id == product._id &&
                              item.variant == variant1.variant
                          )
                          ? JSON.parse(localStorage.getItem("cart")).find(
                              (item) =>
                                item.id == product._id &&
                                item.variant == variant1.variant
                            ).qty
                          : 1
                        : 1
                    );
                  }}
                >
                  {variant1.variant}
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr />
        {stock != 0 && (
          <div className=" h-[25%] flex justify-between">
            <div className="flex space-x-2 md:space-x-3  items-center">
              <div
                className="bg-[#83838333] flex h-5 w-5 items-center justify-center rounded-full pb-1 hover:cursor-pointer"
                onClick={async () => {
                  if (qty > 1) {
                    let response = await fetch(
                      `${url}/api/product/getProductById?id=${product._id}`,
                      {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                          //"Access-Control-Allow-Origin": "*",
                        },
                      }
                    );
                    let data = await response.json();
                    let product1 = data.data;
                    let variant1 = product1.variations.find(
                      (v) => v.variant == variant
                    );
                    if (qty - 1 > variant1.qty) {
                      HandleErrorAndSuccessMessage({
                        type: "error",
                        message: "This much quantity is not available",
                      });
                      return;
                    } else {
                      getOffers();
                      setQty(qty - 1);
                    }
                  }
                }}
              >
                -
              </div>
              <span>{qty}</span>
              <div
                className="bg-[#83838333] flex h-5 w-5 items-center justify-center rounded-full pb-1 hover:cursor-pointer "
                onClick={async () => {
                  let response = await fetch(
                    `${url}/api/product/getProductById?id=${product._id}`,
                    {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json",
                        //"Access-Control-Allow-Origin": "*",
                      },
                    }
                  );
                  let data = await response.json();
                  let product1 = data.data;
                  let variant1 = product1.variations.find(
                    (v) => v.variant == variant
                  );
                  if (qty + 1 > variant1.qty) {
                    HandleErrorAndSuccessMessage({
                      type: "error",
                      message: "This much quantity is not available",
                    });
                    return;
                  } else {
                    getOffers();
                    setQty(qty + 1);
                  }
                }}
              >
                +
              </div>
            </div>
            <div className="space-x-2 items-center flex">
              {/* <button className="border border-[#00B5DA] text-[#00B5DA]  h-8 py-1 px-2 md:px-4 w-28 md:w-32 text-sm md:text-base flex items-center justify-center">
              Add to cart
            </button> */}
              <button
                disabled={true}
                className="bg-[#00B5DA] text-white h-8 py-1 px-2 md:px-4 w-28 md:w-32 text-sm md:text-base flex items-center justify-center hover:cursor-pointer"
                onClick={() => {
                  let cartItem = {
                    id: product._id,
                    qty: qty,
                    price: cartPrice,
                    variant: variant,
                  };
                  let cart = localStorage.getItem("cart")
                    ? JSON.parse(localStorage.getItem("cart"))
                    : [];
                  let index = cart.findIndex(
                    (item) => item.id == product._id && item.variant == variant
                  );
                  if (index == -1) {
                    cart.push(cartItem);
                  } else {
                    cart[index] = cartItem;
                  }
                  localStorage.setItem("cart", JSON.stringify(cart));
                  HandleErrorAndSuccessMessage({
                    type: "success",
                    message: "Added to cart",
                  });
                }}
              >
                Add to cart
              </button>
            </div>
          </div>
        )}
      </div>
      <div className=" hidden md:flex w-[23%] space-y-2 flex-col">
        <div className="h-1/2 border rounded-lg text-left space-y-3 p-3">
          <div>
            <div>Seller</div>
            <div className="font-semibold">{store.name}</div>
          </div>
          <hr />
          <div className="text-[#8B96A5] space-y-2 ">
            <div className="flex items-center space-x-4">
              <WorldIcon /> <span>{store.address}</span>
            </div>
            <div className="flex items-center space-x-4">
              <VerifiedIcon /> <span>Verified Seller</span>
            </div>
          </div>
          <hr />
          <div className="text-[#8B96A5] space-y-2 flex items-center justify-center text-sm">
            <span>{store.store_holder}</span>
          </div>
          {/* <button className="border rounded-lg text-[#0D6EFD]  h-8 py-1 px-4 w-full flex items-center justify-center">
            Seller's Account
          </button> */}
        </div>
        <div className="h-1/2 border rounded-lg text-left p-3 space-y-4 flex flex-col justify-between">
          <div className="flex space-x-4">
            <div className="flex ">
              <DeliveryIcon />
            </div>
            <div className="text-sm  flex flex-col">
              <div className="font-bold">Free Delivery</div>
              <div className="">
                Get the item you ordered at your doorstep for free
              </div>
            </div>
          </div>
          <hr />
          <div className="flex space-x-4">
            <div className="flex ">
              <ReturnPolicyIcon />
            </div>
            <div className="text-sm  flex flex-col">
              <div className="font-bold">7 Days Return</div>
              <div className="">
                7 days return policy,
                <br />
                <span className="underline hover:cursor-pointer">
                  Read more
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default ProductDetailWithPicture;
