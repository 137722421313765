import React, { useEffect, useState } from "react";
import CustomerNavbar from "../components/navbar/customer-navbar/CustomerNavbar";
import Footer from "../components/customer-dashboard/footer/Footer";
import laptop from "../assets/images/laptop.png";
import cn from "classnames";
import { useNavigate } from "react-router";
import url from "../Config/url";
import Loader from "../components/Loader/Loader";
import RefundItem from "../components/customer-dashboard/refund/RefundItem";
import AccountDetails from "../components/customer-dashboard/refund/AccountDetails";
import ErrorAndSuccessMessage from "../components/seller-dashboard/ErrorAndSuccessMessage";
import { useErrorAndSuccessMessageStore } from "../ErrorAndSuccessMessageStore";
import validator from "validator";

const Refund = () => {
  const navigate = useNavigate();
  const [abc, setAbc] = useState(
    "Please enter your order ID and email address to request a refund"
  );
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [order, setOrder] = useState(null);
  const [products, setProducts] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [reason, setReason] = useState("");
  const [iban, setIban] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [bankName, setBankName] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const getOrder = async () => {
    try {
      if (!id || !email) {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Please enter order ID and email",
        });
        return;
      }
      const response = await fetch(
        `${url}/api/refund/getOrderById?orderId=${id}&email=${email}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            //"Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await response.json();
      if (data.message === "Order not found") {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Order not found",
        });
        return;
      }
      if (data.message === "This order is already refunded") {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "This order is already refunded",
        });
        return;
      }
      setOrder(data.order);
      let products = [];
      data.order.products.map((item) => {
        data.products.map((product) => {
          if (item.id === product._id) {
            let obj = {
              ...item,
              name: product.name,
            };
            products.push(obj);
          }
        });
      });
      setProducts(products);
    } catch (err) {
      console.log(err);
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Something went wrong",
      });
    }
  };

  const requestRefund = async () => {
    try {
      if (!selectedProducts.length) {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Please select at least one product",
        });
        return;
      }
      if (!reason) {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Please enter reason",
        });
        return;
      }
      if (!validator.isIBAN(iban)) {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Please enter valid IBAN",
        });
        return;
      }
      if (!accountHolder) {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Please enter account holder name",
        });
        return;
      }
      if (!bankName) {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Please enter bank name",
        });
        return;
      }
      if (!address) {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Please enter address",
        });
        return;
      }
      setLoading(true);
      const response = await fetch(`${url}/api/refund/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          orderId: order._id,
          products: selectedProducts,
          reason: reason,
          iban: iban,
          accountHolder: accountHolder,
          bankName: bankName,
          address: address,
          email: email,
        }),
      });
      const data = await response.json();
      setLoading(false);
      if (data.status) {
        HandleErrorAndSuccessMessage({
          type: "success",
          message: data.message,
        });
        setSelectedProducts([]);
        setReason("");
        setIban("");
        setAccountHolder("");
        setBankName("");
        setAddress("");
        setId("");
        setEmail("");
        setOrder(null);
        setProducts(null);
      } else {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: data.message,
        });
      }
    } catch (err) {
      console.log(err);
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Something went wrong",
      });
    }
  };

  return (
    <div className=" md:min-w-[1000px] min-h-screen flex flex-col justify-between">
      <div>
        <CustomerNavbar />
      </div>
      <div
        className="flex justify-center items-center"
        style={{
          display: loading ? "flex" : "none",
        }}
      >
        <Loader width={100} height={100} />
      </div>
      <div
        className="min-h-[200px]"
        style={{
          display: loading ? "none" : "block",
        }}
      >
        <div className="text-left text-lg font-semibold pl-28 py-3 ">
          Request Refund{" "}
        </div>
        <div className="flex text-xs mx-0 justify-center  h-10 rounded-lg  ">
          <input
            className="border border-gray-300  rounded-lg px-3 w-[35%]"
            type="text"
            placeholder="Enter Order ID received in your email"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
          <input
            className="border border-gray-300  rounded-lg px-3 ml-2 w-[35%]"
            type="email"
            placeholder="Enter Email Address used to place order"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            className="bg-[#00B5DA] text-white rounded-lg ml-2 w-[120px]"
            onClick={getOrder}
          >
            Search
          </button>
        </div>
        <div className="my-5">
          {!order ? (
            <div className="text-sm text-[#8B96A5]">{abc}</div>
          ) : (
            <div className=" ">
              <div className="text-left  text-lg font-semibold pl-28 py-3 ">
                Order Items{" "}
              </div>
              <div className="w-2/3 flex flex-col mx-auto  border border-[#DEE2E7] bg-white  max-h-[400px] overflow-y-scroll no-scrollbar ">
                {products.map((product) => (
                  <RefundItem
                    item={product}
                    key={product.id}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                  />
                ))}
              </div>
              <div className="text-left  text-lg font-semibold pl-28 py-3 ">
                Account Details{" "}
              </div>
              <div className="w-2/3 mx-auto">
                <AccountDetails
                  reason={reason}
                  setReason={setReason}
                  iban={iban}
                  setIban={setIban}
                  accountHolder={accountHolder}
                  setAccountHolder={setAccountHolder}
                  bankName={bankName}
                  setBankName={setBankName}
                  address={address}
                  setAddress={setAddress}
                />
              </div>
              <button
                className=" px-2 py-2 bg-[#00B5DA] text-white rounded-lg mt-4"
                onClick={requestRefund}
              >
                Request Refund
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default Refund;
