// import Logo from "../assets/images/logo.svg"

import { useState, useEffect } from "react";
import SideBar from "../components/adminDashboard/sideBar";
import Navbar from "../components/navbar/navbar";
import Dashboard from "../components/adminDashboard/Dashboard";
import Categories from "../components/adminDashboard/Categories";
import Stores from "../components/adminDashboard/Stores";
import Orders from "../components/adminDashboard/Orders";
import Finance from "../components/adminDashboard/Finance";
import Queries from "../components/adminDashboard/Queries";
import Products from "../components/adminDashboard/products";
import Users from "../components/adminDashboard/users";
import { useNavigate } from "react-router-dom";
import Offers from "../components/adminDashboard/Offers";
import Fund from "../components/adminDashboard/Fund";
import Refund from "../components/adminDashboard/Refund";

const AdminDashboard = () => {
  const [isSelected, setIsSelected] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("admin")) {
      navigate("/admin");
    }
    const url = window.location.href;
    const hash = window.location.hash.substring(1);

    if (!hash) {
      setIsSelected("orders");
    } else {
      setIsSelected(hash);
    }
  }, [isSelected, navigate]);

  return (
    <div className="container min-w-[98%]  flex flex-row  bg-[#F8F7FC] h-[100vh]">
      <div className="w-[20%] min-w-[200px]">
        <SideBar isSelected={isSelected} setIsSelected={setIsSelected} />
      </div>
      <div className=" min-w-[1000px] w-4/5 ">
        <div className=" h-[10%]">
          <Navbar />
        </div>
        <div className="h-[90%]">
          {/* {isSelected === "dashboard" && <Dashboard />} */}
          {isSelected === "categories" && <Categories />}
          {isSelected === "stores" && <Stores />}
          {isSelected === "orders" && <Orders />}
          {isSelected === "finance" && <Finance />}
          {isSelected === "queries" && <Queries />}
          {isSelected === "products" && <Products />}
          {isSelected === "users" && <Users />}
          {isSelected === "offers" && <Offers />}
          {isSelected === "fundRequest" && <Fund />}
          {isSelected === 'refund' && <Refund />}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
