import React, { useState } from "react";
import { GoogleIcon } from "../components/icons/icons";
import { useNavigate } from "react-router";
import url from "../Config/url";
import { SHA512 } from "crypto-js";
import { auth } from "../Config/db";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useErrorAndSuccessMessageStore } from "../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../components/seller-dashboard/ErrorAndSuccessMessage";

const UserLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("customer");
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const login = async () => {
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter valid email",
      });
    } else if (password.length < 0) {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter password",
      });
    } else {
      let hashpassword = SHA512(password).toString();
      const response = await fetch(`${url}/api/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          email: email,
          password: hashpassword,
          role: role,
        }),
      });
      const data = await response.json();
      if (data.status) {
        if (role === "seller") {
          localStorage.setItem("seller", JSON.stringify(data.data));
          navigate("/seller/dashboard");
        } else {
          localStorage.setItem("customer", JSON.stringify(data.data));
          navigate("/");
        }
      } else {
        if (
          data.message === "No such user exists" ||
          data.message === "Role not found"
        ) {
          HandleErrorAndSuccessMessage({
            type: "error",
            message: "No such user exists",
          });
        } else if (data.message === "Password does not match") {
          HandleErrorAndSuccessMessage({
            type: "error",
            message: "Password does not match",
          });
        } else {
          HandleErrorAndSuccessMessage({
            type: "error",
            message: "Something went wrong",
          });
          console.log(data.message);
        }
      }
    }
  };

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    let user = await signInWithPopup(auth, provider);
    if (user) {
      let obj = {
        name: user.user.displayName,
        email: user.user.email,
        role: "customer",
        identityno: "",
        contact:
          user.user.phoneNumber === null ? "" : `${user.user.phoneNumber}`,
        address: "",
        password: "",
      };
      const response = await fetch(`${url}/api/user/loginWithGoogle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(obj),
      });
      const data = await response.json();
      if (data.status) {
        localStorage.setItem("customer", JSON.stringify(data.data));
        navigate("/");
      } else {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Something went wrong",
        });
        console.log(data.message);
      }
    } else {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Something went wrong",
      });
    }
  };

  return (
    <div className="h-[100vh] md:h-[100%] xl:h-screen flex p-0 m-auto flex items-center  bg-white   md:min-w-[1000px]">
      <div className="w-full md:w-1/3 b flex justify-center md:items-center">
        <div className="mx-16 my-10 relative ">
          <div className="text-left space-y-3">
            <div className=" text-2xl lg:text-4xl font-bold">Log In</div>
            <div>
              Please input your information in the fields below to login.
            </div>
          </div>
          <div className="border-y-2 border-dashed space-y-2 lg:space-y-4 my-5 py-5  ">
            <div className="flex flex-col relative text-left ">
              <label className="text-sm py-2" htmlFor="email">
                Account
              </label>
              <div className="flex items-center w-full">
                <button
                  className={`border-2 border-[#00B5DA]  text-[#00B5DA] p-1 px-3 m-auto w-full  flex items-center justify-center text-sm ${
                    role === "customer" ? "bg-[#00B5DA] text-white" : ""
                  }`}
                  onClick={() => {
                    setRole("customer");
                  }}
                >
                  Customer
                </button>
                <button
                  className={`border-2 border-[#00B5DA]  text-[#00B5DA] p-1 px-3 m-auto w-full  flex items-center justify-center text-sm ${
                    role === "seller" ? "bg-[#00B5DA] text-white" : ""
                  }`}
                  onClick={() => {
                    setRole("seller");
                  }}
                >
                  Seller
                </button>
              </div>
              <input
                type="email"
                placeholder="Username"
                className=" border p-2 mt-2"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
              <input
                type="password"
                placeholder="Password"
                className=" border p-2"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
              />
              <div
                className="flex  my-1 text-xs text-[#00B5DA] font-semibold hover:cursor-pointer"
                onClick={() => {
                  navigate("/user/forgot-password", {
                    state: { role: role },
                  });
                }}
              >
                Forgot your password?
              </div>
            </div>
            {/* <div className="text-left space-x-3">
              <input type="checkbox" id="rememberme" name="rememberme" />
              <label htmlFor="rememberme">Remember me</label>
            </div> */}
            <div className="flex space-x-4 font-semibold">
              <button
                className="border-2 border-[#00B5DA]  text-[#00B5DA] p-2 px-3 m-auto w-full  flex items-center justify-center text-sm"
                onClick={() => {
                  if (role === "customer") {
                    navigate("/user/signup");
                  } else {
                    navigate("/seller/signup");
                  }
                }}
              >
                Sign Up
              </button>
              <button
                className="bg-[#00B5DA]  text-white p-2 px-3 m-auto w-full  flex items-center justify-center text-sm"
                onClick={() => {
                  login();
                }}
              >
                Log In
              </button>
            </div>
          </div>
          <div
            className="absolute flex flex-col w-full text-sm space-y-2 items-center justify-center "
            style={{
              display: role === "customer" ? "flex" : "none",
            }}
          >
            <button
              className="border-2 border-[#D0D5DD] rounded-lg space-x-3  font-semibold p-2 px-3 m-auto w-full  flex items-center justify-center text-sm"
              onClick={() => {
                loginWithGoogle();
              }}
            >
              <GoogleIcon />
              <div>Sign in with Google</div>
            </button>
          </div>
        </div>
      </div>
      <div className="hidden md:flex xl:w-2/3 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 h-full"></div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default UserLogin;
