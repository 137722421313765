import Logo from "../../../assets/images/logo.png";
import {
  DashboardIcon,
  CategoryIcon,
  StoresIcon,
  OrdersIcon,
  FinanceIcon,
  LogoutIcon,
} from "../../icons/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SellerSideBar = ({ isSelected, setIsSelected }) => {
  const navigate = useNavigate();

  return (
    <div className="  h-[100%] min-w-[200px] bg-white border">
      <div className="mx-8 my-6 space-y-6">
        <div className="h-16  ">
          <img className="h-16" src={Logo} alt="logo" />
        </div>
        <div className=" space-y-6">
          <div className="space-y-3">
            <div
              className={`${
                isSelected === "products"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("products");
              }}
            >
              <CategoryIcon className="  mr-2" />
              Products
            </div>
            <div
              className={`${
                isSelected === "orders"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("orders");
              }}
            >
              <OrdersIcon className=" mr-2" /> Orders
            </div>
            <div
              className={`${
                isSelected === "finance"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("finance");
              }}
            >
              <FinanceIcon className=" mr-2" /> Finance
            </div>
            <div
              className={`${
                isSelected === "settings"
                  ? "bg-[#00B5DA] text-[white]"
                  : "text-[#7C7C7C] hover:text-[#00B5DA]"
              } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
              onClick={() => {
                setIsSelected("settings");
              }}
            >
              <DashboardIcon className="mr-2" /> Settings
            </div>
            <hr className=" border" />
          </div>
          <div
            className={`${
              isSelected === "logout"
                ? "bg-[#00B5DA] text-[white]"
                : "text-[#7C7C7C] hover:text-[#00B5DA]"
            } rounded px-4 py-2 flex items-center font-medium hover:cursor-pointer `}
            onClick={() => {
              localStorage.removeItem("seller");
              navigate("/");
            }}
          >
            <LogoutIcon className=" mr-2" /> Logout
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerSideBar;
