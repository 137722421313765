import React, { useState } from "react";
import { EditIcon, ShowPasswordIcon } from "../../icons/icons";
import showPassIcon from "../../../assets/images/showpass.png";
import url from "../../../Config/url";
import { SHA512 } from "crypto-js";
import validator from "validator";
import ErrorAndSuccessMessage from "../ErrorAndSuccessMessage";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";

const LoginAndSecurity = ({
  settingOption,
  setSettingOption,
  seller,
  setSeller,
}) => {
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const handleChange = async () => {
    if (!validator.isStrongPassword(password)) {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Please enter strong password",
      });
    } else if (password !== confirmpassword) {
      HandleErrorAndSuccessMessage({
        type: "error",
        message: "Password and Confirm Password must be same",
      });
    } else {
      const res = await fetch(`${url}/api/user/updateUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          id: seller._id,
          password: SHA512(password).toString(),
        }),
      });
      const response = await res.json();
      if (response.status) {
        let obj = seller;
        delete obj.password;

        obj.password = SHA512(password).toString();
        setSeller(obj);
        localStorage.setItem("seller", JSON.stringify(obj));
        setPassword("");
        setConfirmPassword("");
        HandleErrorAndSuccessMessage({
          type: "success",
          message: "Password changed successfully",
        });
      } else {
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Something went wrong",
        });
        console.log(response.message);
      }
    }
  };

  return (
    <div className="border border-[#D0D5DD] rounded-lg bg-white p-4 space-y-7 px-6 mx-8 my-3">
      <div className=" flex justify-between text-left text-sm font-semibold">
        <div className="space-x-4">
          <span
            className={`border-b-2 hover:cursor-pointer py-1 ${
              settingOption === "account-settings"
                ? "text-[#00B5DA] border-[#00B5DA]"
                : "border-[#D0D5DD] text-[#D0D5DD]"
            }`}
            onClick={() => {
              setSettingOption("account-settings");
            }}
          >
            Account Setting
          </span>
          <span
            className={`border-b-2 hover:cursor-pointer py-1 ${
              settingOption === "login&security"
                ? "text-[#00B5DA] border-[#00B5DA]"
                : "border-[#D0D5DD] text-[#D0D5DD]"
            }`}
            onClick={() => {
              setSettingOption("login&security");
            }}
          >
            Login & Security
          </span>
          <span
            className={`border-b-2 hover:cursor-pointer py-1 ${
              settingOption === "bank-account"
                ? "text-[#00B5DA] border-[#00B5DA]"
                : "border-[#D0D5DD] text-[#D0D5DD]"
            }`}
            onClick={() => {
              setSettingOption("bank-account");
            }}
          >
            Bank Account
          </span>
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex space-x-8">
          <div className="flex flex-col w-1/2 text-left space-y-1">
            <label className="text-sm" htmlFor="password">
              New Password
            </label>
            <div className="px-3 flex py-1.5 border rounded-lg border-[#D0D5DD]">
              <input
                className=" focus:outline-none w-full "
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                id="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <img
                onClick={() => setShowPassword(!showPassword)}
                className="hover:cursor-pointer "
                src={showPassIcon}
                alt=""
              />
            </div>
          </div>
          <div className="flex flex-col w-1/2 text-left space-y-1">
            <label className="text-sm" htmlFor="confirmpassword">
              Confirm Password
            </label>
            <div className="px-3 flex py-1.5 border rounded-lg border-[#D0D5DD]">
              <input
                className=" focus:outline-none w-full "
                type={showConfirmPassword ? "text" : "password"}
                name="confirmpassword"
                value={confirmpassword}
                id="confirmpassword"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
              <img
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="hover:cursor-pointer "
                src={showPassIcon}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className="bg-[#00B5DA] flex  text-white p-1 px-4 my-3 rounded-lg    items-center justify-center text-xs md:text-sm "
            onClick={handleChange}
          >
            Change
          </button>
        </div>
      </div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default LoginAndSecurity;
