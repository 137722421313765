import React, { useState, useEffect } from "react";
import cn from "classnames";
import { DropImageIcon } from "../../icons/icons";
import validator from "validator";
import { SHA512 } from "crypto-js";
import url from "../../../Config/url";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";
import ErrorAndSuccessMessage from "../ErrorAndSuccessMessage";

const SellerBasicInfo = ({ setSellerInfo, store, setStore }) => {
  const obj = ["Basic Info", "Store Info", "Bank Info"];
  const [shopeName, setShopeName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [code, setCode] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [identity, setIdentity] = useState("");
  const [error, setError] = useState("");
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const sendCode = async (e) => {
    e.preventDefault();
    if (!validator.isEmail(emailAddress)) {
      setError("Email Address is not Valid");
      return;
    } else {
      let obj = {
        email: emailAddress,
      };
      const res = await fetch(`${url}/api/user/sendCode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(obj),
      });
      const data = await res.json();
      if (data.status) {
        setError("");
        HandleErrorAndSuccessMessage({
          type: "success",
          message: "Code is sent to " + emailAddress,
        });
      } else {
        setError("Something went wrong");
        console.log(data.message);
      }
    }
  };

  useEffect(() => {
    setSellerInfo("basic-info");
  }, []);

  const handleBasicNext = async (e) => {
    e.preventDefault();
    setError("");
    if (
      shopeName === "" ||
      emailAddress === "" ||
      mobileNumber === "" ||
      code === "" ||
      password === "" ||
      identity === "" ||
      address === ""
    ) {
      setError("Please write all Details");
      return;
    }
    if (!validator.isEmail(emailAddress)) {
      setError("Email Address is not Valid");
      return;
    }
    if (mobileNumber.length < 7) {
      setError("Mobile Number is not Valid");
      return;
    }
    if (identity.length < 7) {
      setError("Identity Card Number is not Valid");
      return;
    }
    if (validator.isStrongPassword(password)) {
      setError("Password is not Strong");
      return;
    }
    if (code.length < 6) {
      setError("Code is not Valid");
      return;
    }
    let obj = {
      name: shopeName,
      email: emailAddress,
      contact: `+${mobileNumber}`,
      code: code,
      password: SHA512(password).toString(),
      identityno: identity,
      address: address,
      role: "seller",
    };
    const res = await fetch(`${url}/api/user/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(obj),
    });
    const data = await res.json();
    if (data.status) {
      let obj = {
        store_holder: emailAddress,
        address: address,
      };
      setStore(obj);
      setError("");
      HandleErrorAndSuccessMessage({
        type: "success",
        message: "Account Created Successfully",
      });
      setSellerInfo("store-info");
    } else {
      if (data.message === "Code does not match") {
        setError("Code does not match");
      } else {
        setError("Something went wrong");
        console.log(data.message);
      }
    }
  };

  return (
    <div className=" mx-2 md:mx-44 space-y-4 md:space-y-8 ">
      <div className="flex w-full justify-between space-x-2 md:space-x-16 flex-grow">
        {obj.map((item, index) => (
          <div className="flex  items-center justify-between  flex-grow w-fit ">
            <div className="flex space-x-1">
              <div
                className={cn(
                  `flex items-center font-semibold md:font-bold px-[5px] md:px-2 text-[8px] md:text-xs py-[2px] md:py-1 rounded-full `,
                  {
                    "bg-[#00B5DA] text-white ":
                      obj.indexOf("Basic Info") === index,
                    "bg-[#E1E1E6] text-[#8D8D99]":
                      obj.indexOf("Basic Info") !== index,
                  }
                )}
              >
                {index + 1}
              </div>
              <div
                className={cn("font-normal md:font-bold text-xs md:text-base", {
                  "text-[#323238] ": obj.indexOf("Basic Info") === index,
                  "text-[#8D8D99]": obj.indexOf("Basic Info") !== index,
                })}
              >
                {item}
              </div>
            </div>
            {index + 1 !== obj.length && (
              <div
                className=" w-2 md:w-4 font-bold text-xs md:text-base text-center"
                style={{ flexShrink: 0 }}
              >
                {">"}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="bg-white border border-[#D0D5DD] rounded-lg px-6 md:px-12 py-6 space-y-6 max-h-[400px] overflow-y-scroll no-scrollbar">
        <div className="text-left">
          <div className="text-lg md:text-2xl font-semibold text-[#5D6881]">
            Create Seller Account
          </div>
          <div className="text-xs">
            Please complete the todo as soon as possible, and then start the
            business journey!
          </div>
        </div>
        <div className="space-y-2">
          <div className="flex flex-col text-[#344054] text-left space-y-1">
            <label htmlFor="" className="text-xs md:text-sm ">
              Seller Name
            </label>
            <input
              type="text"
              placeholder="Olivia John"
              className="py-2 px-3 border border-[#D0D5DD] rounded-lg"
              value={shopeName}
              onChange={(e) => setShopeName(e.target.value)}
            />
          </div>
          <div className="flex flex-col text-[#344054] text-left space-y-1">
            <label htmlFor="" className="text-xs md:text-sm ">
              Email Address
            </label>
            <input
              type="email"
              placeholder="john@gmail.com"
              className="py-2 px-3 border border-[#D0D5DD] rounded-lg"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </div>
          <div className="flex flex-col text-[#344054] text-left space-y-1">
            <label htmlFor="" className="text-xs md:text-sm ">
              Verification Code
            </label>
            <div className="flex items-center justify-between">
              <input
                type="number"
                placeholder="123456"
                className="py-2 px-3 border border-[#D0D5DD] rounded-lg w-3/4"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <button
                className="bg-[#00B5DA] text-white py-2 px-3 rounded-lg w-1/4 flex items-center justify-center text-xs md:text-sm ml-2"
                onClick={sendCode}
              >
                Send Code
              </button>
            </div>
          </div>
          <div className="flex flex-col text-[#344054] text-left space-y-1">
            <label htmlFor="" className="text-xs md:text-sm ">
              Password
            </label>
            <input
              type="password"
              placeholder="********"
              className="py-2 px-3 border border-[#D0D5DD] rounded-lg"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex flex-col text-[#344054] text-left space-y-1">
            <label htmlFor="" className="text-xs md:text-sm ">
              Mobile Number
            </label>
            <input
              type="number"
              placeholder="921234567890"
              className="py-2 px-3 border border-[#D0D5DD] rounded-lg"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </div>
          <div className="flex flex-col text-[#344054] text-left space-y-1">
            <label htmlFor="" className="text-xs md:text-sm ">
              Identity Card Number
            </label>
            <input
              type="number"
              placeholder="123456234"
              className="py-2 px-3 border border-[#D0D5DD] rounded-lg"
              value={identity}
              onChange={(e) => setIdentity(e.target.value)}
            />
          </div>
          <div className="flex flex-col text-[#344054] text-left space-y-1">
            <label htmlFor="" className="text-xs md:text-sm ">
              Address
            </label>
            <input
              type="text"
              placeholder="Street 1, House 2, Sector 3"
              className="py-2 px-3 border border-[#D0D5DD] rounded-lg"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          {error && (
            <div className="text-left text-xs md:text-sm  text-red-500">
              {error}
            </div>
          )}
          <div className="flex justify-end">
            <button
              className="bg-[#00B5DA]  text-white p-1 px-4 mt-6 rounded-lg   flex items-center justify-center text-xs md:text-sm "
              onClick={handleBasicNext}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default SellerBasicInfo;
