import React, { useState, useEffect } from "react";
import laptop from "../../../assets/images/laptop.png";
import { CloseIcon, MasterCardIcon, VisaIcon } from "../../icons/icons";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import url from "../../../Config/url";
import Loader from "../../Loader/Loader";
import CheckoutForm from "./Checkoutform";
import ErrorAndSuccessMessage from "../../seller-dashboard/ErrorAndSuccessMessage";
import { useErrorAndSuccessMessageStore } from "../../../ErrorAndSuccessMessageStore";

const PaymentOption = ({ billButton }) => {
  const location = useLocation();
  const [COD, setCOD] = useState(true);
  const [debitCard, setDebitCard] = useState(false);
  const [newCard, setNewCard] = useState(true);
  const [order, setOrder] = useState(
    location?.state?.order ? location.state.order : {}
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { updatePopupMessage, clearPopupMessage } =
    useErrorAndSuccessMessageStore();

  const HandleErrorAndSuccessMessage = ({ type, message }) => {
    updatePopupMessage(type, message);
    setTimeout(() => {
      clearPopupMessage();
    }, 2000);
  };

  const confirm = async () => {
    setLoading(true);
    if (COD) {
      let newObj = {
        ...order,
        paymentMethod: "Cash On Delivery",
        status: "Processing",
      };
      const res = await fetch(`${url}/api/order/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(newObj),
      });
      const data = await res.json();
      if (data.status) {
        setLoading(false);
        localStorage.removeItem("cart");
        navigate(location.pathname, { replace: true });
        window.location.href = "/orders";
      } else {
        setLoading(false);
        console.log(data.message);
        HandleErrorAndSuccessMessage({
          type: "error",
          message: "Something went wrong",
        });
      }
    }
  };

  useEffect(() => {
    if (Object.keys(order).length === 0) {
      navigate("/cart");
    }
  }, []);
  return (
    <div className="  ">
      {loading && <Loader width={100} height={100} />}
      {!loading && (
        <div>
          {Object.keys(order).length === 0 && (
            <div className="text-center text-2xl font-semibold">
              Nothing to show
            </div>
          )}
          {Object.keys(order).length > 0 && (
            <>
              <div className="text-left font-semibold text-lg py-3">
                Payment
              </div>
              <div className="flex flex-col md:flex-row space-y-5 md:space-y-0">
                <div className=" md:w-2/3 space-y-6">
                  <div className=" space-y-3">
                    <label
                      className={`w-full flex justify-between items-center text-[#00B5DA] font-semibold px-6 h-10 border border-[#00B5DA] rounded-t-lg hover:cursor-pointer bg-white ${
                        COD && "bg-[#DAF9FF]"
                      }`}
                      htmlFor="paymentmethod1"
                      onClick={() => {
                        setCOD(true);
                        setDebitCard(false);
                      }}
                    >
                      Cash on Delivery
                      <input
                        id="paymentmethod1"
                        name="paymentmethod"
                        checked={COD}
                        type="radio"
                      />
                    </label>
                    <label
                      className={`w-full flex justify-between items-center text-[#00B5DA] font-semibold px-6 h-10 border border-[#00B5DA] rounded-t-lg hover:cursor-pointer bg-white ${
                        debitCard && "bg-[#DAF9FF]"
                      }`}
                      htmlFor="paymentmethod2"
                      onClick={() => {
                        setCOD(false);
                        setDebitCard(true);
                      }}
                    >
                      Debit Card
                      <input
                        id="paymentmethod2"
                        name="paymentmethod"
                        checked={debitCard}
                        type="radio"
                      />
                    </label>
                  </div>

                  {debitCard && (
                    // <div className="border p-5 bg-white space-y-5 rounded-lg">
                    //   <div className="flex justify-between items-center ">
                    //     <label
                    //       className="font-semibold hover:cursor-pointer"
                    //       htmlFor="newcard"
                    //       onClick={() => setNewCard(!newCard)}
                    //     >
                    //       <input
                    //         type="checkbox"
                    //         checked={newCard}
                    //         id="newcard"
                    //         name="newcard"
                    //         className="mr-2"
                    //       />
                    //       Add New Card
                    //     </label>
                    //     <div className="space-x-2">
                    //       <MasterCardIcon />
                    //       <VisaIcon />
                    //     </div>
                    //   </div>
                    //   <div className="flex h-12  text-left ">
                    //     <div className="flex flex-col w-64 ">
                    //       <span className="font-semibold">Card number</span>
                    //       <span className="text-xs">
                    //         Enter the 16-digit card number on the card
                    //       </span>
                    //     </div>
                    //     <input
                    //       className="border border-[#CFCFCF] w-64 rounded-lg px-2"
                    //       type="text"
                    //       name=""
                    //       id=""
                    //     />
                    //   </div>
                    //   <div className="flex h-12  text-left ">
                    //     <div className="flex flex-col w-64 ">
                    //       <span className="font-semibold">Card owner</span>
                    //       <span className="text-xs">
                    //         Enter the name on the card
                    //       </span>
                    //     </div>
                    //     <input
                    //       className="border border-[#CFCFCF] w-64 rounded-lg px-2"
                    //       type="text"
                    //       name=""
                    //       id=""
                    //     />
                    //   </div>
                    //   <div className="flex h-12  text-left ">
                    //     <div className="flex flex-col w-64 ">
                    //       <span className="font-semibold">Expiry date</span>
                    //       <span className="text-xs">
                    //         Enter the expration date of the card
                    //       </span>
                    //     </div>
                    //     <div className="flex justify-between  w-64">
                    //       <div className="">
                    //         <input
                    //           className="border border-[#CFCFCF] h-full w-12 rounded-lg px-2"
                    //           type="text"
                    //           name=""
                    //           id=""
                    //         />
                    //         <span className="mx-1 font-bold">/</span>
                    //         <input
                    //           className="border border-[#CFCFCF] h-full w-12 rounded-lg px-2"
                    //           type="text"
                    //           name=""
                    //           id=""
                    //         />
                    //       </div>
                    //       <div className="flex space-x-2">
                    //         <div className="flex flex-col ">
                    //           <span className="font-semibold">CVV</span>
                    //           <span className="text-xs">Security code</span>
                    //         </div>
                    //         <input
                    //           className="border border-[#CFCFCF] h-full w-12 rounded-lg px-2"
                    //           type="text"
                    //           name=""
                    //           id=""
                    //         />
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                    <CheckoutForm
                      amount={order.products.reduce(
                        (a, b) => a + b.price * b.qty,
                        0
                      )}
                      orderid={order._id}
                      order={order}
                      setLoading={setLoading}
                      navigate={navigate}
                      location={location}
                    />
                  )}
                </div>
                <div className=" md:w-1/3 md:mx-5 space-y-6">
                  <div className="space-y-3 text-left p-5 bg-white border border-[#DEE2E7] rounded-lg">
                    <div className="flex justify-between">
                      <span>Subtotal:</span>{" "}
                      <span>
                        $
                        {order.products.reduce(
                          (a, b) => a + b.price * b.qty,
                          0
                        )}
                      </span>
                    </div>
                    {/* <div className="flex justify-between">
              <span>Voucher:</span>{" "}
              <span className="text-[#00B517]">Rs. -5000</span>
            </div> */}
                    <hr />
                    <div className="flex justify-between font-bold">
                      <span>Total:</span>{" "}
                      <span className="text-lg">
                        $
                        {order.products.reduce(
                          (a, b) => a + b.price * b.qty,
                          0
                        )}
                      </span>
                    </div>
                    {COD && (
                      <button
                        className="bg-[#00B5DA] text-white p-2 px-3 m-auto w-full rounded-lg flex items-center justify-center text-sm"
                        onClick={() => {
                          confirm();
                        }}
                      >
                        {billButton}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <div className="absolute top-0 w-full">
        <ErrorAndSuccessMessage />
      </div>
    </div>
  );
};

export default PaymentOption;
